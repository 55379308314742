import { Gauge } from '@mp/scene-components';

const gaugeTemperature = new Gauge('gaugeTemperature', false);
gaugeTemperature.setPosition({
    x: 10.75,
    y: 1.725,
    z: 5.33
})

gaugeTemperature.setRotation({
    x: 0,
    y: 180,
    z: 0
});

gaugeTemperature.setScale({
    x: .7,
    y: .7,
    z: .7
})

gaugeTemperature.setTimeInterval(15000);
gaugeTemperature.setSiteControlAPI('95', '5', 'hvac', 'HVAC1');
gaugeTemperature.setUnit('ºF', 'ºC');

// gaugeTemperature.setMinMaxValues(0, 100);
// gaugeTemperature.setRange([0, 20, 40, 60, 80, 100])
// gaugeTemperature.setHighlights([
//     {
//         "from": 0,
//         "to": 50,
//         "color": "rgba(0, 192, 0, 1.0)"
//     },
//     {
//         "from": 50,
//         "to": 90,
//         "color": "rgba(192, 192, 0, 1.0)"
//     },
//     {
//         "from": 90,
//         "to": 100,
//         "color": "rgba(192, 0, 0, 1.0)"
//     }
// ])


export default gaugeTemperature.Gauge;