import React, { Component } from 'react';
import { Container, Navbar, Card, Col } from 'react-bootstrap';


// interface Props {
//   appsPath: any, 
// }

interface State {
  appsPath: any
}

export class Dashboard extends Component<any, State> {
  constructor(props: any, state: State) {
    super(props);
    
    this.state = {
      appsPath: this.props.appsPath
    };
  }

  componentDidMount() {
      // window.location.href = 'https://xr.siemens.com';
  }

  goToApp(path: string) {
    const { history } = this.props;
    history.push({
        pathname: path,
        // search: params
    });
  }

  render() {
    return (
        <div>
          <Navbar className="dashboard-nav">
            <Container>
              <Navbar.Brand href="/"><img src="/assets/images/SiemensLogo.svg"></img></Navbar.Brand>
            </Container>
          </Navbar>
          <div className="container" style={{ marginTop: '50px' }}>
              <div style={{ marginBottom: '50px' }}>
                <h2>Virtual Tour Applications</h2>
              </div>
              <div className="apps">
                {/* <Row> */}
                  {
                    Object.keys(this.state.appsPath).map((entry, index) => {
                      return (
                        <Col lg="4" style={{ position: 'relative' }} key={index}>
                          <Card className="appCard" onClick={() => this.goToApp(this.state.appsPath[entry].path)}>
                            <Card.Img style={{ width: '300px', height: '200px' }} variant="top" src={`${this.state.appsPath[entry].image}`} />
                            <div style={{ cursor: 'pointer'}}>
                              {/* {`${this.state.appsPath[entry].path} */}
                            </div>
                            <Card.Body style={{ paddingLeft: '0'}}>
                              <Card.Title style={{ position: 'relative'}}>
                                <div className="sidebar"></div>                                   
                                <span className="sidebar-title">{this.state.appsPath[entry].name}</span>
                              </Card.Title>
                              <Card.Text>
                                {this.state.appsPath[entry].description}
                              </Card.Text>
                              {/* <Button variant="primary">Go somewhere</Button> */}
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                {/* </Row> */}
              </div>
          </div>
        </div>
    );
  }

}
