// default image component values

type CoordinatesType = {
    x: number, y: number, z: number
}
export class Image {
    private imageComponent = {
        "name": "mediaplayer",
        "position": {
            "x": -0.658,
            "y": 1.2,
            "z": 1
        },
        "rotation": {
            "x": 0,
            "y": 90,
            "z": 0
        },
        "scale": {
            "x": 0.475,
            "y": 0.475,
            "z": 0.475
        },
        "components": [
            // {
            //     "type": "mp.planeRenderer",
            //     "inputs": {
            //       "transparent": true,
            //       "colorBackground": "rgb(200,199,197)",
            //       "opacity": 1,
            //       "events": {
            //         "INTERACTION.CLICK": true
            //       }
            //     }
            // },
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "transparent": false,
                    //   "colorBackground": "rgb(200,199,197)",
                    "events": {
                        "INTERACTION.CLICK": true
                    }
                }
            },
            {
                "type": "mp.canvasRenderer",
                "inputs": {
                    "textureRes": {
                        "w": 1024,
                        "h": 1024
                    }
                }
            },
            {
                "type": "mp.canvasImage",
                "inputs": {
                    "src": "",
                    "srcSize": {
                        "w": 1024,
                        "h": 1024
                    },
                    "destSize": {
                        "w": 1024,
                        "h": 1024
                    },
                }
            },
        ],
        "bindings": [
            [0, "texture", 1, "texture"],
            [1, "painter", 2, "painter"],
        ],
        "events": [
            [1, "repaint", 2, "paint.ready"]
        ]
    }

    private image: any;

    constructor(name: string, src: string) {
        this.image = { ...this.imageComponent };
        this.image.name = name;
        this.changeComponentProperties('mp.canvasImage', 'src', src);

    }

    get Image(): any {
        return this.image;
    }

    public setPosition(position: CoordinatesType) {
        this.image.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.image.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.image.scale = scale;
    }

    public setBackgroundColor(color: string) {
        this.changeComponentProperties('mp.planeRenderer', 'colorBackground', color);
    }

    public setTextureSize(size: {w: number, h: number}) {
        this.changeComponentProperties('mp.canvasRenderer', 'textureRes', size);
        this.changeComponentProperties('mp.canvasImage', 'srcSize', size);
        this.changeComponentProperties('mp.canvasImage', 'destSize', size);
    }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [...this.image.components];
        components.forEach((component: any) => {
            if (component.type === componentType) {
                component.inputs[propertyName] = value;
            }
        });
        this.image.components = [...components];
    }

    // public setClickEvent(eventFunction: any) {
    //     console.log('FUNCTION ADDED', eventFunction);
    //     this.changeComponentProperties('mp.planeRenderer', 'clickEvent', eventFunction);
    // }
}