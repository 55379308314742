import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('lobbyTV', false);
lobbyTV.setPosition({
    x: -44.30,
    y: 1.55, // 2.052, //-2.138,
    z: -1.15
})

lobbyTV.setRotation({
    x: 0,
    y: 90,
    z: 0
});

lobbyTV.setScale({
    x: 2.2,
    y: 1.9,
    z: 20
})
let videoPath = '/assets/videos/plaza-galerias/Ceremonia-Oficial-Apertura-Oficinas-Guadalajarara.mp4';

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;