import React, { Component } from 'react';
import { SceneLoader } from '../../SceneLoader';
import { TiThMenu } from 'react-icons/ti';
import { FaRegUser } from 'react-icons/fa';
import {  TbChevronRight } from 'react-icons/tb';
// import { TbArrowBigRight } from 'react-icons/tb';
import { AiFillCamera } from 'react-icons/ai';
import { VscChromeClose } from 'react-icons/vsc';
import { GrLanguage } from 'react-icons/gr';
import { BsShareFill, BsFacebook, BsTwitter, BsWhatsapp } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { Carousel } from 'react-bootstrap';
import Messages from './messages.json';
// import axios from 'axios';
import {
    // Frame, 
    GetSDK,
    initComponents,
    // SceneComponent, 
    // ISceneNode, 
    // OrientedBox,  
    sdkKey
} from '@mp/common';

import IframeLy from '../../helper/IframeLy';

interface Props {

}

interface State {
    showVideoFrame: boolean;
    showVideoIntro: boolean;
    showShareBox: boolean;
    showSlideShow: boolean;
    videoFrameSrc: string;
    sweepsArray: string[],
    showLangSelection: boolean,
    language: string}

 const sweep5G = '918241ab76e64f74bcfb833f7e44cc79';
 const sweepOtSoc = 'bf568a77c85a4e11b5963a656b60982f';
 const sweepArsenal = '276868f06acb4814837a5311576ec7c0';
 const sweepSecurityTesting = '6d0be7eb4dc742b7b8a58adf50c9e7c6';

// augment window with the MP_SDK property
declare global {
    interface Window {
        MP_SDK: any;
    }
}

export class CyberSecurity extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    private sceneId: string = 'PPWYiu4r9n5';
    // private switchSceneId: string = 'gt3dCv2cXqt';
    private scene: SceneLoader = null;
    private sdk: any = null;
    private defaultLink = 'https://virtualtour.xr.siemens.com/princeton-grid';
    private defaultFbLink = `https://www.facebook.com/sharer/sharer.php?u=${this.defaultLink}`;
    private defaultTwitterLink = `https://twitter.com/intent/tweet?url=${this.defaultLink}&text=Explore Siemens Princeton Island Grid in 3D`;
    private defaultWhatsappLink = `https://api.whatsapp.com/send?text=Explore Siemens Princeton Island Grid in 3D:${this.defaultLink}`;
    private defaultEmailLink = `mailto:?subject=Explore Siemens Princeton Island Grid in 3D&body=${this.defaultLink}`;

    // private currentLink = this.defaultLink;
    private tagsArray: [{ tagId: string, label: string }] = null;
    private slideShowImages: { title: string, desc: string, images: string[] } = null;
    constructor(props: Props, state: State) {
        super(props);


        // Forward url params.
        const params = objectFromQuery();

        this.state = {
            showVideoFrame: false,
            showVideoIntro: false,
            showShareBox: false,
            showSlideShow: false,
            videoFrameSrc: '',
            sweepsArray: [],
            showLangSelection: false,
            language: "en"
        };

        params.m = this.sceneId; //params.m ||  this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '0'; // Show title info [Frame.tsx]
        params.help = params.help || '0'; // Present default help [Frame.tsx]
        params.brand = params.brand || '0'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '0'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '0'; // Show Dollhouse View (default)
        params.qs = params.qs || '0'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '0, 90'; // Set camera position in photo scan
        // params.ss = params.ss || '27'; // -> Set position scan
        params.newtags = '1';
        params.search = '0';

        console.log('#', params.sr)
        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
    }

    async componentDidMount() {
        const base = document.getElementById('base') as HTMLBaseElement;
        base.href = '/cybersecurity';
        await this.initSDK();
        if (screen.width > 767) {
            this.openNav();
        }

        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        const modals = showcase.contentDocument.getElementsByClassName('modal-background') as HTMLCollectionOf<HTMLElement>;
        const toggle = document.querySelector('.open-nav') as HTMLElement;
        const nav = document.querySelector('.nav-cnt') as HTMLElement;
        const share = document.querySelector('#shareIcon') as HTMLElement;
        const screenshot = document.querySelector('#screenshotIcon') as HTMLElement;
        const logo = document.querySelector('#logo') as HTMLElement;

        function callback(mutationList: any, observer: any) {
            mutationList.forEach(function (mutation: any) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    // handle class change
                    if (mutation.target.classList.contains('open')) {
                        toggle.style.display = 'none';
                        nav.style.display = 'none';
                        share.style.display = 'none';
                        screenshot.style.display = 'none';
                        logo.style.display = 'none';
                    } else {
                        toggle.style.display = 'block';
                        nav.style.display = 'block';
                        share.style.display = 'block';
                        screenshot.style.display = 'block';
                        logo.style.display = 'block';
                    }
                }
            })
        }

        const observer = new MutationObserver(callback)
        observer.observe(modals[0], {
            attributes: true
        })

        // const cssLink = document.createElement("link");
        // cssLink.href = "css/extra.css"; 
        // cssLink.rel = "stylesheet"; 
        // cssLink.type = "text/css"; 
        // showcase.contentDocument.head.appendChild(cssLink);
    }

    async initSDK() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        const myself = this;
        document.title = "Siemens Cyber Security Lab";
        this.sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(this.sdk);

        // await this.sdk.Scene.configure((renderer: any, three: any) => {
        //     renderer.physicallyCorrectLights = true;
        //     renderer.gammaFactor = 2.2;
        //     renderer.gammaOutput = true;
        //     renderer.shadowMap.enabled = true;
        //     renderer.shadowMap.bias = 0.0001;
        //     renderer.shadowMap.type = three.PCFSoftShadowMap;
        // });



        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);
        
        // this.sdk.Sweep.current.subscribe(function (currentSweep: any) {
        //     // Change to the current sweep has occurred.
        //     if (currentSweep.sid === '') {
        //       console.log('Not currently stationed at a sweep position');
        //     } else {
        //       console.log('Sweeeping data:');  
        //       console.log('Currently at sweep', currentSweep.sid);
        //       console.log('Current position', currentSweep.position);
        //       console.log('On floor', currentSweep.floorInfo.sequence);
        //     }
        //   });          

        this.sdk.Sweep.data.subscribe({
            onAdded: function (index: string, item: any, collection: any) {
                console.log(`sweep added ${index}`);
                myself.state.sweepsArray.push(index);
            }
        });


        this.sdk.Asset.registerTexture('tagIcon', '/assets/images/princeton-grid/sie-favicon_internet.ico');
        // this.sdk.Asset.registerTexture('tagIconWhite', '/assets/images/princeton-grid/sie-favicon_intranet.ico');
        // this.sdk.Asset.registerTexture('tagInfo', '/assets/images/princeton-grid/info-icon.svg');
        // this.sdk.Asset.registerTexture('tagPlug', '/assets/images/princeton-grid/plug-icon.svg');

        this.sdk.Tag.data.subscribe({
            onAdded: function (index: string, item: any) {
                console.log('#Tag added')
                if (!myself.tagsArray) {
                    myself.tagsArray = [{ tagId: index, label: item.label }];
                } else {
                    myself.tagsArray.push({ tagId: index, label: item.label });
                }
                const noNavigationTag = index;
                myself.editIcon(index, 'tagIcon');
                if (item.label === 'Go to Princeton Grid Experience') 
                    return;

                myself.sdk.Tag.allowAction(noNavigationTag, {
                    opening: true,
                    docking: false
                    // implies navigating: false
                });
            },
        });

        this.sdk.on(this.sdk.Mattertag.Event.CLICK,
            function (tagSid: string) {
                console.log(tagSid + ' #was selected');
                const tag = myself.tagsArray.find((item) => item.tagId == tagSid);
                if (tag) {
                    switch (tag.label) {
                        case '5G Lab Overview':
                            myself.openVideoFrame('/assets/videos/cybersecurity/5G_Lab_Video_Overview_v5.mp4');
                            break;
                        case '5G Lab Case Study: US Air Force':
                            myself.openVideoFrame('/assets/videos/cybersecurity/5G_Lab_USAirForceVideo_v10.mp4');
                            break;
                        case '5G Lab Offering to Clients':
                            myself.openVideoFrame('/assets/videos/cybersecurity/5G_Lab_Video_Siemens_offering_to_Clients_v7.mp4');
                            break;
                        case 'Security Testing Video: Overview':
                            myself.openVideoFrame('/assets/videos/cybersecurity/Security_Testing_Overview_v5.mp4');
                            break;
                        case 'Security Testing Lab Video: Walkthrough':
                            myself.openVideoFrame('/assets/videos/cybersecurity/Security_Testing_Walkthrough_v11.mp4');
                            break;
                        case 'Security Testing Lab Video: Siemens Offering To Clients':
                            myself.openVideoFrame('/assets/videos/cybersecurity/Security_Testing_Offering_v5_1.mp4');
                            break;
                        case 'OT-SOC Lab Advancements':
                            myself.openVideoFrame('/assets/videos/cybersecurity/OT_SOC_Advancements_v8_1.mp4');
                            break;
                        case 'OT-SOC Lab Core Functionalities':
                            myself.openVideoFrame('/assets/videos/cybersecurity/OT_SOC_Core_Functionality_v7_1.mp4');
                            break;
                        case 'OT-SOC Lab Overview':
                            myself.openVideoFrame('/assets/videos/cybersecurity/OT_SOC_Overview_v4.mp4');
                            break;  
                        case 'ARSENAL':
                            myself.openVideoFrame('/assets/videos/cybersecurity/Cybersecurity_Lab_Video-ARSENAL_v5.mp4');
                            break;  
                        default:
                            break;
                    }
                }
            }
        );


        this.sdk.Mode.current.subscribe(function (mode: any) {
            // the view mode has changed
            if (mode === 'mode.outside') {
                myself.toggleNodes(false);
            } else {
                myself.toggleNodes(true);
            }
        });

        // replace Embedly to IframeLy
        const iframe = new IframeLy(this.sdk);
        iframe.replace();
    }

    delay = async (time: number) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    editIcon = async (tagId: string, icon: string) => {
        await this.delay(100);
        this.sdk.Tag.editIcon(tagId, icon);
        console.log('#Tag icon edited')
    }

    toggleNodes = (visible: boolean) => {
        if (!this.scene) return;
        const nodesIterator = this.scene.nodeIterator();

        for (const node of nodesIterator) {
            node.obj3D.visible = visible;
        }
    }

    openNav = () => {
        const element = document.querySelector('.nav-cnt') as HTMLElement;
        const toggle = document.querySelector('.open-nav') as HTMLElement;
        if (element.classList.contains('opened')) {
            element.classList.remove('opened');
            toggle.classList.remove('on');
        } else {
            element.classList.add('opened');
            toggle.classList.add('on');
        }
    }

    takeScreenshot = () => {
        this.sdk.Renderer.takeScreenShot()
            .then(function (screenShotUri: string) {
                const a = document.createElement("a");
                a.href = screenShotUri;
                a.download = "Siemens Princeton Island Grid.jpg";
                a.click();
            });
    }

    openVideoFrame = (src: string) => {
        this.setState({ showVideoFrame: true, videoFrameSrc: src });
    }

    closeVideoFrame = () => {
        this.setState({ showVideoFrame: false, videoFrameSrc: '', showVideoIntro: false });
    }

    openShareBox = () => {
        this.sdk.Settings.get('sr')
            .then(function (data: any) {
                // Setting retrieval complete.
                console.log('#Labels setting: ' + data);
            });
        this.setState({ showShareBox: true })
    }

    closeShareBox = () => {
        this.setState({ showShareBox: false })
    }
    closeSlideShow = () => {
        this.setState({ showSlideShow: false })
    }

    moveToSweep = (sweepId: string, rotation: { x: number, y: number, z?: number }): void => {
        // const myself = this;
        // const sweepId = this.state.sweepsArray[index - 1];
        console.log(`sweeping to ${sweepId}`);
        const transition = this.sdk.Sweep.Transition.FLY;
        const transitionTime = 1000; // in milliseconds
        this.sdk.Sweep.moveTo(sweepId, {
            rotation: rotation,
            transition: transition,
            transitionTime: transitionTime,
        }).catch(function (error: any) {
            // Error with moveTo command
            console.log('Failed to Arrived at sweep ' + error);
        });
        if (screen.width < 767) {
            this.openNav();
        }
    }

    moveToView = (viewNumber: string) => {
        const myself = this;

        this.sdk.Tour.getData()
            .then(() => {
                if (screen.width < 767) {
                    this.openNav();
                }
                return myself.sdk.Tour.step(9);
            })
    }

    replaceUrlParam = (url: string, paramName: string, paramValue: string): string => {
        if (paramValue == null) {
            paramValue = '';
        }
        const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
        if (url.search(pattern) >= 0) {
            return url.replace(pattern, '$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/, '');
        return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
    }

    copyToClipboard = (): void => {
        navigator.clipboard.writeText(this.defaultLink);
    }

    setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }   

    onClickLangIcon (){
        console.log('onClickLangIcon');
        this.setState({showLangSelection: !this.state.showLangSelection});
    } 
    
    onMouseOutLangIcon (languageSelected: string){
        console.log('onMouseOutLangIcon');
        this.setState({showLangSelection: false, language: languageSelected});
        console.log(languageSelected);
    }     

    render() {
        if (this.sdk && this.sdk.currentSweep){
            console.log(`this.sdk.currentSweep:`);
            console.log(this.sdk.currentSweep);
            
        }
        return (
            <div className='ui-wrap'>
                <div className='frame'>
                    <iframe id='showcase' name="showcase" className='frame' src={this.src}></iframe>
                </div>
                <div className='screenshot extra-icon' id="screenshotIcon" onClick={this.takeScreenshot}>
                    <AiFillCamera className='icon' />
                </div>
                <div className='extra-icon share' id="shareIcon" onClick={this.openShareBox}>
                    <BsShareFill className='icon' />
                </div>
                <div className="logo-box my-ui" id="logo">
                    <a className="logo size-200" style={{marginTop: '10px'}}>
                        <img src="/assets/images/SiemensLogoWhite.svg" alt="" />
                    </a>
                </div>
                <div className="nav-box">
                    <a className="open-nav" onClick={this.openNav}>
                        <TiThMenu style={{ width: '30px', height: '25px', verticalAlign: 'middle' }}></TiThMenu>
                        <span style={{ verticalAlign: 'middle' }}>MENU</span>
                    </a>
                    <div className="nav-cnt">
                        <div style={{ display: 'flex' }}>
                            <div className="section title w-lngs">
                                <div style={{ fontSize: '17px' }}>Siemens Cybersecurity Lab</div>
                            </div>
                            <div className="section lng">
                                <div className="label">{Messages[this.state.language.toString() as keyof typeof Messages].language}
                                    <div className="lang-icon" ><GrLanguage 
                                    onClick ={() => this.onClickLangIcon()} /></div>
                                    {this.state.showLangSelection && <div className="lng-box">
                                         {this.state.language != 'en' && <div onClick = {() => this.onMouseOutLangIcon("en")}>en</div>}
                                         {this.state.language != 'de' && <div onClick = {() => this.onMouseOutLangIcon("de")}>de</div>}
                                         {this.state.language != 'it' && <div onClick = {() => this.onMouseOutLangIcon("it")}>it</div>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section2.title}</div>
                            <div className="icnt list-nav">
                                <a href="#" onClick={() => this.openVideoFrame('/assets/videos/cybersecurity/Cybersecurity_Lab_Overview_v5.mp4')}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section2.link1}</span>
                                </a>
                                <a href="#" onClick={() => this.openVideoFrame('/assets/videos/cybersecurity/Cybersecurity_Lab_Video-Research_equals_Collaboration_v4.mp4')}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section2.link2}</span>
                                </a>
                                <a href="#" onClick={() => this.openVideoFrame('/assets/videos/cybersecurity/Cybersecurity_Lab_Video-Future_of_Cybersecurity_v4.mp4')}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section2.link3}</span>
                                </a>        
                            </div>
                        </div> 
                        <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            {/* <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language as keyof typeof Messages].section1.title}</div> */}
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section1.title}</div>                            
                            <div className="icnt list-nav">
                                <a href="#" onClick={() => this.moveToSweep(sweep5G, { x: 5, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section1.link1}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep(sweepArsenal, { x: 0, y: -180 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section1.link2}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep(sweepOtSoc, { x: 0, y: -90 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section1.link3}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep(sweepSecurityTesting, { x: 0, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section1.link4}</span>
                                </a>                                   
                                {/* <a onClick={() => this.openVideoFrame("/assets/videos/princeton-grid/Chris_Automated_Tour_v8rev_M-lowres.mp4")}>
                                    <TbArrowBigRight className="i" />
                                    <span className="name">Siemens Smart Infrastructure microgrid solutions - Automated Tour</span>
                                </a>                                                                                               */}
                            </div>
                        </div>   
                        {/* <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section3.title}</div>
                            <div className="icnt list-nav">
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section3.link1}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section3.link2}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section3.link3}</span>
                                </a>                                                                   
                            </div>
                        </div>                */}
                        {/* <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section4.title}</div>
                            <div className="icnt list-nav">
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section4.link1}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section4.link2}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section4.link3}</span>
                                </a>                                                                   
                            </div>
                        </div>                    */}
                        {/* <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section5.title}</div>
                            <div className="icnt list-nav">
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section5.link1}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section5.link2}</span>
                                </a>
                                <a href="#" onClick={() => this.moveToSweep('569d384948314a569e26f672d2ed17d1', { x: 10, y: 0 })}>
                                    <TbChevronRight className="i" />
                                    <span className="name">{Messages[this.state.language.toString() as keyof typeof Messages].section5.link3}</span>
                                </a>                                                                   
                            </div>
                        </div>                                                                */}
                        <div className="section" style={{ clear: 'both', position: 'relative' }}>
                            <div className="main-icon"><FaRegUser /></div>
                            <div className="icnt title" style={{ fontSize: '16px' }}>{Messages[this.state.language.toString() as keyof typeof Messages].section6.title}</div>
                            <div className="icnt txt">
                                Martin Otto
                                <br />
                                {Messages[this.state.language.toString() as keyof typeof Messages].section6.label1}
                                <br />
                                {Messages[this.state.language.toString() as keyof typeof Messages].section6.label2}
                                <br />
                                <a target="_blank" href="mailto:m.otto@siemens.com">{Messages[this.state.language.toString() as keyof typeof Messages].section6.label3} m.otto@siemens.com</a>
                            </div>
                            <div className="icnt txt">
                                <a href="https://virtualtour.xr.siemens.com/princeton-grid">
                                    Tour our virtual microgrid here
                                </a>
                            </div>
                            {/* https://virtualtour.xr.siemens.com/princeton-grid */}
                        </div>
                    </div>
                </div>
                {this.state.showVideoFrame && <div className="video-frame-wrapper" style={{ backgroundColor: 'black' }}>
                    <VscChromeClose className="video-frame-close" onClick={this.closeVideoFrame} />
                    <div className="video-frame">
                        <video width="320" height="240" controls autoPlay>
                            <source src={this.state.videoFrameSrc} type="video/mp4"></source>
                        </video>

                    </div>
                </div>}
                {/* {this.state.showVideoFrame && <div className="video-frame-wrapper">
                    <VscChromeClose className="video-frame-close" onClick={this.closeVideoFrame} />
                    <div className="video-frame">
                        <iframe src={this.state.videoFrameSrc}
                            allowFullScreen={true} allow="autoplay; fullscreen" scrolling="auto"></iframe>
                    </div>
                </div>} */}

                {this.state.showShareBox && <div className="share-box-wrapper" onClick={this.closeShareBox}>
                    <div className="share-box" onClick={(e) => e.stopPropagation()}>
                        <VscChromeClose className="video-frame-close" onClick={this.closeShareBox} />
                        <div style={{ padding: '20px 0' }}>
                            <h3>
                                <span style={{ fontSize: '1.4rem' }}>Default View</span>
                                <span className="share-link-box">
                                    <a href={this.defaultFbLink} className="share-link"> <BsFacebook className="share-link" /> </a>
                                    <a href={this.defaultTwitterLink} className="share-link"> <BsTwitter className="share-link" /> </a>
                                    <a href={this.defaultWhatsappLink} className="share-link"> <BsWhatsapp className="share-link" /> </a>
                                    <a href={this.defaultEmailLink} className="share-link"> <MdOutlineEmail className="share-link" /> </a>
                                </span>
                            </h3>
                            <div>
                                <input type="text" className="share-url" value={this.defaultLink} />
                                <button className="copy" onClick={this.copyToClipboard}>Copy URL</button>
                            </div>
                        </div>
                        {/* <div style={{ padding: '20px 0' }}>
                            <h3>
                                <span style={{ fontSize: '1.4rem' }}>Current Location</span>
                                <span className="share-link-box">
                                    <a href="#" className="share-link"> <BsFacebook className="share-link" /> </a>
                                    <a href="#" className="share-link"> <BsTwitter className="share-link" /> </a>
                                    <a href="#" className="share-link"> <BsWhatsapp className="share-link" /> </a>
                                    <a href="#" className="share-link"> <MdOutlineEmail className="share-link" /> </a>
                                </span>
                            </h3>
                            <div>
                                <input type="text" className="share-url" value={this.currentLink} />
                                <button className="copy">Copy URL</button>
                            </div>

                        </div> */}
                    </div>
                </div>}

                {this.state.showSlideShow && <div className="slideShow">
                    <VscChromeClose className="slideshow-close" onClick={this.closeSlideShow} />
                    <Carousel slide={false} interval={null}>
                        {this.slideShowImages.images.map((img, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <div className="image">
                                        <img
                                            className="d-block"
                                            src={img}
                                            alt={this.slideShowImages.title}
                                        />
                                    </div>

                                    <Carousel.Caption>
                                        <h3>{this.slideShowImages.title}</h3>
                                        <p>{this.slideShowImages.desc}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            );
                        })
                        }
                    </Carousel>
                </div>}
                {this.state.showVideoIntro && <div className="video-frame-wrapper" style={{ backgroundColor: 'black' }}>
                    <VscChromeClose className="video-frame-close" onClick={this.closeVideoFrame} />
                    <div className="video-frame">
                        <video width="320" height="240" controls autoPlay>
                            <source src="/assets/videos/princeton-grid/Siemens-PIG-Intro-v4-lowres.mp4" type="video/mp4"></source>
                        </video>

                    </div>
                </div>}
            </div>
        );
    }

}

export const objectFromQuery = (url?: string): { [key: string]: string } => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
        object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};