import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('coffeTV', false);
lobbyTV.setPosition({
    x: -27.92,
    y: 1.79, // 2.052, //-2.138,
    z: -2.025
})

lobbyTV.setRotation({
    x: 0,
    y: 0,
    z: 0
});

lobbyTV.setScale({
    x: 1.45,
    y: 1.25,
    z: 1
})
let videoPath = '/assets/videos/plaza-galerias/TownHall-Meeting-GDL.mp4';

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;