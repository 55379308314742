import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('meetingTV', false);
lobbyTV.setPosition({
    x: 3,
    y: 1.5, // 2.052, //-2.138,
    z: 18.8
})

lobbyTV.setRotation({
    x: 0,
    y: -90,
    z: 0
});

lobbyTV.setScale({
    x: 1.47,
    y: 1.29,
    z: 1
})
let videoPath = '/assets/videos/plaza-galerias/Ceremonia-Oficial-Apertura-Oficinas-Guadalajarara.mp4';

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;