import React, { Component } from 'react';

interface Props {

}

interface State {
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}

export class Tutorial extends Component<Props, State> {
private src: string = './bundle/showcase.html?m=z5mGFFTHH5e&applicationKey=gmfu859g52qxhumzkzcwyhdpa&play=1&title=1&brand=1&mls=0&f=1&dh=1&hr=1&sr=-1,1.24&ss=3';
  constructor(props: Props, state: State) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    const showcase = document.getElementById('showcase') as HTMLIFrameElement;
    const key = 'gmfu859g52qxhumzkzcwyhdpa';
    const bundle_version = '3.1.42.14-24-gb057e5145';


    
    showcase.addEventListener('load', async function () {
      let sdk;
      try {
          sdk = await showcase.contentWindow.MP_SDK.connect(showcase, key, bundle_version);
      }
      catch (e) {
          console.error(e);
          return;
      }
  
      console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
      console.log(sdk);
      
      // add default lights
      const nodeDefaultLights = await sdk.Scene.createNode();
      nodeDefaultLights.addComponent('mp.lights');
      nodeDefaultLights.start();
  
      // ambient light (white)
      const nodeAmbientLight = await sdk.Scene.createNode();
      const ambientLightParams = {
          enabled: true,
          color: { r: 0.25, g: 0.25, b: 0.25 },
          intensity: 0.5,
      };
      nodeAmbientLight.addComponent('mp.ambientLight', ambientLightParams);
      nodeAmbientLight.start();
      
      // directional light (green)
      const nodeDirectionalLight = await sdk.Scene.createNode();
      const directionalLightParams = {
          enabled: false,
          color: { r: 1, g: 1, b: 1 },
          intensity: 0.1,
          position: { x: 0, y: 0, z: 0 },
          target: { x: 5, y: 1, z: -1 },
          debug: false,
      };
      nodeDirectionalLight.addComponent('mp.directionalLight', directionalLightParams);
      nodeDirectionalLight.start();
  
      // point light (blue)
      const nodePointLight = await sdk.Scene.createNode();
      const pointLightParams = {
          enabled: false,
          color: { r: 1, g: 1, b: 1  },
          intensity: 1,
          position: { x: 5, y: 1, z: -1 },
          distance: 20,
          decay: 1,
          debug: false,
      };
  
      nodePointLight.addComponent('mp.pointLight', pointLightParams);
      nodePointLight.start();
  
      console.log('%c All light nodes loaded! ', 'background: #ffffff; color: #00dd00');
  
      // FBX loader
      // const nodeModelFbx = await sdk.Scene.createNode();
      // const nodeModelFbxParams = {
      //     url: 'https://gitcdn.link/repo/mrdoob/three.js/dev/examples/models/fbx/stanford-bunny.fbx',
      //     visible: true,
      //     localScale: { x: 0.00001, y: 0.00001, z: 0.00001 },
      //     localPosition: { x: 0, y: 0, z: 0 },
      //     localRotation: { x: 0, y: 0, z: 0 },
      //     colliderEnabled: true
      // };
      // nodeModelFbx.addComponent('mp.fbxLoader', nodeModelFbxParams);
      // nodeModelFbx.obj3D.position.set(2, 0.25, -1);
      // nodeModelFbx.start();
      // console.log('%c FBX - Stanford Bunny loaded! ', 'background: #ffffff; color: #00dd00');
             
      const baseURl = '/';
      // GLTF loader
      const tankModelGltf = await sdk.Scene.createNode();
      const tankModelGltfParams = {
          url: baseURl + 'assets/models/Tank.glb',
          visible: true,
          localScale: { x: 1, y: 1, z: 1 },
          localPosition: { x: 0, y: 0, z: 0 },
          localRotation: { x: 0, y: 90, z: 0 },
          colliderEnabled: true
      };
      tankModelGltf.addComponent('mp.gltfLoader', tankModelGltfParams);
      tankModelGltf.obj3D.position.set(5, 1, -1); 
      tankModelGltf.start();
      console.log('%c GLTF - Tank loaded! ', 'background: #ffffff; color: #00dd00');
  
      // DAE loader
      const trooperModelDae = await sdk.Scene.createNode();
      const trooperModelDaeParams = {
          url: 'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/models/collada/stormtrooper/stormtrooper.dae',
          visible: true,
          localScale: { x: 0.25, y: 0.25, z: 0.25 },
          localPosition: { x: 0, y: 0, z: 0 },
          localRotation: { x: 0, y: -90, z: 0 },
          colliderEnabled: true
      };
      trooperModelDae.addComponent('mp.daeLoader', trooperModelDaeParams);
      trooperModelDae.obj3D.position.set(-1, 0.0, 0);
      trooperModelDae.start();
      console.log('%c DAE - Stormtrooper loaded! ', 'background: #ffffff; color: #00dd00');
  
  
      // OBJ loader
      const turbineModelObj = await sdk.Scene.createNode();
      
      const nodeObjModelParams = {
          url: baseURl + 'assets/models/turbine/turbine-01.obj',
          materialUrl: baseURl + 'assets/models/turbine/turbine-01.mtl',
          visible: true,
          localScale: { x: 1, y: 1, z: 1 },
          localPosition: { x: 0, y: 0, z: 0 },
          localRotation: { x: 0, y: 90, z: 0 },
          colliderEnabled: true
      };
      turbineModelObj.addComponent('mp.objLoader', nodeObjModelParams);
      turbineModelObj.obj3D.position.set(14, 1, 0);
      turbineModelObj.start();
      console.log('%c OBJ - Male Model loaded! ', 'background: #ffffff; color: #00dd00');
  
      // OBJ loader 2
      // const nodeObjModel2 = await sdk.Scene.createNode();
      // const nodeObjModel2Params = {
      //     url: 'https://gitcdn.link/repo/mrdoob/three.js/dev/examples/models/obj/female02/female02.obj',
      //     materialUrl: 'https://gitcdn.link/repo/mrdoob/three.js/dev/examples/models/obj/female02/female02.mtl',
      //     visible: true,
      //     localScale: { x: 0.01, y: 0.01, z: 0.01 },
      //     localPosition: { x: 0, y: 0, z: 0 },
      //     localRotation: { x: 0, y: 0, z: 0 },
      //     colliderEnabled: true
      // };
      // nodeObjModel2.addComponent('mp.objLoader', nodeObjModel2Params);
      // nodeObjModel2.obj3D.position.set(0.75, 0, -1);
      // nodeObjModel2.start();
      // console.log('%c OBJ - Female Model loaded! ', 'background: #ffffff; color: #00dd00');
  
      // OBJ loader 3
      const nodeObjModel3 = await sdk.Scene.createNode();
      const nodeObjModel3Params = {
          url: 'https://raw.githubusercontent.com/ricardoalexandre-dev/3dmodels/main/3dmodels/extintor/extintor.obj',
          materialUrl: 'https://raw.githubusercontent.com/ricardoalexandre-dev/3dmodels/main/3dmodels/extintor/extintor.mtl',
          visible: true,
          localScale: { x: 0.02, y: 0.02, z: 0.02 },
          localPosition: { x: 0, y: 0, z: 0 },
          localRotation: { x: -90, y: 0, z: 0 },
          colliderEnabled: true
      };
      nodeObjModel3.addComponent('mp.objLoader', nodeObjModel3Params);
      nodeObjModel3.obj3D.position.set(-0.5, 0.2, -2);
      nodeObjModel3.start();
  
      console.log('%c OBJ - Extintor Model loaded! ', 'background: #ffffff; color: #00dd00');
  
      // Activate tick function
      const tick = function () {
          requestAnimationFrame(tick);
          //modelNodeFbx.obj3D.rotation.y += 0.01;
          //modelNodeGltf.obj3D.rotation.y -= 0.01;
          //modelNodeDae.obj3D.rotation.y += 0.01;
          //turbineModelObj.obj3D.rotation.y -= 0.01;
          //nodeObjModel2.obj3D.rotation.y += 0.01;
          nodeObjModel3.obj3D.rotation.y += 0.01;
      }
      tick();
  
      // ==================== TransformControls ====================
  
      // Create a scene node with a transform control component.
      // This node's transform will be changed by the transform control.
      const nodeToTranslation = await sdk.Scene.createNode();
      const nodeToRotation = await sdk.Scene.createNode();
      const nodeToScale = await sdk.Scene.createNode();
      const myControlTranslate = nodeToTranslation.addComponent('mp.transformControls');
      const myControlRotate = nodeToRotation.addComponent('mp.transformControls');
      const myControlScale = nodeToScale.addComponent('mp.transformControls');
      nodeToTranslation.start();
      nodeToRotation.start();
      nodeToScale.start();
  
      // Make the transform control visible so that the user can manipulate the control selection.
      myControlTranslate.inputs.visible = true
      myControlRotate.inputs.visible = true;
      myControlScale.inputs.visible = true;
  
      // set 'translate' mode to position the selection.
      myControlTranslate.inputs.mode = 'translate';
      // set 'rotate' mode to orientation the selection.
      myControlRotate.inputs.mode = 'rotate';
      // set 'scale' mode to rescale the selection.
      myControlScale.inputs.mode = 'scale';
  
      // Attach the model to the transform control
      myControlTranslate.inputs.selection = turbineModelObj;
      myControlRotate.inputs.selection = turbineModelObj;
      myControlRotate.inputs.selection = tankModelGltf;
      myControlScale.inputs.selection = trooperModelDae;
  
      // ==================== Inputs ====================
  
      // CLICK
      // Create a scene node with an input component.
      // Note you will probably only need one instance of an input component
      // for your entire application.
      const nodeInputInteractionClick = await sdk.Scene.createNode();
      const inputClickComponent = nodeInputInteractionClick.addComponent('mp.input', {
          eventsEnabled: true,
          userNavigationEnabled: false,
      });
      nodeInputInteractionClick.start();
  
      // Define a click event spy
      class ClickSpy {
          public eventType: 'INTERACTION.CLICK';
          public onEvent(payload: unknown) {
              console.log('received', payload);
          }
      }
  
      // Spy on the click event
      inputClickComponent.spyOnEvent(new ClickSpy());
  
      // You can enable navigation after starting the node.
      inputClickComponent.inputs.userNavigationEnabled = true;
  
      // You can turn off all events and the spy wont receive andy callbacks.
      inputClickComponent.inputs.eventsEnabled = false;
  
  
      // POINTER_BUTTON
      // Create a scene node with an input component.
      // Note you will probably only need one instance of an input component
      // for your entire application.
      const nodeInputInteractionPointer = await sdk.Scene.createNode();
      const inputPointerComponent = nodeInputInteractionPointer.addComponent('mp.input', {
          eventsEnabled: true,
          userNavigationEnabled: false,
      });
      nodeInputInteractionPointer.start();
  
      // Define a click event spy
      class HoverSpy {
          public eventType: 'INTERACTION.POINTER_BUTTON';
          public onEvent(payload: unknown) {
              console.log('received', payload);
          }
      }
  
      // Spy on the click event
      inputPointerComponent.spyOnEvent(new HoverSpy());
  
      // You can enable navigation after starting the node.
      inputPointerComponent.inputs.userNavigationEnabled = true;
  
      // You can turn off all events and the spy wont receive andy callbacks.
      inputPointerComponent.inputs.eventsEnabled = false;
  
  
      /*
      // ==================== Camera ====================
  
      // Create a three.js camera object that will be the acting as the source for the camera component.
      const myCamera = new THREE.PerspectiveCamera(45, 1.333, 1, 1000);
  
      // Create a scene node with a camera component.
      // Set it up to request control after
      // the node is started by setting enabled to true.
      const nodeCamera = await sdk.Scene.createNode();
      const cameraComponent = nodeCamera.addComponent('mp.camera', {
          enabled: true,
          camera: myCamera,
      });
  
      // Define a spy that intercepts ONACCESSGRANTED events
      class AccessGrantedSpy {
          public eventType: 'ONACCESSGRANTED';
          public onEvent(payload: unknown) {
              // myCamera is now enabled, changes to your camera will
              // be reflected on the showcase camera.
          }
      }
  
      // spy on the camera before it starts.
      cameraComponent.spyOnEvent(new AccessGrantedSpy());
  
      // start the camera node
      nodeCamera.start();
  
      // ==================== XR ====================
  
      // Binding to the mp.xr component from another component
  
      // Assumes MyComponent has a session input and handled
      // onInputsChanged()
      var nodeXR = await sdk.Scene.createNode();
      var xr = nodeXR.addComponent('mp.xr');
      var myComponent = nodeXR.addComponent('myComponent');
      myComponent.bind('session', xr, 'session');
      nodeXR.start();
  
      // Observing the mp.xr session from outside the component system.
      var nodeExternalXR = await sdk.Scene.createNode();
      var xrExt = nodeExternalXR.addComponent('mp.xr');
      nodeExternalXR.start();
  
      // The outputs object is an ObservableProxy which supports observing property changes.
      // See @mp/core/src/observable/ObservableProxy.ts in the sdk examples repo.
      xrExt.onPropertyChanged('session', (session: any) => {
          console.log(`session changed:`, session);
      });
  
      */
  
  });

    
  }

  render() {
    return (
        <div className='frame'>
            <iframe id='showcase' className='frame' src={this.src}></iframe>
        </div>    
    );
  }

}
