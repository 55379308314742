import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('lobbyTV', false);
// lobbyTV.setPosition({
//     x: -30.85,
//     y: 1.3, // 2.052, //-2.138,
//     z: -4.6
// })

// lobbyTV.setRotation({
//     x: 0,
//     y: 212,
//     z: 0
// });

// lobbyTV.setScale({
//     x: 1.5,
//     y: 1.2,
//     z: 1
// })

lobbyTV.setPosition({
    x: -26.51,
    y: 1.61,
    z: -6.2
})

lobbyTV.setRotation({
    x: 4,
    y: -90,
    z: 0
});

lobbyTV.setScale({
    x: 2.0,
    y: 1.6,
    z: 1
})
let videoPath = '/assets/videos/dwc/DWC.mp4';

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;