import { Graphic } from '@mp/scene-components';

const graphicOutsideTemperature = new Graphic('graphicOutsideTemperature', 'Outside Air Temperature last 24 hours');
graphicOutsideTemperature.setPosition({
    "x": 28,
    "y": 2.2,
    "z": -2.2
})

graphicOutsideTemperature.setRotation({
    "x": 0,
    "y": 270,
    "z": 0
});

graphicOutsideTemperature.setScale({
    "x": 1.3,
    "y": 1.3,
    "z": 1.3
})

graphicOutsideTemperature.setDataPointColor('green');
graphicOutsideTemperature.setShowPoints(true);
graphicOutsideTemperature.setXLabel('Time (hh:mm)');
graphicOutsideTemperature.setYUnit('ºF');
graphicOutsideTemperature.setDataPointMargin(.3);


graphicOutsideTemperature.setSiteControlAPI('95', '5', 'sensor', 'OSA');



export default graphicOutsideTemperature.Graphic;