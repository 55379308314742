import gaugeTemperature  from './gaugeTemperature';
import graphicTemperature  from './graphicTemperature';

import gaugeRelativeHumidity  from './gaugeRelativeHumidity';
import graphicRelativeHumidity  from './graphicRelativeHumidity';

import graphicOutsideTemperature  from './graphicOutsideTemperature';
import gaugeOutsideTemperature  from './gaugeOutsideTemperature';

export const scene = {
    version: "1.0",
    payload: {
        objects: [
            gaugeTemperature,
            graphicTemperature,
            gaugeRelativeHumidity,
            graphicRelativeHumidity,
            graphicOutsideTemperature,
            gaugeOutsideTemperature
        ]
    }
}