import lobbyTV  from './LobbyScreens';
import coffeTV  from './CoffeTV';
import meetingTV  from './MeetingTV';

export const SceneObjects = {
    version: "1.0",
    payload: {
        objects: [
            lobbyTV,
            coffeTV,
            meetingTV
        ]
    }
}