import { Webcam } from '@mp/scene-components';

const webcam = new Webcam('webcam');
const isPorto = location.pathname.includes('porto');
webcam.setPosition({
    x: isPorto ? -1 : 1.2,
    y: 2,
    z: isPorto ? 4.2 : -4.2
})

webcam.setRotation({
    x: 0,
    y: isPorto ? 180 : 0,
    z: 0
});

webcam.setScale({
    x: .8,
    y: .8,
    z: .01
})

webcam.flipX();


export default webcam.Video;