import { Gauge } from '@mp/scene-components';

const gaugeSpeed = new Gauge('gaugeSpeed', true);
const isPorto = location.pathname.includes('porto');
gaugeSpeed.setPosition({
    x: .5,
    y: 1.5,
    z: isPorto ? 4.2 : -4.2
})

gaugeSpeed.setRotation({
    x: 0,
    y: isPorto ? 180 : 0,
    z: 0
});

gaugeSpeed.setScale({
    x: .5,
    y: .5,
    z: .5
})

gaugeSpeed.setUnit('RPM');

gaugeSpeed.setMqttClient("b5ecfdc04d7d4b50a9187596a75bd5e0.s1.eu.hivemq.cloud", 8884, "siemens_hivemq", "sv$dmb0N5MDEPpL1:8!<", true, 'speed');

gaugeSpeed.setTitle('Velocidade');
gaugeSpeed.setMinMaxValues(0, 1500);
gaugeSpeed.setRange([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500])
gaugeSpeed.setHighlights([
    {
        "from": 0,
        "to": 750,
        "color": "rgba(0, 192, 0, 1.0)"
    },
    {
        "from": 750,
        "to": 1250,
        "color": "rgba(192, 192, 0, 1.0)"
    },
    {
        "from": 1250,
        "to": 1500,
        "color": "rgba(192, 0, 0, 1.0)"
    }
])


export default gaugeSpeed.Gauge;