import {  Video } from '@mp/scene-components';

const learningCenterTV = new Video('learningCenterTV', false);
learningCenterTV.setPosition({
    x: 4.655,
    y: -2.35,
    z: -0.48
})

learningCenterTV.setRotation({
    x: 3.5,
    y: 88.3,
    z: 0
});

learningCenterTV.setScale({
    x: 1.37,
    y: 1.14,
    z: 1
})
let videoPath = '/assets/videos/ga-400/imagefilm_sitrain_digital_industry_academy_en.mp4';

const learningCenterVideoURL = window.location.origin + videoPath;
learningCenterTV.setUrls([learningCenterVideoURL])

export default learningCenterTV.Video;