import { SceneComponent, ComponentOutput } from '../SceneComponent';
import Paho from 'paho-mqtt';

type Inputs = {
    clientId: string,
    hostname: string,
    port: number,
    topic: string,
    qosTopic: Paho.Qos,
    keepAlive: number,
    timeout: number,
    tls: boolean,
    cleanSession: boolean
    publishMessage: string,
    qosMessage: Paho.Qos,
    retainMessage: boolean,
    autoStream: boolean,
    updateInterval: number,
};

type Outputs = {
    receivedMessage: string
} & ComponentOutput;

class MqttLoader extends SceneComponent {
    private client: Paho.Client;
    private connectionString: string;
    private streamValues: number = 0;
    private streamValuesChangeRange: number = 5;
    private subscripted: boolean = false;
    private currentTime: number = 0;
    private nextUpdate: number = 0;

    inputs: Inputs = {
        clientId: 'clientId-' + parseInt((Math.random() * 10000).toString(), 0),
        hostname: 'broker.mqttdashboard.com',
        port: 8000,
        topic: 'myTopic/nameValue',
        qosTopic: 0,
        keepAlive: 60,
        timeout: 180,
        tls: true,
        cleanSession: true,
        publishMessage: '',
        qosMessage: 0,
        retainMessage: false,
        autoStream: false,
        updateInterval: 500
    }

    outputs = {
        receivedMessage: ''
    } as Outputs;

    onInit() {
        this.startConnect();
    }

    onInputsUpdated() {

    }

    onTick(delta: number) {
        this.currentTime += delta;

        if (this.currentTime > this.nextUpdate) {
            this.nextUpdate += this.inputs.updateInterval;

            // Generate numbers between 0 and 99 to stream
            this.generate0to99Numbers();

            // publish message to MQTT broker
            this.inputs.publishMessage = this.streamValues.toFixed(1);

            if (this.subscripted === true && this.inputs.autoStream) {
                // to stream data to the topic
                this.publishMessage();
            }
        }
    }

    onDestroy() {
        this.unsubscribeTopic();
        this.startDisconnect();
    }

    // Generation of numbers between 0 and 99 to stream
    private generate0to99Numbers() {
        this.streamValues += (Math.random() * this.streamValuesChangeRange);
        this.streamValues = Math.trunc(this.streamValues);

        if (this.streamValues > 99) {
            this.streamValues = 99;
            this.streamValuesChangeRange = -this.streamValuesChangeRange;
        }
        if (this.streamValues < 0) {
            this.streamValues = 0;
            this.streamValuesChangeRange = -this.streamValuesChangeRange;
        }
    }

    // Called to Start Connection with broker
    private startConnect() {
        var clientId = this.inputs.clientId + parseInt((Math.random() * 10000).toString(), 0);
        var hostname = this.inputs.hostname;
        var port = this.inputs.port;
        var keepAlive = this.inputs.keepAlive;
        var timeout = this.inputs.timeout;
        var tls = this.inputs.tls;
        var cleanSession = this.inputs.cleanSession;

        // Initialize new Paho client connection
        this.client = new Paho.Client(hostname, port, clientId);

        // Set callback handlers
        this.client.onConnectionLost = this.onConnectionLost.bind(this);
        this.client.onMessageArrived = this.onMessageArrived.bind(this);

        // see client class docs for all the options
        var options = {
            invocationContext: { host: hostname, port: port, clientId: clientId },
            timeout: timeout,
            keepAliveInterval: keepAlive,
            cleanSession: cleanSession,
            useSSL: tls,
            onSuccess: this.onConnected.bind(this),
            onFailure: this.onFailure.bind(this)
        };

        // Connect the client, if successful, call onConnected function
        this.client.connect(options);

        this.connectionString = options.invocationContext.host + ":" + options.invocationContext.port;
        console.log("onStartConnect: Connecting on '" + this.connectionString + "' ... ");
    }

    // Called when the client connects successfully
    private onConnected(context: any) {
        console.log("onConnected: Connected successfully on '" + this.connectionString + "' with client ID '" + context.invocationContext.clientId + "' .");

        // Call the subscribe method
        this.subscribeTopic();
    }

    // Called if client can't connects
    private onFailure(context: any) {
        console.log("onFailure: " + context.errorMessage);
    }

    // Called when the client loses its connection
    private onConnectionLost(context: any) {
        console.log("onConnectionLost: Connection Lost.");
        if (context.errorCode !== 0) {
            console.log("onConnectionLost: " + context.errorMessage);
        }
    }

    // Called when the client subscribes a Topic
    private subscribeTopic() {
        var topic = this.inputs.topic;
        var qos = this.inputs.qosTopic;

        var options = {
            onSuccess: this.subscribeSuccess.bind(this),
            onFailure: this.subscribeFailure.bind(this),
            invocationContext: { topic: topic, qos: qos }
        };

        console.log("Subscribing to Topic: '" + options.invocationContext.topic + "' with QoS: " + options.invocationContext.qos + " ... ");

        // Subscribe to the requested topic
        this.client.subscribe(topic, options);
    }

    // Called when the client subscribes a topic successfully
    private subscribeSuccess(context: any) {
        console.log("Subscribed. [Topic: '" + context.invocationContext.topic + "']");
        this.subscripted = true;
    }

    // Called when the client can't subscribes a topic 
    private subscribeFailure(context: any) {
        console.log("Failed to subscribe. [Topic: '" + context.invocationContext.topic + "']");
        this.subscripted = false;
    }

    // Called when the client unsubscribe a Topic
    private unsubscribeTopic() {
        var topic = this.inputs.topic;
        var qos = this.inputs.qosTopic;

        var options = {
            onSuccess: this.unsubscribeSuccess,
            onFailure: this.unsubscribeFailure,
            invocationContext: { topic: topic, qos: qos }
        };

        console.log("Unsubscribing topic: '" + options.invocationContext.topic + "' with QoS: " + options.invocationContext.qos + " ... ");

        // Unsubscribe to the requested topic
        this.client.unsubscribe(topic, options);
    }

    // Called when the client unsubscribes a topic successfully
    private unsubscribeSuccess(context: any) {
        console.log("Unsubscribed. [Topic: '" + context.invocationContext.topic + "']");
        this.subscripted = false;
    }

    // Called when the client can't unsubscribes a topic 
    private unsubscribeFailure(context: any) {
        console.log("Failed to unsubscribe. [Topic: '" + context.invocationContext.topic + "']");
    }

    // Called when a message arrives
    private onMessageArrived(message: any) {
        //console.log("onMessageArrived: " + message.payloadString);
        this.outputs.receivedMessage = message.payloadString;
    }

    // Creates a new Messaging.Message Object and sends it to the HiveMQ MQTT Broker
    private publishMessage() {
        var topic = this.inputs.topic;
        var payload = this.inputs.publishMessage;
        var msgQos = this.inputs.qosMessage;
        var msgRetain = this.inputs.retainMessage;

        //Send your message (also possible to serialize it as JSON or protobuf or just use a string, no limitations)
        var message = new Paho.Message(payload);
        message.destinationName = topic;
        message.qos = msgQos;
        message.retained = msgRetain;
        this.client.send(message);
        //console.log("onPublishMessage: " + message.payloadString);
    }

    // Called to disconnect client from the broker
    private startDisconnect() {
        this.client.disconnect();
        console.log("Connection: Disconnected.");
    }
}

export const mqttLoaderType = 'mp.mqttLoader';
export function makeMqttLoader() {
    return new MqttLoader();
}
