import microGridScreen  from './MicroGridScreen';
import SiemensCover from './SiemensCover';


export const SceneObjects = {
    version: "1.0",
    payload: {
        objects: [
            microGridScreen,
            SiemensCover
        ]
    }
}