// import * as THREE from "three";

type Inputs = {
    enabled: boolean,
    path: any,
    verticalOffset: number
}

class SplinePath {
  inputs: Inputs = {
    enabled: true,
    path: null,
    verticalOffset: -1.0,
  };

  points: any;
  curve: any;
  tubeGeometry: any;
  material: any;
  mesh: any;

  onInit = async function () {
    const THREE = this.context.three;
    // this.material = new THREE.MeshBasicMaterial({ color: 0x88ff88 }); // green
    this.material = new THREE.MeshBasicMaterial({ color: 0x236BB2 }); // light blue
    
  };

  onInputsUpdated = function (previousInputs: any) {
    const THREE = this.context.three;
    
    const path = this.inputs.path;
    if (path !== previousInputs.path && path.length > 1) {
      console.log(path);
      this.points = path.map((obj: any) => {
        return new THREE.Vector3(
          obj.data.position.x,
          obj.data.position.y + this.inputs.verticalOffset,
          obj.data.position.z,
        );
      });

      this.curve
        ? (this.curve.points = this.points)
        : (this.curve = new THREE.CatmullRomCurve3(
            this.points,
            false,
            "catmullrom",
            0.25,
          ));

      this.tubeGeometry && this.tubeGeometry.dispose();
      this.tubeGeometry = new THREE.TubeGeometry(this.curve, 64, 0.1, 8, false);

      this.mesh = new THREE.Mesh(this.tubeGeometry, this.material);
    }

    if (!this.inputs.enabled || path.length <= 1) {
      this.outputs.objectRoot = null;
    } else {
      this.outputs.objectRoot = this.mesh;
    }
  };

  onDestroy = function () {
    this.tubeGeometry.dispose();
    this.material.dispose();
  };
}

export function SplinePathFactory() {
  return new SplinePath();
}
