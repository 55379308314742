import { SceneComponent, ComponentOutput } from '../SceneComponent';

type Inputs = {
    urls: string[];
    urlIndex: string;
};

type Outputs = {
    src: string;
    index: string;
} & ComponentOutput;

enum Event {
    Next = 'next',
    Previous = 'previous'
};

type Events = {
    [Event.Next]: boolean,
    [Event.Previous]: boolean
};

class Tuner extends SceneComponent {
    private urlIndex = 0;
    private index = 0;

    inputs = {
        urls: [],
    } as Inputs;

    outputs = {
        src: '',
        index: '',
    } as Outputs;

    events: Events = {
        [Event.Next]: true,
        [Event.Previous]: true
    };

    onInit() {
        this.outputs.src = this.inputs.urls.length > 0 ? this.inputs.urls[0] : '';
    }

    onEvent(eventType: string, eventData: unknown) {
        if (eventType === Event.Next) {
            this.urlIndex++;
            if (this.urlIndex >= this.inputs.urls.length) {
                this.urlIndex = 0;
            }  
        }
        else if (eventType === Event.Previous) {
            this.urlIndex--;
            if (this.urlIndex < 0) {
                this.urlIndex = this.inputs.urls.length - 1;
            }
        }

        if (this.inputs.urls.length > 0) {
            this.outputs.src = this.inputs.urls[this.urlIndex];
        }
    }
    
    onTick() {
        this.index = this.urlIndex + 1;
        this.outputs.index = `CH ${(this.index).toString()}`;
    }
}

export const tunerType = 'mp.tuner';
export function makeTuner() {
    return new Tuner();
}
