import { Graphic } from '@mp/scene-components';

const graphicRelativeHumidity = new Graphic('graphicRelativeHumidity', 'DevLab Relative Humidity last 24 hours');
graphicRelativeHumidity.setPosition({
    "x": 9.70,
    "y": 1.65,
    "z": 5.32
})

graphicRelativeHumidity.setRotation({
    "x": 0,
    "y": 180,
    "z": 0
});

graphicRelativeHumidity.setScale({
    "x": 1.0,
    "y": 1.0,
    "z": 1.0
})

graphicRelativeHumidity.setDataPointColor('orange');
graphicRelativeHumidity.setDataPointNameSerie('Relative Humidity');
graphicRelativeHumidity.setShowPoints(false);
graphicRelativeHumidity.setXLabel('Time (hh:mm)');
graphicRelativeHumidity.setYUnit('%');
graphicRelativeHumidity.setDataPointMargin(.3);


graphicRelativeHumidity.setSiteControlAPI('95', '5', 'sensor', 'CT53');


export default graphicRelativeHumidity.Graphic;