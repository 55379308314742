import learningCenterTV  from './LearningCenterTV';
import lobbyTV  from './LobbyTV';
import learningCenterStreaming  from './LearningCenterStreaming';
import sitrainWallpaper from './SitrainWallpaper';
import posterText from './PosterText';
import whiteBoard1 from './WhiteBoard1';
import whiteBoard2 from './WhiteBoard2';
import whiteBoard3 from './WhiteBoard3';
import whiteBoard4 from './WhiteBoard4';

export const scene = {
    version: "1.0",
    payload: {
        objects: [
            learningCenterTV,
            lobbyTV,
            learningCenterStreaming,
            sitrainWallpaper,
            posterText,
            whiteBoard1,
            whiteBoard2,
            whiteBoard3,
            whiteBoard4
        ]
    }
}