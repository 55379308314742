import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { DataPoint, DataPoints } from './D3Handler';
import { Clock } from 'three';

import SiteControlAPI from '../api/site-control';
import axios from 'axios';

type Inputs = {
    dataStreamFrequency: number, // in seconds
    enabled: boolean,
    range: Array<number>
    receivedValue: string,
    deviceType: string;
    deviceKey: string;
    clientId: string;
    siteId: string;
    dataPointMargin: number;
    enlightedApi: {
        spaceId: string,
        apiKey: string
    } | null
}

type Outputs = {
    datapoint: DataPoint,
    datapoints: DataPoints,
    yDomain: number[]
} & ComponentOutput;

class DispersionDataGenerator extends SceneComponent {

    inputs: Inputs = {
        dataStreamFrequency: 1,
        enabled: true,
        range: [0, 100],
        receivedValue: '',
        deviceType: '',
        deviceKey: '',
        clientId: '',
        siteId: '',
        dataPointMargin: 0,
        enlightedApi: null
    }

    outputs = {
        datapoint: null,
        datapoints: null,
        yDomain: null
    } as Outputs;

    private clock: Clock;
    private SiteControl: SiteControlAPI;
    private delta: number = 0;
    private autoGenerate: Boolean = false;
    // private currentTime: number = 0;
    // private nextUpdate: number = 0;

    async onInit(){
        this.SiteControl = new SiteControlAPI(this.inputs.clientId, this.inputs.siteId)

        this.clock = new Clock(false);
        if(this.inputs.enabled)
            this.clock.start();

        if (this.inputs.enlightedApi) {
            await this.getEnlightedData();
        } else if (this.inputs.clientId) {
            await this.getData();
        } else {
            this.autoGenerate = true;
        }
        
    }

    async onTick(delta: number) {
        if(!this.inputs.enabled) return;
        if (this.autoGenerate) {
            const sec = Math.floor(this.clock.getElapsedTime()) * this.inputs.dataStreamFrequency;
            if(this.delta < sec){
                this.delta = sec;
                const value = Math.floor(Math.random() * 100) + 1;
                this.outputs.datapoints = null;
                this.outputs.datapoint = this.genDatapoint(new Date(), value);
            }
        }
    }

    async getLiveData() {

        let response
        try {
            response = await this.SiteControl.getLiveData(this.inputs.deviceType);
        } catch (error) {
            console.log('#Failed to get data', error);
        }
        let value = 0;
        if (this.inputs.deviceKey && response.data) {
            const device = response.data.find((d: any) => d.key === this.inputs.deviceKey);
            value = device.dataset['zone-i18n'].value
            console.log('#live data value', value)
            this.outputs.datapoints = null;
            this.outputs.datapoint = this.genDatapoint(new Date(), value);
        }

        if (value < this.outputs.yDomain[0]) {
            this.outputs.yDomain = [value - this.inputs.dataPointMargin, this.outputs.yDomain[1]]
        } 
        if (value > this.outputs.yDomain[1]) {
            this.outputs.yDomain = [this.outputs.yDomain[0], value + this.inputs.dataPointMargin]
        } 
    }

    async getData(test?: boolean) {
        const points:DataPoints = [];

        let response
        try {
            if (this.inputs.deviceType === 'hvac') {
                response = await this.SiteControl.getHvacData(this.inputs.deviceKey);
            }
            if (this.inputs.deviceType === 'sensor') {
                response = await this.SiteControl.getSensorData(this.inputs.deviceKey);
            }
        } catch (error) {
            console.log('#Failed to get data', error);
        }

        let minValue: number | null = null;
        let maxValue: number | null = null;

        response.data.forEach((item: any, key: number) => {
            let date: Date;
            let value: number;
            if (this.inputs.deviceType === 'hvac') {
                date = new Date(item.ts.value);
                value = item.zone.value;
            }
            if (this.inputs.deviceType === 'sensor') {
                date = new Date(item.timeStamp.value);
                value = item.value.value;
            }
            const precision = 100;
            
            if (test) value = value + Math.floor(Math.random() * (10 * precision - 1 * precision) + 1 * precision) / (1*precision);

            if (this.isIn24h(date)) {
                if (maxValue === null || value > maxValue) {
                    maxValue = value;
                }
                if (minValue === null || value < minValue) {
                    minValue = value;
                }
                points.push(this.genDatapoint(date, value));
            }
        });

        this.outputs.yDomain = [minValue - this.inputs.dataPointMargin, maxValue + this.inputs.dataPointMargin];
        this.outputs.datapoints = points;
    }

    async getEnlightedData() {
        const points:DataPoints = [];

        const liveUrl = 'https://api.alpha.enlightedinc.com/v0/utilization/1/occupancy';

        const today = new Date();
        const yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));

        const defaultStartDate = `${yesterday.getFullYear()}-${('0' + (yesterday.getMonth()+1)).slice(-2)}-${('0' + yesterday.getDate()).slice(-2)} ${('0' + yesterday.getHours()).slice(-2)}:${('0' + yesterday.getMinutes()).slice(-2)}:${('0' + yesterday.getSeconds()).slice(-2)}`; 
        const defaultEndDate = `${today.getFullYear()}-${('0' + (today.getMonth()+1)).slice(-2)}-${('0' + today.getDate()).slice(-2)} ${('0' + today.getHours()).slice(-2)}:${('0' + today.getMinutes()).slice(-2)}:${('0' + today.getSeconds()).slice(-2)}`; 
        
        console.log('#DEFAULT DATE', defaultEndDate);

        let response
        try {
            response = await axios.get(liveUrl, { 
                params: {
                    frequency: 'all',
                    start_datetime: defaultStartDate,
                    end_datetime: defaultEndDate,
                    time_period: 'all day'
                },
                headers: { 
                    'Accept': 'application/json', 
                    'X-API-Key-People-Counting': 'F,@<zDzkuP(\\usm}9&nokt&Fh9WOJp]:V*$xv5x%I%d+Y/RX>o'
                }
            });
        } catch (error) {
            console.log('#Failed to get data', error);
        }
        
        let minValue: number | null = null;
        let maxValue: number | null = null;

        response.data.forEach((item: any, key: number) => {
            let date: Date;
            let value: number;

            value = item.people_count;

            date = new Date(item.start_time);


            if (maxValue === null || value > maxValue) {
                maxValue = value;
            }
            if (minValue === null || value < minValue) {
                minValue = value;
            }

            points.push(this.genDatapoint(date, value));
        });

        this.outputs.yDomain = [minValue - this.inputs.dataPointMargin, maxValue + this.inputs.dataPointMargin];

        // let count = 0;
        // const interval = setInterval(() => {
        //     if (points[count]) {
        //         this.outputs.datapoint = points[count];
        //         count++;
        //     } else {
        //         clearInterval(interval);
        //     }
        // }, 100)

        this.outputs.datapoints = points;
    }

    isIn24h(then: Date): boolean {
        // const then = new Date('2022-01-24T09:30:20');
        const now = new Date();
        const msBetweenDates = Math.abs(then.getTime() - now.getTime());
        // 👇️ convert ms to hours                  min  sec   ms
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates < 24) {
            // console.log('date is within 24 hours');
            return true;
        } else {
            // console.log('date is NOT within 24 hours');
            return false;
        }
    }

    onDestroy(){
        this.clock.stop();
    }

    private genDatapoint(date: Date, value: number) {
        return {
            x: date ||new Date(),
            y: value || Number(this.inputs.receivedValue)
        } as DataPoint;
    }
}

export const dispersionDataGeneratorType = 'mp.dispersionDataGenerator';
export function makeDispersionDataGenerator() {
  return new DispersionDataGenerator();
}
