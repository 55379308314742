import { Text } from '@mp/scene-components';

const PosterText = new Text('posterText', 'Welcome to SITRAIN Digital Industry Academy Learning Center')

PosterText.setPosition({
    x: 3.631, // 3.541, //3.631,
    y: -2.850, // 1.026, //-2.850,
    z: 2.760 // 2.970 //2.760
})

PosterText.setRotation({
    x: -7,
    y: -113.4,
    z: 0
});

PosterText.setScale({
    x: 0.6,
    y: 0.25,
    z: 0.5
})

PosterText.setTextPosition({
    x: 20,
    y: 50,
    z: 0
})

PosterText.setBackgroundColor('#01A7A7'); // #02D0CE 01A7A7

PosterText.setTextColor('white');
PosterText.setTextBorderColor('white');
PosterText.setFont('normal 40px Verdana');
PosterText.setTextWidth(512);
PosterText.setTextAlign('left');


export default PosterText.Text;