import { Graphic } from '@mp/scene-components';

const graphicTemperature = new Graphic('graphicTemperature', 'Dev Lab Air Temperature last 24 hours');
graphicTemperature.setPosition({
    "x": 11.5,
    "y": 1.65,
    "z": 5.32
})

graphicTemperature.setRotation({
    "x": 0,
    "y": 180,
    "z": 0
});

graphicTemperature.setScale({
    "x": 1.0,
    "y": 1.0,
    "z": 1.0
})

graphicTemperature.setDataPointColor('blue');
graphicTemperature.setShowPoints(false);
graphicTemperature.setXLabel('Time (hh:mm)');
graphicTemperature.setYUnit('ºF');
graphicTemperature.setDataPointMargin(.3);




graphicTemperature.setSiteControlAPI('95', '5', 'hvac', 'HVAC1');



export default graphicTemperature.Graphic;