// default video component values

type CoordinatesType = {
    x: number, y: number, z: number
}

export class Video {
    private videoComponent = {
        "name": "video",
        "position": {
            "x": 3.68,
            "y": -2.74,
            "z": 2.6
        },
        "rotation": {
            "x": -8,
            "y": -115,
            "z": 0
        },
        "scale": {
            "x": 1.2,
            "y": 0.9,
            "z": 1
        },
        "components": [
            {
                "type": "mp.tuner",
                "inputs": {
                    "urls": [
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_8vcr6uhy/v/11/ev/14/flavorId/1_6rhpxrm9/name/a.mp4/index.m3u8",
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_oj86xepi/v/1/ev/7/flavorId/1_ltfxekru/name/a.mp4/index.m3u8",
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_zyq81gwa/v/2/ev/7/flavorId/0_2djbsqi7/name/a.mp4/index.m3u8",
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_734lwyni/v/21/ev/21/flavorId/1_57lzr44u/name/a.mp4/index.m3u8",
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_42hc3o2k/v/2/ev/7/flavorId/0_k7v5znwp/name/a.mp4/index.m3u8",
                        "https://kalhlspd-a.akamaihd.net/fhls/p/2110741/sp/211074100/serveFlavor/entryId/1_s3fdbff3/v/1/ev/7/flavorId/1_7tp7js79/name/a.mp4/index.m3u8",
                        "https://video2archives.earthcam.com/archives/_definst_/MP4:permanent/14162/2019/07/22/1300.mp4/chunklist_w1401431259.m3u8",
                        "https://nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8",
                        "https://br_hdslive-f.akamaihd.net/i/bfssued_germany@119890/index_3776_av-p.m3u8",
                        "http://dwstream4-lh.akamaihd.net/i/dwstream4_live@131329/index_1_av-b.m3u8",
                        "http://skynews-go-hls.ak-cdn.skydvn.com/skynews/1404/latest.m3u8",
                        "https://bozztv.com/36bay2/gin/giniko_euronews_800kb_36bay2/index.m3u8",
                        "https://austchannel-live.akamaized.net/hls/live/2002736/austchannel-sport/master.m3u8",
                        "https://nbcnewshls-i.akamaihd.net/hls/live/1005206/nnn_live2/index.m3u8",
                        "http://1111296894.rsc.cdn77.org/ls-54548-1/tracks-v1a1/mono.m3u8",
                        "https://live.impresa.pt/live/sic/sic540p.m3u8",
                        "https://live.impresa.pt/live/sicnot/sicnot540p.m3u8",
                        "https://video-auth1.iol.pt/live_tvi/live_tvi/playlist.m3u8?wmsAuthSign=c2VydmVyX3RpbWU9Mi8yLzIwMjEgMTozMDo1MCBQTSZoYXNoX3ZhbHVlPVlSVlB0VXljY21xYjdKUFZFSEZZY0E9PSZ2YWxpZG1pbnV0ZXM9MTQ0MCZpZD04OGU5ZmQ4NS1mMjljLTRkODctOGVhOC1hNzg2N2RlZjgzMDY=",
                        "https://video-auth7.iol.pt/live_tvi24/live_tvi24/playlist.m3u8?wmsAuthSign=c2VydmVyX3RpbWU9Mi8yLzIwMjEgMTozMDo1MCBQTSZoYXNoX3ZhbHVlPVlSVlB0VXljY21xYjdKUFZFSEZZY0E9PSZ2YWxpZG1pbnV0ZXM9MTQ0MCZpZD04OGU5ZmQ4NS1mMjljLTRkODctOGVhOC1hNzg2N2RlZjgzMDY=",
                        "http://streamer-a01.videos.sapo.pt/live/portocanal/playlist.m3u8"
                    ]
                },
                "events": {
                    "next": true,
                    "previous": true
                }
            },
            {
                "type": "mp.videoSourceLoader"
            },
            {
                "type": "mp.videoRenderer"
            },
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "planeName": "video1",
                    "colorBackground": "#050505",
                    "transparent": true,
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    },
                    "localPosition": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "events": {
                        "INTERACTION.CLICK": true,
                        "INTERACTION.HOVER": true
                    }
                }
            },
            {
                "type": "mp.toggleState",
                "inputs": {
                    "initialState": false
                }
            },
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "planeName": "video2",
                    "transparent": true,
                    "opacity": 1,
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    }
                }
            },
            {
                "type": "mp.canvasRenderer",
                "inputs": {
                    "textureRes": {
                        "w": 1024,
                        "h": 1024
                    }
                }
            },
            {
                "type": "mp.canvasBorder",
                "inputs": {
                    "position": {
                        "x": 0,
                        "y": 0
                    },
                    "size": {
                        "h": 1024,
                        "w": 1024
                    },
                    "radius": 25
                }
            },
    
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "planeName": "video3",
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    },
                    "transparent": true,
                    "opacity": 0.8,
                    "polygonOffset": true,
                    "polygonOffsetFactor": -2
                }
            },
            {
                "type": "mp.canvasRenderer",
                "inputs": {
                    "textureRes": {
                        "w": 1024,
                        "h": 1024
                    }
                }
            },
            {
                "type": "mp.canvasText",
                "inputs": {
                    "size": {
                        "h": 1024,
                        "w": 1024
                    },
                    "text": "> Click to Play Video",
                    "font": "normal bold 50px sans-serif",
                    "color": "white",
                    "colorBorder": "black",
                    "position": {
                        "x": 512,
                        "y": 450,
                        "z": 0
                    },
                    "textWidth": 400
                }
            },
        ],
        "bindings": [
            [1, "src", 0, "src"],
            [2, "src", 1, "video"],
            [3, "texture", 2, "texture"],
            [3, "enabledBackground", 4, "state"],
            [1, "enabled", 4, "state"],
            [3, "aspect", 1, "aspect"],
    
            [6, "painter", 7, "painter"],
            [5, "texture", 6, "texture"],
            [5, "aspect", 1, "aspect"],
    
            [9, "painter", 10, "painter"],
            [8, "texture", 9, "texture"],
            [8, "visible", 4, "negated"],
        ],
        "events": [
            [4, "toggle", 3, "INTERACTION.CLICK"],
            
            [4, "hover", 3, "INTERACTION.HOVER"],
            
        ]
    }
    private tunnerComponents = [
        {
            "type": "mp.planeRenderer",
            "inputs": {
                "planeName": "video4",
                "localScale": {
                    "x": 0.05,
                    "y": 0.05,
                    "z": 0.05
                },
                "localPosition": {
                    "x": 0.4,
                    "y": -0.25,
                    "z": 0.01
                },
                "localRotation": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                }
            },
            "transparent": true,
            "opacity": 0.5,
            "polygonOffset": true,
            "polygonOffsetFactor": -2,
            "events": {
                "INTERACTION.CLICK": true,
                "INTERACTION.HOVER": true
            }
        },
        {
            "type": "mp.canvasRenderer",
            "inputs": {
                "textureRes": {
                    "w": 128,
                    "h": 128
                }
            }
        },
        {
            "type": "mp.canvasImage",
            "inputs": {
                "src": "https://virtualtour.xr.siemens.com/assets/ff.png",
                "srcPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "srcSize": {
                    "w": 128,
                    "h": 128
                },
                "destPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "destSize": {
                    "w": 128,
                    "h": 128
                }
            }
        },
        {
            "type": "mp.planeRenderer",
            "inputs": {
                "planeName": "video5",
                "localScale": {
                    "x": 0.05,
                    "y": 0.05,
                    "z": 0.05
                },
                "localPosition": {
                    "x": -0.4,
                    "y": -0.25,
                    "z": 0.01
                },
                "localRotation": {
                    "x": 0,
                    "y": 0,
                    "z": 180
                }
            },
            "transparent": true,
            "opacity": 0.5,
            "polygonOffset": true,
            "polygonOffsetFactor": -2,
            "events": {
                "INTERACTION.CLICK": true,
                "INTERACTION.HOVER": true
            }
        },
        {
            "type": "mp.canvasRenderer",
            "inputs": {
                "textureRes": {
                    "w": 128,
                    "h": 128
                }
            }
        },
        {
            "type": "mp.canvasImage",
            "inputs": {
                "src": "https://virtualtour.xr.siemens.com/assets/ff.png",
                "srcPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "srcSize": {
                    "w": 128,
                    "h": 128
                },
                "destPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "destSize": {
                    "w": 128,
                    "h": 128
                }
            }
        },
        {
            "type": "mp.planeRenderer",
            "inputs": {
                "planeName": "video6",
                "localScale": {
                    "x": 1,
                    "y": 1,
                    "z": 1
                },
                "localPosition": {
                    "x": 0,
                    "y": 0,
                    "z": 0
                },
                "transparent": true,
                "opacity": 0.8,
                "polygonOffset": true,
                "polygonOffsetFactor": -2
            }
        },
        {
            "type": "mp.canvasRenderer",
            "inputs": {
                "textureRes": {
                    "w": 1024,
                    "h": 1024
                }
            }
        },
        {
            "type": "mp.canvasText",
            "inputs": {
                "size": {
                    "h": 1024,
                    "w": 1024
                },
                "text": "CH:",
                "font": "normal bold 50px sans-serif",
                "color": "white",
                "colorBorder": "black",
                "position": {
                    "x": 512,
                    "y": 750,
                    "z": 0
                },
                "textWidth": 300
            }
        }
    ];
    private tunnerBindings = [
        [12, "painter", 13, "painter"],
        [11, "texture", 12, "texture"],
        [11, "visible", 4, "hover"],
    
        [15, "painter", 16, "painter"],
        [14, "texture", 15, "texture"],
        [14, "visible", 4, "hover"],
    
        [18, "painter", 19, "painter"],
        [17, "texture", 18, "texture"],
        [17, "visible", 4, "hover"],
        [19, "text", 0, "index"]
    ];
    private tunnerEvents = [
        [0, "next", 11, "INTERACTION.CLICK"],
        [0, "previous", 14, "INTERACTION.CLICK"],
    
        [4, "hover", 11, "INTERACTION.HOVER"],
        [4, "hover", 14, "INTERACTION.HOVER"],
        [4, "hover", 17, "INTERACTION.HOVER"],
        [12, "repaint", 13, "paint.ready"],
        [15, "repaint", 16, "paint.ready"],
        [18, "repaint", 19, "paint.ready"]
    ];
    private video: any;
    constructor( name: string, stream: boolean = false, tunner: boolean = false) {
        this.video = {...this.videoComponent};
        this.video.name = name;
        if (stream) {
            if (this.video.components[1].type === 'mp.videoSourceLoader') {
                this.video.components[1].type = 'mp.hlsLoader';
            } else {
                this.video.components[1].type = 'mp.videoSourceLoader';
            }
        }
        if (tunner) {
            this.video.components.push(...this.tunnerComponents);
            this.video.bindings.push(...this.tunnerBindings);
            this.video.events.push(...this.tunnerEvents)
        }
    }

    get Video(): any {
        return this.video;
    }

    public setPosition(position: CoordinatesType) {
        this.video.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.video.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.video.scale = scale;
    }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [ ...this.video.components ];
        components.forEach((component: any) => {
            if (component.type === componentType) {
                component.inputs[propertyName] = value;
            }
        });
        this.video.components = [...components]; 
    }
    public setUrls(urls: string[]) {
        this.changeComponentProperties('mp.tuner', 'urls', urls)
    }
    public setBorderColor(color: string) {
        this.changeComponentProperties('mp.canvasBorder','borderColor', color)
    }
    public setBorderShadow(shadow: boolean) {
        this.changeComponentProperties('mp.canvasBorder','shadow', shadow)
    }
    public setBackgroundColor(color: string) {
        this.changeComponentProperties('mp.planeRenderer','colorBackground', color)
    }
    public setBorderRadius(radius: number) {
        this.changeComponentProperties('mp.canvasBorder', 'radius', radius);
    }

    public setSprite() {
        // this.changeComponentProperties('mp.planeRenderer', 'sprite', true);
        const components = [...this.video.components];
          components.forEach((component: any) => {
              if (component.type === 'mp.planeRenderer') {
                  component.type = 'mp.spriteRenderer';
              }
          });
          this.video.components = [...components];
      }
    
}