import { SceneComponent, ComponentOutput, Size, IPainter2d } from '@mp/common';

// enum Event {
//   TextClicked = 'textClicked',
// }

type Inputs = {
  color: string;
  colorBorder: string;
  position: { x: number; y: number; },
  size: Size;
  radius: number;
  text: string;
  font: string;
  textWidth: number;
  textAlign: "center" | "end" | "left" | "right" | "start";
  fontFaceName: string;
  fontFaceUrl: string;
};

type Outputs = {
  painter: IPainter2d | null;
} & ComponentOutput;

// type Events = {
//   [Event.TextClicked]: boolean;
// };

class CanvasText extends SceneComponent implements IPainter2d {
  inputs: Inputs = {
    color: 'white',
    colorBorder: 'black',
    position: { x: 0, y: 0 },
    size: { h: 100, w: 100 },
    radius: 1,
    text: 'Placeholder',
    font: 'normal bold 80px sans-serif',
    textWidth: 100,
    textAlign: 'center',
    fontFaceName: '',
    fontFaceUrl: '',
  }

  outputs = {
    painter: null,
  } as Outputs;

  events = {
    ["click"]: true,
    ["hover"]: true,
  };

  onInit() {
    this.outputs.painter = this;
  }
  
  onEvent(eventType: string, eventData: unknown) {
    if (eventType === "click") {
      console.log("click");
    }
    if (eventType === "hover") {
      console.log("hover");
    }
  }
  

  onInputsUpdated() {
    this.notify('paint.ready');
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    context2d.clearRect(0, 0, size.w, size.h);
    context2d.lineWidth = 2;
    context2d.strokeStyle = this.inputs.colorBorder;
    context2d.fillStyle = this.inputs.color;
    context2d.textAlign = this.inputs.textAlign;
    context2d.textBaseline = 'top';

    if (this.inputs.fontFaceName !== '') {
      const myFont = new FontFace(this.inputs.fontFaceName, this.inputs.fontFaceUrl); //  'url(assets/fonts/myFont/myFont.otf)'
      const myself = this;
      myFont.load().then(function(font: any){
        (document as any).fonts.add(font);

        context2d.font = myself.inputs.font;
    
        wrapText(context2d, myself.inputs.text, myself.inputs.position.x, myself.inputs.position.y, myself.inputs.textWidth, 80);
      });
       
    } else {
      context2d.font = this.inputs.font;
      wrapText(context2d, this.inputs.text, this.inputs.position.x, this.inputs.position.y, this.inputs.textWidth, 80);
    }
  }
}

function wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
  maxWidth: number, lineHeight: number) {
  var words = text.split(' ');
  var line = '';

  for(var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + ' ';
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      context.strokeText(line, x, y);
      line = words[n] + ' ';
      y += lineHeight;
    }
    else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
  context.strokeText(line, x, y);
}

export const canvasTextType = 'mp.canvasText';
export function makeCanvasText() {
  return new CanvasText();
}
