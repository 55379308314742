import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('lobbyTV', false);
lobbyTV.setPosition({
    x: 0.290,
    y: 1.405, // 2.052, //-2.138,
    z: 14.053
})

lobbyTV.setRotation({
    x: 0,
    y: 180,
    z: 0
});

lobbyTV.setScale({
    x: 4.80,
    y: 2.50,
    z: 1
})
let videoPath = '/assets/videos/experience-center-mx/Main-Digital-Experience-Corte-05.mp4';

if (window.location.pathname.includes('/en')) {
    videoPath = '/assets/videos/experience-center-mx/Digital-Experience-(English).mp4';
}

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;