import { Text } from '@mp/scene-components';

const WhiteBoard3 = new Text('whiteBoard3', 'Learn as you go')

WhiteBoard3.setPosition({
    x: 10.360,
    y: -3.020,
    z: 2.100
})

WhiteBoard3.setRotation({
    x: 0,
    y: 133.2,
    z: 0
});

WhiteBoard3.setScale({
    x: 0.8,
    y: 0.2,
    z: 0
})

WhiteBoard3.setTextPosition({
    x: 20,
    y: 0,
    z: 0
})

WhiteBoard3.setTransparent(); 

WhiteBoard3.setTextColor('black');
WhiteBoard3.setTextBorderColor('black');
WhiteBoard3.setFont('normal 60px myFont');
WhiteBoard3.setNewFontFace('myFont', 'url(assets/fonts/SiemensSans-Bold.otf)');
WhiteBoard3.setTextWidth(512);
WhiteBoard3.setTextResolution(512, 150);
WhiteBoard3.setTextAlign('left');


export default WhiteBoard3.Text;