import { Image } from '@mp/scene-components';

let imagePath = '/assets/images/princeton-grid/Money-Savings-Year.jpg';

const microGridScreen = new Image('microGridScreen', imagePath)

microGridScreen.setPosition({
    x: -35.460, 
    y: 3.930, 
    z: -83.500
})

microGridScreen.setRotation({
    x: 0,
    y: 0,
    z: 0
});

microGridScreen.setScale({
    x: 2.35,
    y: 1.35,
    z: 1
})

microGridScreen.setTextureSize({
    w: 5760,
    h: 3240
})
export default microGridScreen.Image;