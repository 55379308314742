import { Image } from '@mp/scene-components';

let imagePath = '/assets/images/ga-400/future-of-learning.jpg';



const SitrainWallpaper = new Image('sitrainWallpaper', imagePath)

SitrainWallpaper.setPosition({
    x: 4.325,
    y: -1.900,
    z: 4.400
})

SitrainWallpaper.setRotation({
    x: 0,
    y: -90,
    z: 0
});

SitrainWallpaper.setScale({
    x: 2.0,
    y: 1,
    z: 1
})
SitrainWallpaper.setTextureSize({
    w: 2667,
    h: 1500
})
export default SitrainWallpaper.Image;