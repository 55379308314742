import { SceneComponent, ComponentInteractionType } from '../SceneComponent';
import { Color, Texture, Object3D, Sprite, SpriteMaterial } from 'three';

type Inputs = {
    enabledBackground: boolean;
    colorBackground: Color | null;
    texture: Texture | null;
    aspect: number;
    transparent: boolean;
    visible: boolean;
    opacity: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    localScale: { x: number; y: number; z: number; };
    localPosition: { x: number; y: number; z: number; };
    localRotation: { x: number; y: number; z: number; };
}

export class SpriteRenderer extends SceneComponent implements ISpriteRenderer {
    private sprite: Sprite;
    private pivotNode: Object3D;

    inputs: Inputs = {
        enabledBackground: false,
        colorBackground: null,
        texture: null,
        aspect: 1,
        transparent: true,
        visible: true,
        opacity: 1,
        polygonOffset: false,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        localScale: { x: 1, y: 1, z: 1 },
        localPosition: { x: 0, y: 0, z: 0 },
        localRotation: { x: 0, y: 0, z: 0 },
    }

    events = {
        [ComponentInteractionType.CLICK]: true,
        [ComponentInteractionType.HOVER]: true,
    };

    onInit() {
        const THREE = this.context.three;
        this.pivotNode = new THREE.Group();
        // const Renderer = this.context.renderer;
        // Renderer.setSize(window.innerWidth, window.innerHeight);

        // const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 500 );
        // camera.position.set( 0, 0, 100 );
        // camera.lookAt( 0, 0, 0 );


        // const points = [];
        // points.push( new THREE.Vector3( -1, 0, 0 ) );
        // points.push( new THREE.Vector3( 0, 0, 0 ) );
        // points.push( new THREE.Vector3( 0, 1, 0 ) );
        // points.push( new THREE.Vector3( 0, 0, 0 ) );
        // points.push( new THREE.Vector3( 0, 0, 1 ) );

        const material = new THREE.SpriteMaterial( {
            color: this.inputs.colorBackground,
            transparent: this.inputs.transparent,
            map: this.inputs.texture,
            opacity: this.inputs.opacity,
            polygonOffset: this.inputs.polygonOffset,
            polygonOffsetFactor: this.inputs.polygonOffsetFactor,
            polygonOffsetUnits: this.inputs.polygonOffsetUnits,
        } );
        console.log('#SPRITE DATA - Texture:', this.inputs.texture, '  - Material:', material);
        this.sprite = new THREE.Sprite( material );
        this.sprite.scale.set(this.inputs.localScale.x, this.inputs.localScale.y / this.inputs.aspect, this.inputs.localScale.z);
        this.sprite.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        // sprite.rotation.set(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD);
        

        this.pivotNode.add(this.sprite);

        this.outputs.objectRoot = this.pivotNode;
        // this.outputs.collider = this.pivotNode;
    }

    onEvent(eventType: string, eventData: unknown) {
        this.notify(eventType, eventData);
    }

    onInputsUpdated(oldInputs: Inputs) {
        if (oldInputs.transparent !== this.inputs.transparent) {
            (this.sprite.material as SpriteMaterial).transparent = this.inputs.transparent;
        }
        if (oldInputs.texture !== this.inputs.texture) {
            const material = this.sprite.material as SpriteMaterial;
            material.map = this.inputs.texture;
            material.needsUpdate = true;
        }

        if (oldInputs.visible !== this.inputs.visible) {
            this.sprite.visible = this.inputs.visible;
        }

        if (oldInputs.polygonOffset !== this.inputs.polygonOffset) {
            const material = this.sprite.material as SpriteMaterial;
            material.polygonOffset = this.inputs.polygonOffset;
            material.polygonOffsetFactor = this.inputs.polygonOffsetFactor;
            material.polygonOffsetUnits = this.inputs.polygonOffsetUnits;
        }

        this.sprite.scale.set(this.inputs.localScale.x, this.inputs.localScale.y / this.inputs.aspect, this.inputs.localScale.z);
        this.sprite.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);

        // change background color
        if (this.inputs.enabledBackground) {
            (this.sprite.material as SpriteMaterial).color.set("white");
        }
        else {
            (this.sprite.material as SpriteMaterial).color.set(this.inputs.colorBackground);
        }
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        console.log('#Destroyed');
        (this.sprite.material as SpriteMaterial).dispose();
    }
}

export interface ISpriteRenderer extends SceneComponent {
    inputs: Inputs;
}

export const spriteRendererType = 'mp.spriteRenderer';
export function makeSpriteRenderer() {
    return new SpriteRenderer();
}
