import React, { Component } from 'react';
import {  Button } from 'react-bootstrap';
import { BiVolumeFull } from 'react-icons/bi';
import { Frame, GetSDK, initComponents, SceneComponent, ISceneNode, OrientedBox,  sdkKey } from '@mp/common';
// import { AppState } from '../AppState';
import { SceneLoader, SCENE_ID } from '../SceneLoader';
import SceneFunctions from '../helper/SceneFunctions';
// import THREE from 'three';

import axios from 'axios';

interface Props {
  // appState: AppState;
}

interface State {
  slotNode: SlotNode|null;
  showCapacity: Boolean;
  peopleCount: Number;
  capacityPercentage: Number;
  totalCapacity: Number;
}

type SlotNode = {
  node: ISceneNode;
  slotComponent: SceneComponent;
  modelComponent: SceneComponent;
  boxComponent: OrientedBox;
}

export class Main extends Component<Props, State> {
  private sdk: any = null;
  private scene: SceneLoader = null;
  private src: string;
  private applicationKey: string;
  private sceneId: string = SCENE_ID;
  
  constructor(props: Props, state: State) {
    super(props);
    
    this.state = {
      slotNode: null,
      showCapacity: false,
      peopleCount: 0,
      capacityPercentage: 0,
      totalCapacity: 0,
    };

    // Forward url params.
    const params = objectFromQuery();
    params.m = params.m || this.sceneId;
    params.play = params.play || '1'; // Play enabled
    params.title = params.title || '1'; // Show title info [Frame.tsx]
    params.help = params.help || '1'; // Present default help [Frame.tsx]
    params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
    params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
    params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
    params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
    params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
    params.dh = params.dh || '1'; // Show Dollhouse View (default)
    params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
    params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
    // params.ss = params.ss || '2'; // -> Set position scan

    // ensure applicationKey is inserted into the bundle query string
    params.applicationKey = params.applicationKey || sdkKey;
    this.applicationKey = params.applicationKey;

    const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    this.src = `./bundle/showcase.html?${queryString}`;
  }

  async componentDidMount() {
    document.title = "Singapore Gym";
    this.sdk = await GetSDK('sdk-iframe', this.applicationKey);
    await initComponents(this.sdk);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      renderer.physicallyCorrectLights = true;
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.PCFSoftShadowMap;

    });
    this.scene = new SceneLoader(this.sdk);
    let videoNode: ISceneNode;
    // let buttonNode: ISceneNode;
    await this.scene.load(this.sceneId);
    let nodeIterator = this.scene.nodeIterator();
    for (const node of nodeIterator) {
      if (node.name === 'gymTV') videoNode = node;
    }

    // const area = document.createElement( 'textarea' ); 
    // const obj = new THREE.Object3d.CSS2DObject(area); 
    // obj.position.set(x. y, z); scene.add(obj);


    console.log('Scene',this.scene);
    console.log('SDK', this.sdk);

    // this.getLiveData();

    // const myself = this;
    // setInterval(() => {
    //   myself.getLiveData();
    // }, 10000)


    const mpSdk = this.sdk;
    const sensor = await mpSdk.Sensor.createSensor(mpSdk.Sensor.SensorType.CAMERA);
    sensor.showDebug(true);

    const sources = await Promise.all([
      mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
        center: { x: 15, y: 1, z: -2.5 },
        orientation: {pitch: 0, roll: 0, yaw: 3},
        size: {x: 20, y: 3, z: 8},
        userData: {
          id: 'gym-sensor',
        },
      }),
    ]);

    const Functions = new SceneFunctions(this.scene, this.sdk);
    Functions.autoPlayVideo('gymTV');
    sensor.addSource(...sources);
    let insideSensor = false;
    sensor.readings.subscribe({
      onUpdated(source: any, reading: any) {
        switch(source.userData.id) {
          case 'gym-sensor':
            if (reading.inRange && !insideSensor) {
              // alert('Entered Gym');c
              // (graphNode as any).obj3D.visible = true;
              // videoNode.obj3D.visible = true;
              // Functions.autoPlayVideo('gymTV');
              // (buttonNode as any).obj3D.visible = true;
              insideSensor = true;
            } else if (!reading.inRange) {
              // alert('Exited Gym');
              // (graphNode as any).obj3D.visible = false;
              videoNode.obj3D.visible = false;
              Functions.pauseVideo('gymTV');
              // (buttonNode as any).obj3D.visible = false;
              insideSensor = false;
            }
            break;
          default:
            break;
        }
      }
    });

    
    
    // console.log('playing video')
  }

  async getLiveData() {
    const liveUrl = 'https://api.alpha.enlightedinc.com/v0/utilization/1/occupancy/recent?frequency=hour&current_value=true&number_hours=12';

    const response = await axios.get(liveUrl, { 
      headers: { 
        'Accept': 'application/json', 
        'X-API-Key-People-Counting': 'F,@<zDzkuP(\\usm}9&nokt&Fh9WOJp]:V*$xv5x%I%d+Y/RX>o'
      }
    });
    const liveData = response.data[0];

    this.setState({ 
      capacityPercentage: liveData.occupancy_ratio * 100,
      peopleCount: liveData.people_count,
      totalCapacity: liveData.space_capacity,
      showCapacity: true
    });
  }

  render() {
    return (
      <div className='main'>
        <Button id="unmuteButton" variant="dark"><BiVolumeFull /> Enable Video Sound</Button>
        <Frame src={this.src}></Frame>
        {this.state.showCapacity && <div className='wrapper'>
          <div>
            Occupancy: {this.state.peopleCount}
          </div>
        </div>}
      </div>
    );
  }

}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): {[key: string]: string} => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
