// default image component values

type CoordinatesType = {
    x: number, y: number, z: number
}
export class Text {
    private textComponent = {
        "name": "mediaplayer",
        "position": {
            "x": -0.658,
            "y": 1.2,
            "z": 1
        },
        "rotation": {
            "x": 0,
            "y": 90,
            "z": 0
        },
        "scale": {
            "x": 0.475,
            "y": 0.475,
            "z": 0.475
        },
        "components": [
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "planeName": "Text plane1",
                    "transparent": false,
                    "colorBackground": "#00BED0",
                },
            },
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "localScale": {
                        "x": 1,
                        "y": 1,
                        "z": 1
                    },
                    "localPosition": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "transparent": true,
                    "polygonOffset": true,
                    "polygonOffsetFactor": -2
                },
            },
            {
                "type": "mp.canvasRenderer",
                "inputs": {
                    "textureRes": {
                        "w": 512,
                        "h": 512
                    }
                },
            },
            {
                "type": "mp.canvasText",
                "inputs": {
                    "size": {
                        "h": 512,
                        "w": 512
                    },
                    "text": "Text goes here!",
                    "font": "normal 40px sans-serif",
                    "color": "white",
                    "colorBorder": "black",
                    "position": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "textWidth": 400,
                    "textAlign": "center",
                    "fontFaceName": '',
                    "fontFaceUrl": '',
                },
                "events": {
                    "click": true,
                    "hover": true
                }
            },
        ],
        "bindings": [
            [ 2, "painter", 3, "painter" ],
            [ 1, "texture", 2, "texture" ],
        ],
    }

    private text: any;

    constructor(name: string, text: string) {
        this.text = { ...this.textComponent };
        this.text.name = name;
        this.changeComponentProperties('mp.canvasText', 'text', text);
    }

    get Text(): any {
        return this.text;
    }

    public setPosition(position: CoordinatesType) {
        this.text.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.text.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.text.scale = scale;
    }

    public setTextPosition(position: CoordinatesType) {
        this.changeComponentProperties('mp.canvasText', 'position', position);
    }

    public setBackgroundColor(color: string) {
        this.changeComponentProperties('mp.planeRenderer', 'colorBackground', color);
    }

    public setTextColor(color: string) {
        this.changeComponentProperties('mp.canvasText', 'color', color);
    }

    public setTextBorderColor(color: string) {
        this.changeComponentProperties('mp.canvasText', 'colorBorder', color);
    }

    public setTransparent() {
        this.changeComponentProperties('mp.planeRenderer', 'transparent', true);
        this.text.bindings.push(
            [ 0, "texture", 2, "texture" ]
        )
    }

    public setNewFontFace(name: string, url: string) {
        this.changeComponentProperties('mp.canvasText', 'fontFaceName', name);
        this.changeComponentProperties('mp.canvasText', 'fontFaceUrl', url);

    }

    public setFont(font: string) {
        this.changeComponentProperties('mp.canvasText', 'font', font);
    }

    /**
     * Sets the maximum text width inside the plane
     * @param width 
     */
    public setTextWidth(width: number) {
        this.changeComponentProperties('mp.canvasText', 'textWidth', width);
    }

    /**
     * Sets the text resolution, lower values for bigger texts
     * @param width
     * @param height 
     */
    public setTextResolution(width: number, height: number) {
        const size = {
            w: width, 
            h: height
        }
        this.changeComponentProperties('mp.canvasRenderer', 'textureRes', size);
        this.changeComponentProperties('mp.canvasText', 'size', size);

    }

    public setTextAlign(align: string) {
        this.changeComponentProperties('mp.canvasText', 'textAlign', align);
    }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [...this.text.components];
        components.forEach((component: any) => {
            if (component.type === componentType && (propertyName in component.inputs)) {
                component.inputs[propertyName] = value;
            }
        });
        this.text.components = [...components];
    }

    public setSprite() {
        const components = [...this.text.components];
          components.forEach((component: any) => {
              if (component.type === 'mp.planeRenderer') {
                  component.type = 'mp.spriteRenderer';
              }
          });
          this.text.components = [...components];
      }

}