import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { DataPoint1 } from './D3Painter';
import { Clock } from 'three';

type Inputs = {
    dataStreamFrequency: number // in seconds
}

type Outputs = {
    datapoint: DataPoint1
} & ComponentOutput;

class SinewaveDataGenerator extends SceneComponent {

    inputs: Inputs = {
        dataStreamFrequency: 1 // must be natural number
    }

    outputs = {
        datapoint: null
    } as Outputs;

    private clock: Clock;
    private delta: number;

    onInit(){
        this.clock = new Clock(false);
        this.delta = this.clock.getElapsedTime();
        this.clock.start();
    }

    onTick(){
        const sec = Math.floor(this.clock.getElapsedTime()) * this.inputs.dataStreamFrequency;
        if(this.delta < sec){
            this.delta = sec;
            this.outputs.datapoint = this.genDatapoint(this.delta);
        }
    }

    onDestroy(){
        this.clock.stop();
    }

    private genDatapoint(i: number){
        return {
            x: 0.5 * i * Math.PI,
            y: Math.sin(0.5 * i * Math.PI)
        } as DataPoint1;
    }
}

export const sinewaveDataGeneratorType = 'mp.sinewaveDataGenerator';
export function makeSinewaveDataGenerator() {
    return new SinewaveDataGenerator();
}
