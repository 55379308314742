import speed  from './gaugeSpeed';
import temperature  from './gaugeTemperature';
import current  from './gaugeCurrent';
import webcam from './webcam';

export const SceneObjects = {
    version: "1.0",
    payload: {
        objects: [
            speed,
            temperature,
            current,
            webcam
        ]
    }
}