import { Image } from '@mp/scene-components';

let imagePath = '/assets/images/princeton-grid/siemens-logo-16x9.png';

const siemensCover = new Image('siemensCover', imagePath)

siemensCover.setPosition({
    x: -48.990, 
    y: 4.325, 
    z: -74.720
})

siemensCover.setRotation({
    x: 0,
    y: 90,
    z: 0
});

siemensCover.setScale({
    x: 2,
    y: 1,
    z: 1
})

siemensCover.setTextureSize({
    w: 1920,
    h: 1080
})
export default siemensCover.Image;