import React, { Component } from 'react';
// import { SceneLoader } from '../../SceneLoader';
// import { SceneObjects } from './scene-objects';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    // ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';

interface Props {

}

interface State {
    isNewActive: boolean,
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}


export class TimelineConferenceRoom extends Component<Props, State> {
    private src: string;
    private src2: string;
    private applicationKey: string;
    private sceneId: string = 'veQFJ25bvNw';
    private sceneId2: string = '3R5Dui6aQxE';
    // private scene: SceneLoader = null;
    // private sdk: any = null;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
            isNewActive: false
        };

         // Forward url params.
        const params = objectFromQuery();
        params.m = this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '1'; // Show title info [Frame.tsx]
        params.help = params.help || '1'; // Present default help [Frame.tsx]
        params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '1'; // Show Dollhouse View (default)
        params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
        // params.ss = params.ss || '2'; // -> Set position scan
        params.newtags = '1';

        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        params.m = this.sceneId2;
        const queryString2 = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
        this.src2 = `./bundle/showcase.html?${queryString2}`;
    }

    async componentDidMount() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        const showcase2 = document.getElementById('showcase2') as HTMLIFrameElement;

        document.title = "Timeline Conference Room";

        this.initShowcaseSDK(showcase);
        this.initShowcaseSDK(showcase2);

    }

    async initShowcaseSDK(showcase: HTMLIFrameElement) {
        const sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(sdk);

        await sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });
    }
    

    showOldModel = () => {
        const showcase = document.getElementById('showcase');
        const showcase2 = document.getElementById('showcase2');

        showcase.style.display = 'inherit';
        showcase2.style.display = 'none';

        this.setState({ isNewActive: false })
    }

    showNewModel = () => {
        const showcase = document.getElementById('showcase');
        const showcase2 = document.getElementById('showcase2');

        showcase.style.display = 'none';
        showcase2.style.display = 'inherit';

        this.setState({ isNewActive: true })
    }

    render() {
        return (
            <div className='frame'>
                {/* <button style={{ position: "absolute", left: "50%", right: "50%", width: "200px", transform: "translate(-50%, 0%)" }}
                onClick={this.switchModel}>
                    Click Me
                    </button> */}
                <div style={{ position: "absolute", left: "50%", right: "50%", width: "40%", bottom: 0, transform: "translate(-50%, 0%)" }}>
                    <ul className="timeline">
                        <li className={this.state.isNewActive ? '' : 'active-tl'} onClick={this.showOldModel}>Old Conference Room</li>
                        <li className={this.state.isNewActive ? 'active-tl' : ''} onClick={this.showNewModel}>New Conference Room</li>
                    </ul>
                </div>
                    
                <iframe id='showcase' className='frame' src={this.src}></iframe>

                <iframe id='showcase2' style={{ display: "none" }} className='frame' src={this.src2}></iframe>
            </div>    
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};