import { Gauge } from '@mp/scene-components';

const gaugeRelativeHumidity = new Gauge('gaugeRelativeHumidity');
gaugeRelativeHumidity.setPosition({
    x: 9,
    y: 1.88,
    z: 5.33
})

gaugeRelativeHumidity.setRotation({
    x: 0,
    y: 180,
    z: 0
});

gaugeRelativeHumidity.setScale({
    x: .5,
    y: .5,
    z: .5
})

gaugeRelativeHumidity.setTimeInterval(15000);
gaugeRelativeHumidity.setSiteControlAPI('95', '5', 'sensor', 'CT53');
gaugeRelativeHumidity.setUnit('%');
gaugeRelativeHumidity.setTitle('Relative Humidity');


gaugeRelativeHumidity.setMinMaxValues(0, 100);
gaugeRelativeHumidity.setRange([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100])
gaugeRelativeHumidity.setHighlights([
    {
        "from": 0,
        "to": 40,
        "color": "rgba(0, 192, 0, 1.0)"
    },
    {
        "from": 40,
        "to": 45,
        "color": "rgba(192, 192, 0, 1.0)"
    },
    {
        "from": 45,
        "to": 100,
        "color": "rgba(192, 0, 0, 1.0)"
    }
])


export default gaugeRelativeHumidity.Gauge;