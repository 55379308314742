import React, { Component } from 'react';
import { SceneLoader } from '../../SceneLoader';
import { SceneObjects } from './scene-objects';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    // ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';
import IframeLy from '../../helper/IframeLy';
import ReactGA from "react-ga4";

interface Props {

}

interface State {
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}


export class IExperienceCenterPorto extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    private sceneId: string = 'tYrGotBohRk';
    private scene: SceneLoader = null;
    private sdk: any = null;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
        };

         // Forward url params.
        const params = objectFromQuery();
        params.m = params.m || this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '1'; // Show title info [Frame.tsx]
        params.help = params.help || '1'; // Present default help [Frame.tsx]
        params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '1'; // Show Dollhouse View (default)
        params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
        // params.ss = params.ss || '2'; // -> Set position scan
        params.newtags = '1';

        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
    }

    async componentDidMount() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        //Initialize GA4
        ReactGA.initialize("G-M22JB4M4X8");
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
        });


        document.title = "iExperience Center Porto";
        this.sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(this.sdk);

        await this.sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        this.scene = new SceneLoader(this.sdk, this.sceneId, SceneObjects);
 
        await this.scene.load(this.sceneId);

        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);
        
        // replace Embedly to IframeLy
        const iframe = new IframeLy(this.sdk);
        iframe.replace();
        
    }

    render() {
        return (
            <div className='frame'>
                <iframe id='showcase' className='frame' src={this.src}></iframe>
            </div>    
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};