import { Text } from '@mp/scene-components';

const WhiteBoard1 = new Text('whiteBoard1', 'The future of learning starts now')

WhiteBoard1.setPosition({
    x: 10.420,
    y: -2.420,
    z: 2.150
})

WhiteBoard1.setRotation({
    x: 0,
    y: 133.2,
    z: 0
});

WhiteBoard1.setScale({
    x: 0.8,
    y: 0.2,
    z: 0
})

WhiteBoard1.setTextPosition({
    x: 20,
    y: 0,
    z: 0
})

WhiteBoard1.setTransparent(); 

WhiteBoard1.setTextColor('black');
WhiteBoard1.setTextBorderColor('black');
WhiteBoard1.setFont('normal 60px myFont');
WhiteBoard1.setNewFontFace('myFont', 'url(assets/fonts/SiemensSans-Bold.otf)')
WhiteBoard1.setTextWidth(512);
WhiteBoard1.setTextResolution(512, 220);
WhiteBoard1.setTextAlign('left');


export default WhiteBoard1.Text;