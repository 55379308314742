import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Size } from './PlaneRenderer';
import * as d3 from 'd3';
import { IPainter2d } from './CanvasRenderer';

export type DataPoint1 = { 
    x: number,
    y: number
};

type Inputs = {
    enabled: boolean,
    data: Array<DataPoint1> | null,
    datapoint: DataPoint1 | null,
    size: Size,
    title: string
}

type Outputs = {
    painter: IPainter2d | null
} & ComponentOutput;

class D3Painter extends SceneComponent implements IPainter2d {
    private samples = 200; // how many data points to display
    private factor = 4; // must be proportional inverse with # of samples: 200->80 = 4; 100->20 = 2; 50->5 = 1; 25->2.5 = 0.5; 10->1 = 0.2 )
    private sampleInterval = this.samples/10 * (this.factor); // interval between samples  
    private freq = this.samples/this.sampleInterval; // frequency to display points 

    private margin = 10; // top bottom margin for canvas

    inputs: Inputs = {
        enabled: true,
        data: null,
        datapoint: null,
        size: { h: 128, w: 128 }, 
        title: "",
    }

    outputs = {
        painter: null
    } as Outputs;
  
    onInit(){
       this.outputs.painter = this;

       // temp data
        this.inputs.data = [];  
    }

    onInputsUpdated(oldInputs: Inputs){
        if(this.inputs.datapoint && this.inputs.data){
            if (this.inputs.data.length >= this.samples)
            {
                this.inputs.data.shift();

            }
            this.inputs.data.push(this.inputs.datapoint);
            this.inputs.datapoint = null;
        }
        this.notify('paint.ready');
    }

    onDestroy(){}

    paint(context2d: CanvasRenderingContext2D, size: Size): void{
        // render your data with the canvas here
        this.paintLine(context2d, size);
    }

    private paintLine(context2d: CanvasRenderingContext2D, size: Size): void{
        // render your data with the canvas here
        if(!this.inputs.data) return;

        context2d.clearRect(0, 0, this.inputs.size.w, this.inputs.size.h); 
                
        // draw points
        for (let i = 0; i < this.inputs.data.length; i++) {
            const pointX = this.inputs.data[i].x * this.freq;
            const pointY = -this.inputs.data[i].y * 0.25 * this.inputs.size.h - this.margin + 0.5 * this.inputs.size.h + this.margin;

            //console.log("pointX,pointY: (" + pointX + "," + pointY + ")");
            context2d.fillStyle = 'red';
            context2d.beginPath();
            context2d.arc(pointX, pointY, 5, 0, Math.PI * 2);
            context2d.fill();
        }    

        const walkX = d3.scaleLinear()
            .domain([0, this.samples])
            .range([0, this.inputs.size.w - this.margin]);

        const walkY = d3.scaleLinear()
            .domain([-1, 1])
            .range([0.75 * this.inputs.size.h - this.margin, 0.25 * this.inputs.size.h + this.margin ]);

        // const curve = d3.curveBasis(context2d);

        const line = d3.line<DataPoint1>()
            .x(d => walkX(d.x))
            .y(d => walkY(d.y))
            .curve(d3.curveLinear)
            .context(context2d);

        // draw line
        context2d.lineWidth = 5;
        context2d.strokeStyle = 'darkgreen';
        context2d.stroke();
        context2d.beginPath();
        line(this.inputs.data);
        context2d.stroke();

        //draw title chart
        context2d.lineWidth = 2;
        context2d.font = "30px Arial";
        context2d.textAlign = "center";
        context2d.textBaseline = "middle";
        context2d.fillStyle = "black";
        context2d.strokeStyle = "darkblue";
        context2d.fillText(`${this.inputs.title}`, 256, 40);
        context2d.strokeText(`${this.inputs.title}`, 256, 40);
    }
}

export const d3PainterType = 'mp.d3Painter';
export function makeD3Painter(){
    return new D3Painter();
}