export const test: string = 'test';

export { GetSDK } from './Sdk';
export { Frame } from './components/Frame';
export * from './SceneComponent';
export * from './sdk-components/CanvasRenderer';
export * from './sdk-components/OrientedBox';
export * from './sdk-components/SecurityCamera';
export * from './sdk-components/SceneRenderer';
export * from './sdk-components/Slot';
export * from './sdk-components/PlaneRenderer';
export * from './sdk-components/SpriteRenderer';
export * from './sdk-components/VideoRenderer';
export * from './sdk-components/VideoStreamCapture';
export * from './sdk-components/NestThermostat';
export * from './sdk-components/RoomShadow';
export * from './sdk-components/ClockPainter';
export * from './sdk-components/GaugesPainter';
export * from './sdk-components/MqttLoader';
export * from './sdk-components/SinewaveDataGenerator';
export * from './sdk-components/DispersionDataGenerator';
export * from './sdk-components/D3Painter';
export * from './sdk-components/D3Handler';
export * from './sdk-components/Site-Control/GaugeData';


import { orientedBoxType, makeOrientedBox } from './sdk-components/OrientedBox';
import { slotType, makeSlot } from './sdk-components/Slot';
import { securityCameraType, makeSecurityCamera } from './sdk-components/SecurityCamera';
import { loadingIndicatorType, makeLoadingIndicator } from './sdk-components/LoadingIndicator';
import { planeRendererType, makePlaneRenderer } from './sdk-components/PlaneRenderer';
import { spriteRendererType, makeSpriteRenderer } from './sdk-components/SpriteRenderer';
import { canvasRendererType, makeCanvasRenderer } from './sdk-components/CanvasRenderer';
import { sceneRendererType, makeSceneRenderer } from './sdk-components/SceneRenderer';
import { videoRendererType, makeVideoRenderer } from './sdk-components/VideoRenderer';
import { videoStreamCaptureType, makeVideoStreamCapture } from './sdk-components/VideoStreamCapture';
import { nestThermostatType, makeNestThermostat } from './sdk-components/NestThermostat';
import { roomShadowType, makeRoomShadow } from './sdk-components/RoomShadow';
import { clockPainterType, makeClockPainter } from './sdk-components/ClockPainter';
import { scenePainterType, makeScenePainter } from './sdk-components/ScenePainter';
import { tunerType, makeTuner } from './sdk-components/Tuner';
import { hlsLoaderType, makeHlsLoader } from './sdk-components/HlsLoader';
import { mjpegPlayerType, makeMjpegPlayer } from './sdk-components/MjpegPlayer';
import { toggleStateType, makeToggleState } from './sdk-components/ToggleState';
import { canvasBorderType, makeCanvasBorder } from './sdk-components/CanvasBorder';
import { canvasTextType, makeCanvasText } from './sdk-components/CanvasText';
import { canvasImageType, makeCanvasImage } from './sdk-components/CanvasImage';
import { cameraInputType, makeCameraInput } from './sdk-components/Camera';
import { videoSourceLoaderType, makeVideoSourceLoader } from './sdk-components/VideoSourceLoader';
import { gaugesPainterType, makeGaugesPainter } from './sdk-components/GaugesPainter';
import { mqttLoaderType, makeMqttLoader } from './sdk-components/MqttLoader';
import { sinewaveDataGeneratorType, makeSinewaveDataGenerator } from './sdk-components/SinewaveDataGenerator';
import { d3PainterType, makeD3Painter } from './sdk-components/D3Painter';
import { dispersionDataGeneratorType, makeDispersionDataGenerator } from './sdk-components/DispersionDataGenerator';
import { d3HandlerType, makeD3Handler } from './sdk-components/D3Handler';
import { gaugeDataType, makeGaugeData } from './sdk-components/Site-Control/GaugeData';



export const initComponents = async (sdk: any) => {
    await Promise.all([
        sdk.Scene.register(orientedBoxType, makeOrientedBox),
        sdk.Scene.register(slotType, makeSlot),
        sdk.Scene.register(securityCameraType, makeSecurityCamera),
        sdk.Scene.register(loadingIndicatorType, makeLoadingIndicator),
        sdk.Scene.register(planeRendererType, makePlaneRenderer),
        sdk.Scene.register(spriteRendererType, makeSpriteRenderer),
        sdk.Scene.register(canvasRendererType, makeCanvasRenderer),
        sdk.Scene.register(sceneRendererType, makeSceneRenderer),
        sdk.Scene.register(videoRendererType, makeVideoRenderer),
        sdk.Scene.register(videoStreamCaptureType, makeVideoStreamCapture),
        sdk.Scene.register(nestThermostatType, makeNestThermostat),
        sdk.Scene.register(roomShadowType, makeRoomShadow),
        sdk.Scene.register(clockPainterType, makeClockPainter),
        sdk.Scene.register(scenePainterType, makeScenePainter),
        sdk.Scene.register(tunerType, makeTuner),
        sdk.Scene.register(hlsLoaderType, makeHlsLoader),
        sdk.Scene.register(mjpegPlayerType, makeMjpegPlayer),
        sdk.Scene.register(toggleStateType, makeToggleState),
        sdk.Scene.register(canvasBorderType, makeCanvasBorder),
        sdk.Scene.register(canvasTextType, makeCanvasText),
        sdk.Scene.register(canvasImageType, makeCanvasImage),
        sdk.Scene.register(cameraInputType, makeCameraInput),
        sdk.Scene.register(videoSourceLoaderType, makeVideoSourceLoader),
        sdk.Scene.register(gaugesPainterType, makeGaugesPainter),
        sdk.Scene.register(mqttLoaderType, makeMqttLoader),
        sdk.Scene.register(sinewaveDataGeneratorType, makeSinewaveDataGenerator),
        sdk.Scene.register(d3PainterType, makeD3Painter),
        sdk.Scene.register(dispersionDataGeneratorType, makeDispersionDataGenerator),
        sdk.Scene.register(d3HandlerType, makeD3Handler),
        sdk.Scene.register(gaugeDataType, makeGaugeData),

    ]);
}

export const assetVersion = '1.0-2-g6b74572';
export const cdnUrl = `https://static.matterport.com/showcase-sdk/examples/assets-${assetVersion}/assets`;
export const sdkKey = 'gmfu859g52qxhumzkzcwyhdpa';
