import gymTV  from './GymTV';
// import peopeCountingGraph from './Graph';
// import changeDateButton from './ChangeDateButton';


export const scene = {
    version: "1.0",
    payload: {
        objects: [
            gymTV,
            // peopeCountingGraph,
            // changeDateButton
        ]
    }
}