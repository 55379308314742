import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Main } from './components/Main';
import './main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDom.render(
  <Main />,
  document.getElementById("content")
);
