import { SceneLoader } from '../SceneLoader';
export default class SceneFunctions {
    private scene: SceneLoader;
    private sdk: any;
    // private nodesIterator: Ite
    constructor(scene: SceneLoader, sdk: any) {
        this.scene = scene;
        this.sdk = sdk;
    }

    learningCenterStreaming(): void {
        let lastSweepId = '';
        let enteredLearningCenter = false;
        this.sdk;
        this.sdk.Camera.pose.subscribe((pose: any) => {
            // entered first sweep of learning center
            if (!enteredLearningCenter && lastSweepId !== '89e68f32c61345c0a3aee511ba8446bc' && pose.sweep === '89e68f32c61345c0a3aee511ba8446bc') {
                // jump to screen position
                const sweepId = '226e1513ddb44cdc8d59ed153ef2dd04';
                // const rotation = { x: 0, y: 0, z: 0 };
                const transition = this.sdk.Sweep.Transition.FLY;
                const transitionTime = 1000; // in milliseconds

                this.sdk.Sweep.moveTo(sweepId, {
                    // rotation: rotation,
                    transition: transition,
                    transitionTime: transitionTime,
                })
                .then(function(sweepId: any){
                    // Move successful.
                    // console.log('Arrived at sweep ' + sweepId);
                })
                .catch(function(error: any){
                    // Error with moveTo command
                });
            }

            if (!enteredLearningCenter && pose.sweep === '226e1513ddb44cdc8d59ed153ef2dd04') {
                enteredLearningCenter = true;
                this.sdk.Camera.setRotation({ x: 5, y: 100, z: 0 }, { speed: 60 });
            }
        });
    }

    learningCenterTV(): void {
        let enteredLobby = false;
        const mpsdk = this.sdk;
        const myself = this;
        this.sdk.Camera.pose.subscribe((pose: any) => {
            if (!enteredLobby && pose.sweep === '22c3d96bb3a8424c9dbb244f4a906fd9') {
                enteredLobby = true;
                mpsdk.Camera.setRotation({ x: 5, y: 100 }, { speed: 60 });
                myself.playPauseVideo('learningCenterTV', 'Play');
            }
        });
    }

    pauseVideoWhenLeavingRoom(): void {
        const LobbyRoom = "2";
        const CommonAreaRoom = "4";
        const LearningCenterRoom = "3";
        const myself = this;
        this.sdk.Room.current.subscribe(function (currentRooms: any) {
            // if LobbyRoom is not available in current | if rooms length is greater than 1 and first room is LobbyRoom
            // pause LobbyRoom video
            const InLobbyRoom = (currentRooms.rooms.findIndex((room: any) => room.id === LobbyRoom ) > -1);
            const InCommonAreaRoom = (currentRooms.rooms.findIndex((room: any) => room.id === CommonAreaRoom ) > -1);
            const InLearningCenterRoom = (currentRooms.rooms.findIndex((room: any) => room.id === LearningCenterRoom ) > -1);

            if (!InLobbyRoom || currentRooms.rooms.length > 1 && currentRooms.rooms[0].id === LobbyRoom) {
                myself.playPauseVideo('lobbyTV', 'Pause');
            }
            if (!InCommonAreaRoom || currentRooms.rooms.length > 1 && currentRooms.rooms[0].id === CommonAreaRoom) {
                myself.playPauseVideo('learningCenterTV', 'Pause');
            }
            if (!InLearningCenterRoom || currentRooms.rooms.length > 1 && currentRooms.rooms[0].id === LearningCenterRoom) {
                myself.playPauseVideo('learningCenterStreaming', 'Pause');
            }
        });
    }

    private playPauseVideo(video: string, status: 'Play' | 'Pause'): void {
        const nodesIterator = this.scene.nodeIterator()
        for (const node of nodesIterator) {
            if (node.name === video) {
                const componentIterator = node.componentIterator();
                for (const component of componentIterator) {
                    if (component.componentType === 'mp.toggleState') {
                        component.outputs.state = status === 'Play' ? true : false;
                        component.outputs.negated = status === 'Play' ? false : true;
                    }
                    if (component.componentType === 'mp.hlsLoader') {
                        component.inputs.enabled = status === 'Play' ? true : false;
                    }
                }
            }
        }
    }
}
