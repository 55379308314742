import {  Video } from '@mp/scene-components';

const gymTV = new Video('gymTV', false);
// gymTV.setPosition({
//     x: 4.17, 
//     y: 1.47, 
//     z: -3.28
// })

gymTV.setPosition({
    x: 10.5, 
    y: 1.3, 
    z: -1
})

gymTV.setRotation({
    x: 1,
    y: 308,
    z: 0
});

gymTV.setScale({
    x: 2,
    y: 2,
    z: 2
})
let videoPath = '/assets/videos/singapore-gym/transparent_video.webm';
const gymVideoURl = window.location.origin + videoPath;
gymTV.setUrls([gymVideoURl])
gymTV.setSprite();

export default gymTV.Video;