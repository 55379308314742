import lobbyTV  from './LobbyScreens';
import agenda  from './AgendaImage';
// import meetingTV  from './MeetingTV';

export const SceneObjects = {
    version: "1.0",
    payload: {
        objects: [
            lobbyTV,
            agenda
        ]
    }
}