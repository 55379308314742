import React, { Component } from 'react';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    // ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';
    
import { SplinePathFactory } from "../SplinePath";

const GRAPH_EDGE_WEIGHT_SCALE = 1.035;

interface Props {

}

interface State {
  linkToPath: string;
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}


export class PathTesting extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    // private sceneId: string = 'wpA9iaXg3Wb';
    private sdk: any = null;
    private modelId: string = "";
    private askForModel: boolean = true;
    private endSweepId: string = "";
    // private linkToPath: string = "";
    private currentSweepId: string;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
          linkToPath: "",
        };

        // this.src = `./bundle/showcase.html?${this.buildShowcaseSrc("")}`;
    }

    buildShowcaseSrc() {
       // Forward url params.
       const params = objectFromQuery();
       params.m = params.m || this.modelId;
       params.play = params.play || '1'; // Play enabled
       params.title = params.title || '1'; // Show title info [Frame.tsx]
       params.help = params.help || '1'; // Present default help [Frame.tsx]
       params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
       params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
       params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
       params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
       params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
       params.dh = params.dh || '1'; // Show Dollhouse View (default)
       params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
       // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
       // params.ss = params.ss || '2'; // -> Set position scan

       // ensure applicationKey is inserted into the bundle query string
       params.applicationKey = params.applicationKey || sdkKey;
       this.applicationKey = params.applicationKey;

       const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
       return queryString;
    }

    async componentDidMount() {
      document.title = "Siemens Munich Campus - Building 48 Floor 2";
      const params = objectFromQuery();
      
      if (params.m) {
        if (params.end) {
          this.endSweepId = params.end;
        }
        this.askForModel = false;
        this.src = `./bundle/showcase.html?${this.buildShowcaseSrc()}`;
        const myself = this;
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          myself.init();
        }, 1000);
        
      }
    }

    async init() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        console.log('showcase', showcase);
        
        this.sdk = await GetSDK(showcase, this.applicationKey);
        console.log('getSdk init', this.sdk);
        await initComponents(this.sdk);

        const mpSdk = this.sdk;
        console.log('mpsdk init', mpSdk);
        // Register your custom component with the sdks
        mpSdk.Scene.register("splinePath", SplinePathFactory);

        await this.sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);

        
        let navigating = false;
        let currentPath: any;

      
        if (this.endSweepId) {
        
          //setup input component to prevent user navigation until sweep path plays through
          const [sceneObject] = await mpSdk.Scene.createObjects(1);

          //setup input component to prevent user navigation until sweep path plays through
          const inputNode = sceneObject.addNode();
          // const inputComponent = inputNode.addComponent("mp.input", {
          //     userNavigationEnabled: true,
          // });
          inputNode.start();

          const splineNode = sceneObject.addNode();
          const splineComponent = splineNode.addComponent("splinePath", {
              path: null,
          });
          splineNode.start();

          const sweepGraph = await mpSdk.Sweep.createGraph();
          let endSweep = sweepGraph.vertex(
              //"4c1ed9e451454cdfad306895839c3d72",
              // "9b74da769ff048d4b5b39cd992c9f74b",
              this.endSweepId,
          ) as any;

          let currentSweep: any;
          let mapped = false;
          mpSdk.Sweep.current.subscribe(function (sweep: any) {
              // Change to the current sweep has occurred.
              currentSweep = sweep;
              console.log('#Current sweep is:', currentSweep.id)
              mapped = true;
              mapPath();
          });

          // async function showHidePath() {
          //     console.log((window as any).destinationPath)
          //     if (mapped) {
          //       mapped = false;
          //       splineComponent.inputs.path = null;
          //       // splineComponent.inputs.enabled = false;
          //       splineComponent.outputs.objectRoot = null;
          //     } else {
          //       mapped = true;
          //       mapPath();
          //     }
          // }
          
          // async function goToPath(pathId: string) {
          //     endSweep = sweepGraph.vertex(
          //       pathId,
          //     ) as any;
          //     mapped = true;
          //     mapPath();
          // }
          
          async function mapPath() {
              if (currentSweep.id !== "" && mapped && !navigating) {
                let startSweep = sweepGraph.vertex(
                  currentSweep.id,
                ) as any;
                const aStarRunner = mpSdk.Graph.createAStarRunner(
                  sweepGraph,
                  startSweep,
                  endSweep,
                );
                for (const { src, dst, weight } of sweepGraph.edges) {
                  sweepGraph.setEdge({
                    src,
                    dst,
                    weight: weight ** GRAPH_EDGE_WEIGHT_SCALE,
                  });
                }
          
                currentPath = aStarRunner.exec().path;
                splineComponent.inputs.path = currentPath;
                splineComponent.inputs.enabled = true;
                console.log('#Mapped path component started', splineComponent)
              }
          }
          
          // async function navigationStartStop() {
          //     console.log("start navigation clicked");
          //     inputComponent.inputs.userNavigationEnabled = false;
          //     navigating = true;
          //     const tempPath = currentPath;
          //     for (const i in tempPath) {
          //       await mpSdk.Sweep.moveTo(tempPath[i].id, {
          //         // rotation: tempPath[i].data.rotation,
          //         transition: mpSdk.Sweep.Transition.FLY,
          //         transitionTime: 1000,
          //       });
          //     }
          
          //     inputComponent.inputs.userNavigationEnabled = true;
          //     navigating = false;
          // }
        } else {
          const myself = this;
          mpSdk.Sweep.current.subscribe(function (sweep: any) {
            // Change to the current sweep has occurred.
            myself.currentSweepId = sweep.id;
          });
        }

    }

    setModelId = () => {
      console.log(this.modelId);
      
      if (this.modelId)
        window.location.href = '/path?m=' + this.modelId;
    }

    handleChange = (event: any) => {
      this.modelId = event.target.value;
    }

    createLinkToPath = () => {
      // this.linkToPath = window.location.href + '&end=' + this.currentSweepId;
      // this.setState({linkToPath: window.location.href + '&end=' + this.currentSweepId})
      // this.linkPath = <div style={{background: 'white', position: 'absolute', right: 30, top: 70, display: 'flex', alignItems: 'center'}}>
      //   <p style={{margin: 0}}>Link: <span>{this.linkToPath}</span></p> <button>Copy</button>
      // </div>
      const link = window.location.href + '&end=' + this.currentSweepId;
      navigator.clipboard.writeText(link);
      alert("Link copied to Clipboard: " + link);
      // console.log('path set', this.state.linkToPath, this.linkPath);
    }

    copyToClipboard = () => {
       // Copy the text inside the text field
      navigator.clipboard.writeText(this.state.linkToPath);
      console.log('copied to clipboard')
    }

    render() {
      function InitialApp(props: any) {
        const {askForModel, handleChange, setModelId, src, createLinkToPath, endSweepId} = props;
        // const askForModel = props.askForModel;
        // const handleChange = props.handleChange;
        // const setModelId = props.setModelId;
        console.log('asking for model', askForModel)
        if (askForModel) {
          return <div style={{'position': 'absolute', 'top':'50%', 'left':'50%', 'transform': 'translate(-50%, -50%)' }}>
            <input type={'text'} placeholder="Input ModelId" onChange={handleChange}/>
            <button style={{padding: '3px 5px'}} onClick={() => setModelId()}>Go</button>
          </div>;
        }
        return <div style={{'height': '100%', 'width': '100%'}}>
              <iframe id='showcase' className='frame' src={src} ></iframe>
              {endSweepId == "" && <button className="effect" onClick={() => createLinkToPath()} style={{position: 'absolute', right: 30, top: 20, height: 40}}>
                Set Path Destination
              </button>}
          </div>;
      }
        // let linkPath;
        // if (this.linkToPath !== "") {
        //   linkPath = <div style={{background: 'white', position: 'absolute', right: 30, top: 70, display: 'flex', alignItems: 'center'}}>
        //   <p style={{margin: 0}}>Link: <span>{this.linkToPath}</span></p> <button>Copy</button>
        // </div>
        // }
        return (
          // <div>

            <div className='frame'>
                {/* <iframe id='showcase' className='frame' ></iframe>
                <div style={{'position': 'absolute', 'top':0, 'right': 0}}>
                  <input type={'text'} onChange={this.handleChange}/>
                  <button onClick={() => this.setModelId()}>Ok</button>
                </div> */}
              <InitialApp endSweepId={this.endSweepId} askForModel={this.askForModel} handleChange={this.handleChange} setModelId={this.setModelId} src={this.src} createLinkToPath={this.createLinkToPath}/>
              
              {false && this.endSweepId == "" && <div style={{background: 'white', position: 'absolute', right: 30, top: 70, display: 'flex', alignItems: 'center'}}>
                <p style={{margin: 0}}>Link: <span>{this.state.linkToPath}</span></p> <button onClick={this.copyToClipboard}>Copy</button>
              </div>}

              {/* {this.endSweepId !== "" && <button className="effect" onClick={() => this.()} style={{position: 'absolute', right: 30, top: 20, height: 40}}>
                Auto Navigate
              </button>} */}
            </div> 
             
          // </div>
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};