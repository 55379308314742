import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiVolumeFull } from 'react-icons/bi';
import { Frame, GetSDK, initComponents, SceneComponent, ISceneNode, OrientedBox,  sdkKey } from '@mp/common';
// import { AppState } from '../AppState';
import { SceneLoader, SCENE_ID } from '../SceneLoader';
import SceneFunctions from '../helper/SceneFunctions';

interface Props {
  // appState: AppState;
}

interface State {
  slotNode: SlotNode|null;
  showModal: Boolean;
}

type SlotNode = {
  node: ISceneNode;
  slotComponent: SceneComponent;
  modelComponent: SceneComponent;
  boxComponent: OrientedBox;
}

export class Main extends Component<Props, State> {
  private sdk: any = null;
  private scene: SceneLoader = null;
  private src: string;
  private applicationKey: string;
  private sceneId: string = SCENE_ID;
  private movingToFloor: boolean = false;
  
  constructor(props: Props, state: State) {
    super(props);
    
    this.state = {
      slotNode: null,
      showModal: false,
    };

    // Forward url params.
    const params = objectFromQuery();
    params.m = params.m || this.sceneId;
    params.play = params.play || '1'; // Play enabled
    params.title = params.title || '1'; // Show title info [Frame.tsx]
    params.help = params.help || '1'; // Present default help [Frame.tsx]
    params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
    params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
    params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
    params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
    params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
    params.dh = params.dh || '1'; // Show Dollhouse View (default)
    params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
    params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
    params.ss = params.ss || '2'; // -> Set position scan

    // ensure applicationKey is inserted into the bundle query string
    params.applicationKey = params.applicationKey || sdkKey;
    this.applicationKey = params.applicationKey;

    const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    this.src = `./bundle/showcase.html?${queryString}`;
  }

  async componentDidMount() {
    document.title = "SITRAIN - Digital Industry Academy";
    this.sdk = await GetSDK('sdk-iframe', this.applicationKey);
    await initComponents(this.sdk);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      renderer.physicallyCorrectLights = true;
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.PCFSoftShadowMap;

    });
    this.scene = new SceneLoader(this.sdk);

    await this.scene.load(this.sceneId);

    console.log('Scene',this.scene);
    console.log('SDK', this.sdk);

    const Functions = new SceneFunctions(this.scene, this.sdk);
    Functions.learningCenterTV();
    Functions.learningCenterStreaming();

    Functions.pauseVideoWhenLeavingRoom();

    const mpsdk = this.sdk;
    const myself = this;
    mpsdk.Floor.current.subscribe(function (currentFloor: any) {
      // Change to the current floor has occurred.
      if (currentFloor.sequence === -1) {
        // Currently viewing all floors
        mpsdk.Floor.moveTo(0).then(() => {
          console.log('moved to floor 0')
        }).catch((err: any) => {
          console.log('failed to move floor', err)
        })
      } else if (currentFloor.sequence === undefined) {
        if (currentFloor.id === undefined) {
          //  console.log('Current viewing an unplaced unaligned sweep');
        } else {
          // console.log('Currently transitioning between floors');
        }
      } else {
        // if in any floor other than 0 and not moving to another floor
        if (parseInt(currentFloor.id) !== 0 && !myself.movingToFloor) {
          myself.handleShowModal();
        }
        // console.log('Currently on floor', currentFloor.id);
        // console.log('Current floor\'s sequence index', currentFloor.sequence);
        // console.log('Current floor\'s name', currentFloor.name)
      }
    });

    mpsdk.Sweep.data.subscribe({
      onCollectionUpdated: function (collection: any) {
        const sweepIDs: string[] = [];
        Object.entries(collection).forEach(([key, value]) => {
          const item = (value as any)
          if (item.floorInfo.id !== "0") {
            sweepIDs.push(key);
          }
        });
        mpsdk.Sweep.disable(...sweepIDs)
      }
    });

  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.movingToFloor = true;
    this.sdk.Floor.moveTo(0).then(() => {
      this.movingToFloor = false;
    });
  }

  handleShowModal() {
    this.setState({ showModal: true });
  }

  render() {
    const { showModal } = this.state;

    const handleCloseModal = () => this.handleCloseModal();

    return (
      <div className='main'>
        <Button id="unmuteButton" variant="dark"><BiVolumeFull /> Enable Video Sound</Button>
        <Frame src={this.src}></Frame>
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Access Restricted!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sorry you don't have access to this floor.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): {[key: string]: string} => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
