import { SceneComponent, IPainter2d, Size, ComponentOutput, ComponentInteractionType } from '@mp/common';
import { RadialGauge } from 'canvas-gauges';

type Highlight = { 
    from: number,
    to: number,
    color: string
}
type Inputs = {
    image: string | null;
    image1: string | null;
    image2: string | null;
    imageScale: number | null;
    text: string | null;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    textBorderColor: string;
    updateInterval: number;
    unit: string;
    alternateUnit: string | null;
    minValue: number;
    maxValue: number;
    highlights: Highlight[];
    rangeValues: number[];
    showGauge: boolean;
    title: string;
    name: string;
}

type Outputs = {
    painter: IPainter2d | null;
} & ComponentOutput;


class GaugesPainterComponent extends SceneComponent implements IPainter2d {
    private alternateUnit: boolean = false;

    inputs: Inputs = {
        image: null,
        image1: null,
        image2: null,
        imageScale: 0.75,
        text: 'default',
        backgroundColor: "rgba(0,128,0,1.0)",
        borderColor: "rgba(0,0,0,1.0)",
        textColor: "rgba(0,0,0,1.0)",
        textBorderColor: "rgba(255,255,255,1.0)",
        updateInterval: 100,
        unit: 'ºC',
        minValue: 0, 
        maxValue: 100,
        highlights: [],
        rangeValues: [],
        showGauge: true,
        alternateUnit: null,
        title: '',
        name: '',
    }

    outputs = {
        painter: null,
    } as Outputs;

    events: {
        [ComponentInteractionType.CLICK]: false,
    }

    onInit() {
        this.outputs.painter = this;
    }

    onInputsUpdated() {
        this.outputs.painter = this;
        this.notify('paint.ready');
    }

    onEvent(eventType: string) {
        if (eventType === ComponentInteractionType.CLICK) {

            if (this.inputs.alternateUnit) {
                this.alternateUnit = !this.alternateUnit;
            }
        }

        this.outputs.painter = this;
        this.notify('paint.ready');
    }

    parseUnitValue(value: number): number {
        if (!this.alternateUnit) {
            return value;
        }

        let parsed = value;

        if (this.inputs.unit === 'ºF' && this.inputs.alternateUnit === 'ºC') {
            parsed = (value - 32) * 5/9;
        }
        if (this.inputs.unit === 'ºC' && this.inputs.alternateUnit === 'ºF') {
            parsed = value * 9/5 + 32;
        }

        return Number(parsed.toFixed(1));
    }

    drawGauge(canvas: HTMLCanvasElement) {
        const gaugeOptions = {
            renderTo: canvas, // identifier of HTML canvas element or element itself
            width: 300,
            height: 300,
            units: this.alternateUnit ? this.inputs.alternateUnit : this.inputs.unit,
            value: this.parseUnitValue(Number(this.inputs.text)),
            valueBox: false,
            title: this.inputs.title,
            minValue: this.inputs.minValue,
            maxValue: this.inputs.maxValue,
            majorTicks: [...this.inputs.rangeValues],
            // minorTicks: 2,
            strokeTicks: true,
            highlights: this.inputs.highlights,
            ticksAngle: 225,
            startAngle: 67.5,
            colorMajorTicks: "#ddd",
            colorMinorTicks: "#ddd",
            colorTitle: "#fff",
            colorUnits: "#fff",
            colorNumbers: "#fff",
            colorPlate: "#222",
            borderShadowWidth: 2,
            borders: true,
            needleType: "arrow",
            needleWidth: 2,
            needleCircleSize: 7,
            needleCircleOuter: true,
            needleCircleInner: false,
            // colorBorderOuter: "#333",
            // colorBorderOuterEnd: "#111",
            // colorBorderMiddle: "#222",
            // colorBorderMiddleEnd: "#111",
            // colorBorderInner: "#111",
            // colorBorderInnerEnd: "#333",
            colorNeedleShadowDown: "#333",
            colorNeedleCircleOuter: "#333",
            colorNeedleCircleOuterEnd: "#111",
            colorNeedleCircleInner: "#111",
            colorNeedleCircleInnerEnd: "#222",
        }
        var gauge = new RadialGauge(gaugeOptions);
        gauge.draw();

        if (!(window as any).gauges) {
            (window as any).gauges = {}
        }

        (window as any).gauges[this.inputs.name] = { canvas: gaugeOptions, value: this.inputs.text, unit: this.inputs.unit, highlight: this.getHighlightColor() };

    }

    getHighlightColor() {
        if (!this.inputs.text) return '';
        const value = Number(this.inputs.text);
        let color = '';

        for (let i = 0; i < this.inputs.highlights.length; i++) {
            const item = this.inputs.highlights[i];
            if (item.from <= value && item.to >= value) {
                color = item.color;
                break;
            }
        }
        return color;
    }

    paint(context2d: CanvasRenderingContext2D, size: Size, canvas: HTMLCanvasElement) {
        
        if (this.inputs.showGauge) {
            this.drawGauge(canvas);

            context2d.lineWidth = 2;
            context2d.font = "70px Arial";
            context2d.textAlign = "center";
            context2d.textBaseline = "middle";
            context2d.fillStyle = this.inputs.textColor;
            context2d.strokeStyle = this.inputs.textBorderColor;
            if (this.inputs.text) {
                context2d.fillText(`${this.parseUnitValue(Number(this.inputs.text))}`, 0, 100);
                context2d.strokeText(`${this.parseUnitValue(Number(this.inputs.text))}`, 0,100);
            }
            console.log('#Canvas Size', size.w, size.h)
        } else {
            const width = 0.5 * size.w;
            const height = 0.5 * size.h;
            const factor = 10;
            const r = 0.5 * (Math.min(size.w, size.h) - factor);

            context2d.fillStyle = this.inputs.backgroundColor;
            context2d.beginPath();
            context2d.arc(width/2,height/2, (r-10)/2, 0, 2 * Math.PI);
            context2d.fill();

            context2d.lineWidth = 10;
            context2d.strokeStyle = this.inputs.borderColor;
            context2d.beginPath();
            context2d.arc(width / 2, height / 2, (r - 5) / 2, 0, 2 * Math.PI);
            context2d.stroke();

            context2d.lineWidth = 5;
            context2d.beginPath();
            context2d.strokeStyle = 'white';
            context2d.arc(width / 2, height / 2, (r - 10) / 2, 0, 2 * Math.PI);
            context2d.lineCap = 'butt';
            context2d.stroke();

            context2d.lineWidth = 2;
            context2d.font = "120px Arial";
            context2d.textAlign = "center";
            context2d.textBaseline = "middle";
            context2d.fillStyle = this.inputs.textColor;
            context2d.strokeStyle = this.inputs.textBorderColor;
            if (this.inputs.text) {
                context2d.fillText(`${this.parseUnitValue(Number(this.inputs.text))} ${this.alternateUnit ? this.inputs.alternateUnit : this.inputs.unit}`, 256, 300);
                context2d.strokeText(`${this.parseUnitValue(Number(this.inputs.text))} ${this.alternateUnit ? this.inputs.alternateUnit : this.inputs.unit}`, 256,300);
            }

            if (!(window as any).gauges) {
                (window as any).gauges = {}
            }

            (window as any).gauges[this.inputs.name] = { value: this.inputs.text, unit: this.inputs.unit };
        }


        // Create the event.
        const event = new Event('gaugeUpdate');
        // target can be any Element or other EventTarget.
        window.dispatchEvent(event);
    }
}

export interface IGaugesPainter extends SceneComponent {
    outputs: Outputs;
}

export const gaugesPainterType = 'mp.gaugesPainter';
export function makeGaugesPainter() {
    return new GaugesPainterComponent();
}
