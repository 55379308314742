import React, { Component } from 'react';
// import { ISceneNode } from '@mp/common';

import { AppState, items, slots } from '../AppState';

import { Main as SingaporeGym } from 'singapore-center-gym';
import { Main as UsTestlab } from 'us-testlab';
import { Main as Ga400 } from 'ga-400';
import { Main as Showcase } from 'showcase';
import { Tutorial } from './Tutorial';
// import { Main as RcApp, ClientContext, context } from 'rc-app';

// import { ComfyTest } from './ComfyTest';
import { PathTesting } from './PathTesting';
import { ExperienceCenterMX } from './ExperienceCenterMX';
import { PlazaGalerias } from './PlazaGalerias';
import { PrincetonGrid } from './PrincetonGrid';
import { CyberSecurity } from './CyberSecurity';
import { IExperienceCenter } from './IExperienceCenter';
import { IExperienceCenterPorto } from './IExperienceCenter/Porto';
import { TimelineConferenceRoom } from './TimelineConferenceRoom';
import { GoldenTicket } from './GoldenTicket';


// List of apps and route paths
const apps = {
  singaporeGym: {
    name:'Singapore Gym',
    path: 'singapore-gym',
    description: '',
    image: '/assets/images/singapore-gym/Thumb.png',
  },
  usTestlab: {
    name:'Us R&D Test Lab',
    path: 'rd-testlab-us-sample',
    description: '',
    image: '/assets/images/US-TestLab-Thumb.png',
  },
  ga400: {
    name:'Sitrain - GA-400',
    path: 'ga-400',
    description: '',
    image: '/assets/images/ga-400/future-of-learning.jpg',
  },
  showcase: {
    name:'Showcase',
    path: 'showcase',
    description: '',
    image: '/assets/images/Showcase-Thumb.png',
  },
  tutorial: {
    name:'SDK Tutorial',
    path: 'showcase-sdk-tutorial',
    description: '',
    image: '/assets/images/Showcase-Tutorial-Thumb.png',
  },
  // comfy: {
  //   name:'Comfy App',
  //   path: 'comfy',
  //   description: '',
  //   image: '/assets/images/MatterportLogo.png',
  // },
  experienceCenterMx: {
    name:'Experience Center Mexico',
    path: 'experience-center-mx',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  plazaGalerias: {
    name:'Plaza Galerias Guadalajara',
    path: 'plaza-galerias',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  princetonGrid: {
    name:'Princeton Island Grid',
    path: 'princeton-grid',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  cybersecurity: {
    name:'Cayber Security Room',
    path: 'cybersecurity',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  rcApp: {
    name:'Remote Control Test',
    path: 'rc-app',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  iExperienceCenterAlfra: {
    name: 'iExperience Center Alfragide',
    path: 'experience-center-alfragide',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  iExperienceCenterPorto: {
    name: 'iExperience Center Porto',
    path: 'experience-center-porto',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  TimelineConferenceRoom: {
    name: 'Timeline Conference Room',
    path: 'timeline-conference-room',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  PathTesting: {
    name: 'Testing paths',
    path: 'path',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  },
  GoldenTicket: {
    name: 'Digital World Creation Day Golden Ticket',
    path: 'dwc-golden-ticket',
    description: '',
    image: '/assets/images/MatterportLogo.png',
  }
}


// Only being used for showcase
const appState = new AppState();
appState.items = items;
appState.slots = slots;


import { Login } from './Login';
import { Dashboard } from './Dashboard';


// import {Route, Switch} from 'react-router';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import axios from 'axios';


interface Props {

}

interface State {
  host: string,
  apiHost: string,
  isAuthenticated: boolean,
  isLoading: boolean,
  appsPath: typeof apps
}

export class Main extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    
    this.state = {
      host: '',
      apiHost: window.location.host.includes('localhost') ? 'http://localhost:4000' : 'https://api.xr.siemens.com',
      isAuthenticated: false,
      isLoading: true,
      appsPath: {
        ...apps
      }
    };
  }

  async componentDidMount() {
    this.checkAuthenticated();
  }

  async checkAuthenticated(): Promise<void> {
    const token = localStorage.getItem('apiToken');
    if (token) {
      try {
        const apiUrl = this.state.apiHost;

        await axios.get(apiUrl + '/auth', {
          params: {
            // action: 'auth',
            token,
          },
          headers: {
            'Accept': 'application/json',
            'x-api-key': 'iAhdf1821903SJiasdnkasHA73201ANdkahshdalqihA1981n'
          }
        })
        this.setState({ isAuthenticated: true, isLoading: false });
        return;
      } catch (error) {
        console.log('#Failed to get data', error);
        localStorage.removeItem('apiToken');
      }
    }
    
    this.setState({ isAuthenticated: false, isLoading: false });
  }

  redirectHome() {
    window.location.replace("https://xr.siemens.com");
  }

  render() {
    return (
      <div className='main-app'>
        <Router >

          <Switch>
            <Route exact path={`${this.state.host}/${this.state.appsPath.singaporeGym.path}`}>
              <SingaporeGym />
            </Route>

            {this.state.isAuthenticated && <Route exact path={`${this.state.host}/${this.state.appsPath.usTestlab.path}`}>
              <UsTestlab />
            </Route>}

            <Route exact path={`${this.state.host}/${this.state.appsPath.ga400.path}`}>
              <Ga400 />
            </Route>
            
            <Route exact path={`${this.state.host}/${this.state.appsPath.showcase.path}`}>
              <Showcase appState={appState}/>
            </Route>

            <Route exact path={`${this.state.host}/${this.state.appsPath.tutorial.path}`}>
              <Tutorial />
            </Route>

            {/* {this.state.isAuthenticated && <Route path={`${this.state.host}/${this.state.appsPath.comfy.path}`}>
              <ComfyTest />
            </Route>} */}

            <Route exact path={`${this.state.host}/${this.state.appsPath.experienceCenterMx.path}`}>
              <ExperienceCenterMX />
            </Route>
            
            <Route path={`${this.state.host}/${this.state.appsPath.princetonGrid.path}`}>
              <PrincetonGrid />
            </Route>

            <Route path={`${this.state.host}/${this.state.appsPath.cybersecurity.path}`}>
              <CyberSecurity />
            </Route>            

            <Route exact path={`${this.state.host}/${this.state.appsPath.experienceCenterMx.path}/en`}>
              <ExperienceCenterMX />
            </Route>

            <Route exact path={`${this.state.host}/${this.state.appsPath.plazaGalerias.path}`}>
              <PlazaGalerias />
            </Route>

            <Route exact path={`${this.state.host}/${this.state.appsPath.iExperienceCenterAlfra.path}`}>
              <IExperienceCenter />
            </Route>
            <Route exact path={`${this.state.host}/${this.state.appsPath.iExperienceCenterPorto.path}`}>
              <IExperienceCenterPorto />
            </Route>
            
            <Route exact path={`${this.state.host}/${this.state.appsPath.TimelineConferenceRoom.path}`}>
              <TimelineConferenceRoom />
            </Route>

            <Route exact path={`${this.state.host}/${this.state.appsPath.PathTesting.path}`}>
              <PathTesting />
            </Route>

            <Route exact path={`${this.state.host}/${this.state.appsPath.GoldenTicket.path}`}>
              <GoldenTicket />
            </Route>

            {/* {this.state.isAuthenticated && <Route exact path={`${this.state.host}/${this.state.appsPath.rcApp.path}`}>
              <ClientContext.Provider value={context}>
                <RcApp />
              </ClientContext.Provider>
            </Route>} */}

            {/* <Route path={`${this.state.host}/`}> */}
              {(!this.state.isAuthenticated && !this.state.isLoading) &&
                // <Login />
                <Route path="/" component={Login} />
              }
              {this.state.isAuthenticated && 
                // <Dashboard appsPath={this.state.appsPath}/>
                <Route path="/" render={
                  props => <Dashboard {...props} appsPath={this.state.appsPath}/> 
                } />
              }
            {/* </Route> */}


          </Switch>

        </Router>

      </div>
    );
  }

}
