// default Video component values

type CoordinatesType = {
    x: number, y: number, z: number
}
export class Webcam {
    private VideoComponent = {
        "name": "webcam",
        "position": {
          "x": 1.2,
          "y": 2,
          "z": -4.2
        },
        "rotation": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "scale": {
          "x": 0.8,
          "y": 0.8,
          "z": 0.01
        },
        "components": [
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "transparent": false,
              "colorBackground": "#050505",
              "localScale": {
                "x": 1,
                "y": 1,
                "z": 1
              },
              "localPosition": {
                "x": 0,
                "y": 0,
                "z": 0
              }
            }
          },
          {
            "type": "mp.videoRenderer",
            "inputs": {
                "flipX": false
              }
          },
          {
            "type": "mp.videoStreamCapture",
            "inputs": {
              "deviceId": 0
            }
          },
          {
            "type": "mp.toggleState",
            "inputs": {
              "initialState": false
            }
          },
    
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "transparent": true,
              "opacity": 0.8
            }
          },
          {
            "type": "mp.canvasRenderer",
            "inputs": {
              "textureRes": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.canvasBorder",
            "inputs": {
              "position": {
                "x": 0,
                "y": 0
              },
              "size": {
                "h": 1024,
                "w": 1024
              },
              "radius": 15
            }
          },
    
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "localScale": {
                "x": 1,
                "y": 1,
                "z": 1
              },
              "localPosition": {
                "x": 0,
                "y": 0,
                "z": 0
              },
              "transparent": true,
              "opacity": 0.8,
              "polygonOffset": true,
              "polygonOffsetFactor": -2
            }
          },
          {
            "type": "mp.canvasRenderer",
            "inputs": {
              "textureRes": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.canvasText",
            "inputs": {
              "size": {
                "h": 1024,
                "w": 1024
              },
              "text": "Click to enable webcam.",
              "font": "normal bold 80px sans-serif",
              "color": "white",
              "colorBorder": "black",
              "position": {
                "x": 512,
                "y": 400,
                "z": 0
              },
              "textWidth": 360
            }
          }
        ],
        "bindings": [
          [ 0, "texture", 1, "texture" ],
          [ 1, "src", 2, "stream" ],
          [ 2, "enabled", 3, "state" ],
          [ 0, "enabledBackground", 3, "state" ],
          [ 0, "aspect", 2, "aspect" ],
    
          [ 5, "painter", 6, "painter" ],
          [ 4, "texture", 5, "texture" ],
          [ 4, "aspect", 2, "aspect" ],
    
          [ 8, "painter", 9, "painter" ],
          [ 7, "texture", 8, "texture" ],
          [ 7, "visible", 3, "negated" ]
        ],
        "events": [
          [ 3, "toggle", 0, "INTERACTION.CLICK" ]
        ]
      }

    private video: any;

    constructor(name: string) {
        this.video = { ...this.VideoComponent };
        this.video.name = name;

    }

    get Video(): any {
        return this.video;
    }

    public setPosition(position: CoordinatesType) {
        this.video.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.video.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.video.scale = scale;
    }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [...this.Video.components];
        components.forEach((component: any) => {
            if (component.type === componentType) {
                component.inputs[propertyName] = value;
            }
        });
        this.video.components = [...components];
    }

    public flipX() {
        this.changeComponentProperties('mp.videoRenderer', 'flipX', true);
    }
}