import React, { Component } from 'react';
import axios from 'axios';

interface Props {

}

interface State {
  host: string,
  password: string,
}

export class Login extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props);
    
    this.state = {
      host: window.location.host.includes('localhost') ? 'http://localhost:4000' : 'https://api.xr.siemens.com',
      password: '',
    };
  }

  componentDidMount() {
  }
  isAuthenticated(): boolean {
    return true;
  }

  async logIn(): Promise<void> {
    const password = this.state.password;
    if (!password) {
      return;
    }

    let response;
    try {
      const apiUrl = this.state.host;

      response = await axios.post(apiUrl + '/login', {
        // action: 'login',
        password,
      }, {
        headers: {
          'Accept': 'application/json',
          'x-api-key': 'iAhdf1821903SJiasdnkasHA73201ANdkahshdalqihA1981n'
        }
      })
    } catch (error) {
      console.log('#Failed to get data', error);
    }
    console.log('#response', response.data.token);
    if (response?.data) {
      localStorage.setItem('apiToken', response.data.token);
    }

    window.location.reload()
  }

  changePassword() {
      console.log
      this.setState({ password: this.state.password });
  }

  render() {
    return (
        <div className="container login-form">
          <h4>Authenticate</h4>
          {/* <div className="form-group">
              <label>Email address</label>
              <input type="email" className="form-control" placeholder="Enter email" />
          </div> */}
          <div className="form-group">
            <label>Password</label>
            <input type="password" 
                value={this.state.password} 
                onChange={e => this.setState({password: e.target.value})}  
                className="form-control" 
                placeholder="Enter password"
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    this.logIn()
                  }
                }} />
          </div>
          <button style={{ marginTop: '10px' }} type="submit" className="btn btn-primary btn-block" onClick={() => this.logIn()}>Submit</button>
        </div>
    );
  }

}
