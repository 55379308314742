import { Gauge } from '@mp/scene-components';

const gaugeOutsideTemperature = new Gauge('gaugeOutsideTemperature', false);
gaugeOutsideTemperature.setPosition({
    x: 28,
    y: 2.2,
    z: -1.15
})

gaugeOutsideTemperature.setRotation({
    x: 0,
    y: 270,
    z: 0
});

gaugeOutsideTemperature.setScale({
    x: 1,
    y: 1,
    z: 1
})

gaugeOutsideTemperature.setTimeInterval(15000);
gaugeOutsideTemperature.setSiteControlAPI('95', '5', 'sensor', 'OSA');
gaugeOutsideTemperature.setUnit('ºF', 'ºC');


export default gaugeOutsideTemperature.Gauge;