import { Text } from '@mp/scene-components';

const WhiteBoard4 = new Text('whiteBoard4', 'Learn as you grow')

WhiteBoard4.setPosition({
    x: 10.360,
    y: -3.220,
    z: 2.100
})

WhiteBoard4.setRotation({
    x: 0,
    y: 133.2,
    z: 0
});

WhiteBoard4.setScale({
    x: 0.8,
    y: 0.2,
    z: 0
})

WhiteBoard4.setTextPosition({
    x: 20,
    y: 0,
    z: 0
})

WhiteBoard4.setTransparent(); 

WhiteBoard4.setTextColor('black');
WhiteBoard4.setTextBorderColor('black ');
WhiteBoard4.setFont('normal 60px myFont');
WhiteBoard4.setNewFontFace('myFont', 'url(assets/fonts/SiemensSans-Bold.otf)')
WhiteBoard4.setTextWidth(512);
WhiteBoard4.setTextResolution(512, 150);
WhiteBoard4.setTextAlign('left');


export default WhiteBoard4.Text;