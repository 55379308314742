import axios from 'axios';

const HOST = window.location.origin.includes('localhost') ? 'http://localhost:4000' : 'https://api.xr.siemens.com'
export default class SiteControlAPI {
    private clientId: string;
    private siteId: string;

    constructor(clientId: string, siteId: string) {
        this.clientId = clientId;
        this.siteId = siteId;
    }

    public async getLiveData(deviceType: string) {
        let response
        try {
            response = await axios.get(`${HOST}/site-control/live`, {
                params: { 
                    clientId: this.clientId,
                    siteId: this.siteId,
                    device: deviceType
                 },
                 headers: {
                    'x-api-key': 'iAhdf1821903SJiasdnkasHA73201ANdkahshdalqihA1981n'
                 }
            });
        } catch (error) {
            console.log('#Failed to get data', error);
            throw new Error(error.message);
        }
        return response;
    }

    async getTimeSeries(dataType: string, deviceKey: string) {
        let response
        try {
            response = await axios.get(`${HOST}/site-control/time-series`, {
                params: { 
                    clientId: this.clientId,
                    siteId: this.siteId,
                    dataType,
                    key: deviceKey
                 },
                 headers: {
                    'x-api-key': 'iAhdf1821903SJiasdnkasHA73201ANdkahshdalqihA1981n'
                 }
            });
        } catch (error) {
            console.log('#Failed to get data', error);
            throw new Error(error.message);
        }
        return response;
    }

    public getHvacData(deviceKey: string) {
        return this.getTimeSeries('hvacdata', deviceKey);
    }
    public getSensorData(deviceKey: string) {
        return this.getTimeSeries('sensordata', deviceKey);
    }
}
