type CoordinatesType = {
    x: number, y: number, z: number
}

type Highlight = { 
    from: number,
    to: number,
    color: string
}

export class Gauge {
    private gaugeComponent = {
        "name": "gauges_temp",
        "position": {
            "x": 10.55,
            "y": 1.525,
            "z": 4.575
        },
        "rotation": {
            "x": 0,
            "y": 180,
            "z": 0
        },
        "scale": {
            "x": 1,
            "y": 1,
            "z": 1
        },
        "components": [
            {
                "type": "mp.planeRenderer",
                "inputs": {
                    "colorBackground": "#ffffff",
                    "transparent": true,
                    "localScale": {
                        "x": 0.75,
                        "y": 0.75,
                        "z": 0.75
                    },
                    "localPosition": {
                        "x": 0,
                        "y": 0,
                        "z": 0
                    },
                    "opacity": 1.0,
                    "events": {
                        "INTERACTION.CLICK": true
                    }
                }
            },
            {
                "type": "mp.canvasRenderer",
                "inputs": {
                    "textureRes": {
                        "w": 1024,
                        "h": 1024
                    }
                }
            },
            {
                "type": "mp.gaugesPainter",
                "inputs": {
                    // "image": null,
                    "image1": "https://raw.githubusercontent.com/ricardoalexandre-dev/3dmodels/main/IconsPNG/gauge.png",
                    "image2": "https://raw.githubusercontent.com/ricardoalexandre-dev/3dmodels/main/IconsPNG/filter.png",
                    "text": "default",
                    "filterColor": "rgba(207,83,0,1.0)",
                    "backgroundColor": "#222",
                    "borderColor": "rgba(0,0,0,0.5)",
                    "textColor": "rgba(255,255,255,1.0)",
                    "textBorderColor": "rgba(0,0,0,1.0)",
                    "updateInterval": 5000, 
                    "unit": 'ºC',
                    // "alternateUnit": 'ºF',
                    "minValue": 0, 
                    "maxValue": 100, 
                    "showGauge": true,
                    "title": '',
                    "name": 'gauge_temp'
                }
            },
            {
                "type": "mp.gaugeData",
                "inputs": {
                    "updateInterval": 5000,
                    "deviceType": '',
                    "deviceKey": '',
                    "clientId": '',
                    "siteId": ''
                }
            },
        ],
        "bindings": [
            [0, "texture", 1, "texture"],
            [1, "painter", 2, "painter"],
            [2, "text", 3, "receivedMessage"],
        ],
        "events": [
            [1, "repaint", 2, "paint.ready"],
            [2, "INTERACTION.CLICK", 0, "INTERACTION.CLICK"]
        ]
    };
    private gauge: any;

    constructor(name: String, showGauge: boolean = true) {
        this.gauge = { ...this.gaugeComponent };
        this.gauge.name = name;

        this.changeComponentProperties('mp.gaugesPainter', 'showGauge', showGauge);
        this.changeComponentProperties('mp.gaugesPainter', 'name', name);
    }

    get Gauge(): any {
        return this.gauge;
    }

    public setSiteControlAPI(clientId: string, siteId: string, deviceType: string, deviceKey: string) {
        this.changeComponentProperties('mp.gaugeData', 'clientId', clientId);
        this.changeComponentProperties('mp.gaugeData', 'siteId', siteId);
        this.changeComponentProperties('mp.gaugeData', 'deviceKey', deviceKey);
        this.changeComponentProperties('mp.gaugeData', 'deviceType', deviceType);
    }

    public setMqttClient(host: string, port: number, username: string, password: string, ssl: boolean, topic: string) {
        this.changeComponentProperties('mp.gaugeData', 'isMqtt', true);
        this.changeComponentProperties('mp.gaugeData', 'mqttHost', host);
        this.changeComponentProperties('mp.gaugeData', 'mqttPort', port);
        this.changeComponentProperties('mp.gaugeData', 'mqttUser', username);
        this.changeComponentProperties('mp.gaugeData', 'mqttPwd', password);
        this.changeComponentProperties('mp.gaugeData', 'mqttSSL', ssl);
        this.changeComponentProperties('mp.gaugeData', 'mqttTopic', topic);
    }

    public setPosition(position: CoordinatesType) {
        this.gauge.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.gauge.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.gauge.scale = scale;
    }

    public setTimeInterval(interval: number) {
        this.changeComponentProperties('mp.gaugeData', 'updateInterval', interval);
    }

    public setTitle(title: string) {
        this.changeComponentProperties('mp.gaugesPainter', 'title', title);
    }

    public setGaugeImage(src: string) {
        this.changeComponentProperties('mp.mjpegPlayer', 'src', src);
    }

    public setUnit(unit: string, alternate?: string) {
        this.changeComponentProperties('mp.gaugesPainter', 'unit', unit);
        if (alternate != null) {
            this.changeComponentProperties('mp.gaugesPainter', 'alternateUnit', alternate);
        }
    }

    public setMinMaxValues(min: number, max: number) {
        this.changeComponentProperties('mp.gaugesPainter', 'minValue', min);
        this.changeComponentProperties('mp.gaugesPainter', 'maxValue', max);
    }

    public setRange(range: number[]) {
        this.changeComponentProperties('mp.gaugesPainter', 'rangeValues', range);
    }

    public setHighlights(highlights: Highlight[]) {
        this.changeComponentProperties('mp.gaugesPainter', 'highlights', highlights);
    }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [...this.gauge.components];
        components.forEach((component: any) => {
            if (component.type === componentType) {
                component.inputs[propertyName] = value;
            }
        });
        this.gauge.components = [...components];
    }

}