// import axios from 'axios';
import { SceneComponent, ComponentOutput } from '../../SceneComponent';

import SiteControlAPI from '../../api/site-control';
import Paho from 'paho-mqtt';

type Inputs = {
    updateInterval: number;
    deviceType: string;
    deviceKey: string;
    clientId: string;
    siteId: string;
    isMqtt: boolean;
    mqttHost: string,
    mqttPort: number,
    mqttUser: string,
    mqttPwd: string,
    mqttSSL: boolean,
    mqttTopic: string,
};

type Outputs = {
    receivedMessage: string
} & ComponentOutput;

class GaugeData extends SceneComponent {
    private currentTime: number = 0;
    private nextUpdate: number = 0;
    private SiteControl: SiteControlAPI;
    private mqttClient: Paho.Client;
    // private counter: number = 0;

    inputs: Inputs = {
        updateInterval: 5000,
        deviceType: '',
        deviceKey: '',
        clientId: '',
        siteId: '',
        isMqtt: false,
        mqttHost: '',
        mqttPort: 8884,
        mqttUser: '',
        mqttPwd: '',
        mqttSSL: true,
        mqttTopic: ''
    }

    outputs = {
        receivedMessage: ''
    } as Outputs;

    onInit() {
        const myself = this;
        if (!this.inputs.isMqtt) {
            this.SiteControl = new SiteControlAPI(this.inputs.clientId, this.inputs.siteId)
        } else {
            this.mqttClient = new Paho.Client(this.inputs.mqttHost, this.inputs.mqttPort, 'clientId-' + parseInt((Math.random() * 10000).toString(), 0));
            console.log('# mqtt init')
            this.mqttClient.onConnectionLost = onConnectionLost;
            this.mqttClient.onMessageArrived = onMessageArrived;

            // connect the client
            this.mqttClient.connect({
                userName: this.inputs.mqttUser,
                password: this.inputs.mqttPwd,
                useSSL: this.inputs.mqttSSL,
                onSuccess: onConnect
            });

            // called when the client connects
            function onConnect() {
                // Once a connection has been made, make a subscription and send a message.
                console.log("#onConnect");
                myself.mqttClient.subscribe(myself.inputs.mqttTopic);
                // client.subscribe("speed");
                // client.subscribe("torque");
                // client.subscribe("current");

                // message = new Paho.MQTT.Message("Hello");
                // message.destinationName = "Word";
                // client.send(message);
            }

            // called when the client loses its connection
            function onConnectionLost(responseObject: any) {
                if (responseObject.errorCode !== 0) {
                    console.log("#onConnectionLost:" + responseObject.errorMessage);
                }
            }

            // called when a message arrives
            function onMessageArrived(message: any) {
                console.log("#onMessageArrived:" + JSON.stringify(message));
                myself.outputs.receivedMessage = message.payloadString;
            }
        }

    }

    onInputsUpdated() {

    }

    async onTick(delta: number) {
        if (!this.inputs.isMqtt) {
            this.currentTime += delta;

            if (this.currentTime > this.nextUpdate) {
                this.nextUpdate += this.inputs.updateInterval;

                let response
                try {
                    response = await this.SiteControl.getLiveData(this.inputs.deviceKey);
                } catch (error) {
                    console.log('#Failed to get data', error);
                }


                if (this.inputs.deviceKey && response.data) {
                    const device = response.data.find((d: any) => d.key === this.inputs.deviceKey);
                    if (this.inputs.deviceType === 'hvac') {
                        this.outputs.receivedMessage = device.dataset['zone-i18n'].value;
                    }
                    if (this.inputs.deviceType === 'sensor') {
                        this.outputs.receivedMessage = device.dataset['value-i18n'].value;
                    }
                    // this.counter+=5;
                }

            }
        }
    }
}

export const gaugeDataType = 'mp.gaugeData';
export function makeGaugeData() {
    return new GaugeData();
}
