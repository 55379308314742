import React, { Component } from 'react';
import { SceneLoader } from '../../SceneLoader';
import { SceneObjects } from './scene-objects';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    // ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';
import IframeLy from '../../helper/IframeLy';
import Paho from 'paho-mqtt';
import ReactGA from "react-ga4";

interface Props {

}

interface State {
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}


export class IExperienceCenter extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    private sceneId: string = '9QmEgtzCU9H';
    private scene: SceneLoader = null;
    private sdk: any = null;
    private mqttClient: Paho.Client;
    private panelOpened: any = null;
    private panelClosed: any = null;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
        };

         // Forward url params.
        const params = objectFromQuery();
        params.m = params.m || this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '1'; // Show title info [Frame.tsx]
        params.help = params.help || '1'; // Present default help [Frame.tsx]
        params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '1'; // Show Dollhouse View (default)
        params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
        // params.ss = params.ss || '2'; // -> Set position scan
        params.newtags = '1';

        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
    }

    async componentDidMount() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;
        //Initialize GA4
        ReactGA.initialize("G-S87K561YSH");
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
        });

        document.title = "iExperience Center Alfragide";
        this.sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(this.sdk);

        await this.sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        this.scene = new SceneLoader(this.sdk, this.sceneId, SceneObjects);
 
        await this.scene.load(this.sceneId);

        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);
        
        // replace Embedly to IframeLy
        const iframe = new IframeLy(this.sdk);
        iframe.replace();

        // add default lights
        const nodeDefaultLights = await this.sdk.Scene.createNode();
        nodeDefaultLights.addComponent('mp.lights');
        nodeDefaultLights.start();

        // OBJ loader
        const model = await this.sdk.Scene.createNode();
        
        const nodeObjModelParams = {
            url: '/assets/models/G_FI01_XX_12606V.obj',
            materialUrl: '/assets/models/G_FI01_XX_12606V.mtl',
            visible: true,
            localScale: { x: .001, y: .001, z: .001 },
            localPosition: { x: 0, y: 0, z: 0 },
            localRotation: { x: 0, y: 0, z: 0 },
            colliderEnabled: true
        };
        model.addComponent('mp.objLoader', nodeObjModelParams);
        model.obj3D.position.set(6.7, 1, -2);
        model.start();
        console.log('%c OBJ - Model loaded! ', 'background: #ffffff; color: #00dd00');

        // const maxAnimationIterations = 50;
        // let animationTarget = maxAnimationIterations;
        // let animationCount = 0;
         // Activate tick function
        const tick = function () {
            requestAnimationFrame(tick);
            // Check the object's X position
            model.obj3D.rotation.y +=0.01;
            // if (animationCount < animationTarget) {
            //     model.obj3D.position.y += 0.001; // You decide on the increment, higher value will mean the objects moves faster
            //     animationCount++;
            // } 

            // if (animationCount > animationTarget) {
            //     model.obj3D.position.y -= 0.001; // You decide on the increment, higher value will mean the objects moves faster
            //     animationCount--;
            // }

            // if (animationCount == animationTarget) {
            //     if (animationCount == maxAnimationIterations) {
            //         animationTarget = 0;
            //     } else {
            //         animationTarget = maxAnimationIterations;
            //     }
            // }
        }
        tick();

        // FBX loader
        // const panelClosed = await this.sdk.Scene.createNode();
        // const panelClosedParams = {
        //     url: '/assets/models/UCA1.fbx',
        //     visible: true,
        //     localScale: { x: 0.01, y: 0.01, z: 0.01 },
        //     localPosition: { x: 0, y: 0, z: 0 },
        //     localRotation: { x: -90, y: 0, z: 180 },
        //     colliderEnabled: true
        // };
        // panelClosed.addComponent('mp.fbxLoader', panelClosedParams);
        // panelClosed.obj3D.position.set(6, -1.52, -0.6);
        // panelClosed.start();
        // console.log('%c FBX - Model loaded! ', 'background: #ffffff; color: #00dd00');

        // FBX loader
        // const panelOpened = await this.sdk.Scene.createNode();
        // const panelOpenedParams = {
        //     url: '/assets/models/UCA1.3.fbx',
        //     visible: true,
        //     localScale: { x: 0.01, y: 0.01, z: 0.01 },
        //     localPosition: { x: 0, y: 0, z: 0 },
        //     localRotation: { x: -90, y: 0, z: 180 },
        //     colliderEnabled: true
        // };
        // panelOpened.addComponent('mp.fbxLoader', panelOpenedParams);
        // panelOpened.obj3D.position.set(6, -1.52, -0.6);
        // panelOpened.obj3D.visible = false;
        // panelOpened.start();
        // console.log('%c FBX - Model loaded! ', 'background: #ffffff; color: #00dd00');

        // this.panelClosed = panelClosed;
        // this.panelOpened = panelOpened;

        const mpSdk = this.sdk;
        const sensor = await mpSdk.Sensor.createSensor(mpSdk.Sensor.SensorType.CAMERA);
        // sensor.showDebug(true);
        const sources = await Promise.all([
            mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
              center: { x: 0, y: 1, z: -4.2 },
              // orientation: {pitch: 0, roll: 0, yaw: 0},
              size: {x: 3, y: 3, z: 5},
              userData: {
                id: 'box-control-sensor',
              },
            }),
            mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
                center: { x: 5.5, y: 1, z: -2 },
                // orientation: {pitch: 0, roll: 0, yaw: 0},
                size: {x: 3, y: 3, z: 5},
                userData: {
                  id: 'box-panel-sensor',
                },
              }),
        ]);
        let insideSensor = false;
        sensor.addSource(...sources);
        sensor.readings.subscribe({
            onAdded(source: any, reading: any) {
              console.log(source.userData.id, '#has a reading of:', reading);
            },
            onUpdated(source: any, reading: any) {
              console.log(source.userData.id, '#has an updated reading:', reading);
              switch(source.userData.id) {
                case 'box-control-sensor':
                  if (reading.inRange && !insideSensor) {
                    // alert('Entered inner sensor')
                    document.getElementById('control-btn').style.display = 'block';
                    insideSensor = true;
                  } else if (!reading.inRange) {
                    // alert('Exited inner sensor')
                    document.getElementById('control-btn').style.display = 'none';
                    insideSensor = false;
                  }
                  break;
                // case 'box-panel-sensor':
                //     if (reading.inRange && !insideSensor) {
                //       // alert('Entered inner sensor')
                //       document.getElementById('panel-btn').style.display = 'block';
                //       insideSensor = true;
                //     } else if (!reading.inRange) {
                //       // alert('Exited inner sensor')
                //       document.getElementById('panel-btn').style.display = 'none';
                //       insideSensor = false;
                //     }
                //     break;
                default:
                  break;
              }
            }
        });

        this.initMQTT();
        
    }

    initMQTT = () => {
        const myself = this;
        this.mqttClient = new Paho.Client(
            'b5ecfdc04d7d4b50a9187596a75bd5e0.s1.eu.hivemq.cloud', 
            8884, 'clientId-' + parseInt((Math.random() * 10000).toString(), 0));
        console.log('## mqtt init')
        this.mqttClient.onConnectionLost = onConnectionLost;
        this.mqttClient.onMessageArrived = onMessageArrived;

        // connect the client
        this.mqttClient.connect({
            userName: 'siemens_hivemq',
            password: "sv$dmb0N5MDEPpL1:8!<",
            useSSL: true,
            onSuccess: onConnect
        });

        // called when the client connects
        function onConnect() {
            // Once a connection has been made, make a subscription and send a message.
            console.log("##onConnect");
            myself.mqttClient.subscribe('control');
            // client.subscribe("speed");
            // client.subscribe("torque");
            // client.subscribe("current");
        }

        // called when the client loses its connection
        function onConnectionLost(responseObject: any) {
            if (responseObject.errorCode !== 0) {
                console.error("##onConnectionLost:" + responseObject.errorMessage);
            }
        }

        // called when a message arrives
        function onMessageArrived(message: any) {
            console.log("##onMessageArrived:" + JSON.stringify(message));
            // myself.outputs.receivedMessage = message.payloadString;
        }
    }

    toggleControl = () => {
        // alert('clicked')
        const message = new Paho.Message("true");
        message.destinationName = "control";
        this.mqttClient.send(message);
        console.log('## message sent')
    }

    togglePanelDoor = () => {
        this.panelOpened.obj3D.visible = !this.panelOpened.obj3D.visible;
        this.panelClosed.obj3D.visible = !this.panelClosed.obj3D.visible;
    }

    render() {
        return (
            <div className='frame'>
                {/*<button id="panel-btn" onClick={this.togglePanelDoor}>
                    Abrir / Fechar <br/> Porta do Quadro
                </button>*/}
                <button id="control-btn" onClick={this.toggleControl}>
                    Ligar Motor <br/>SIMOTICS
                </button>
                <iframe id='showcase' className='frame' src={this.src}></iframe>
            </div>    
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};