import React, { Component } from 'react';
import { SceneLoader } from '../../SceneLoader';
import { SceneObjects } from './scene-objects';
import { 
    // Frame, 
    GetSDK, 
    initComponents, 
    // SceneComponent, 
    ISceneNode, 
    // OrientedBox,  
    sdkKey } from '@mp/common';

import { Button } from 'react-bootstrap';
import { BiVolumeFull } from 'react-icons/bi';
import IframeLy from '../../helper/IframeLy';

interface Props {

}

interface State {
}

// augment window with the MP_SDK property
declare global {
  interface Window {
      MP_SDK: any;
  }
}

const isEnglishVersion = window.location.pathname.includes('/en');

export class ExperienceCenterMX extends Component<Props, State> {
    private src: string;
    private applicationKey: string;
    private sceneId: string = isEnglishVersion ? 'QPFy4qXhomz' : '9TJfNmrH5G9';
    private scene: SceneLoader = null;
    private sdk: any = null;

    constructor(props: Props, state: State) {
        super(props);
        
        this.state = {
        };

         // Forward url params.
        const params = objectFromQuery();
        params.m = params.m || this.sceneId;
        params.play = params.play || '1'; // Play enabled
        params.title = params.title || '1'; // Show title info [Frame.tsx]
        params.help = params.help || '0'; // Present default help [Frame.tsx]
        params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
        params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
        params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
        params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
        params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
        params.dh = params.dh || '1'; // Show Dollhouse View (default)
        params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
        // params.sr = params.sr || '-1.56, 1.55'; // Set camera position in photo scan
        // params.ss = params.ss || '2'; // -> Set position scan
        params.newtags = '1';

        // ensure applicationKey is inserted into the bundle query string
        params.applicationKey = params.applicationKey || sdkKey;
        this.applicationKey = params.applicationKey;

        const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
        this.src = `./bundle/showcase.html?${queryString}`;
    }

    async componentDidMount() {
        const showcase = document.getElementById('showcase') as HTMLIFrameElement;

        document.title = "Siemens - Digital Experience Center Mexico";
        this.sdk = await GetSDK(showcase, this.applicationKey);
        await initComponents(this.sdk);

        await this.sdk.App.state.waitUntil((appState: any) => appState.phase == 'appphase.playing');

        await this.sdk.Scene.configure((renderer: any, three: any) => {
            renderer.physicallyCorrectLights = true;
            renderer.gammaFactor = 2.2;
            renderer.gammaOutput = true;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.bias = 0.0001;
            renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        this.scene = new SceneLoader(this.sdk, this.sceneId, SceneObjects);
 
        await this.scene.load(this.sceneId);

        console.log('%c Bundle SDK fully loaded! ', 'background: #ffffff; color: #00dd00');
        console.log(this.sdk);

        const mpSdk = this.sdk;
        const myself = this;
        const sensor = await mpSdk.Sensor.createSensor(mpSdk.Sensor.SensorType.CAMERA);
        // sensor.showDebug(true);
        const sources = await Promise.all([
            mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
                center: { x: 0, y: 1, z: 11.5 },
                // orientation: {pitch: 0, roll: 0, yaw: 0},
                size: {x: 10, y: 3, z: 6},
                userData: {
                id: 'box-play-video',
                },
            }),
            mpSdk.Sensor.createSource(mpSdk.Sensor.SourceType.BOX, {
                center: { x: 0, y: 1, z: 1 },
                // orientation: {pitch: 0, roll: 0, yaw: 0},
                size: {x: 5, y: 3, z: 15},
                userData: {
                id: 'box-hide-video',
                },
            }),
        ]);
        const nodesIterator = myself.scene.nodeIterator()
        let videoNode: ISceneNode = null;
        let videoPlayed = false;
        for (const node of nodesIterator) {
            if (node.name === 'lobbyTV') {
                console.log('#node found', node)
                videoNode = node;
            }
        }
        sensor.addSource(...sources);
        sensor.readings.subscribe({
            onAdded(source: any, reading: any) {
                switch(source.userData.id) {
                    case 'box-hide-video':
                        if (reading.inRange) {
                            videoNode.obj3D.visible = false;
                        }
                        break;
                    default:
                        break;
                }
            },
            onUpdated(source: any, reading: any) {
                switch(source.userData.id) {
                    case 'box-hide-video':
                        if (reading.inRange) {
                            videoNode.obj3D.visible = false;
                            myself.playPauseVideo(videoNode, 'Pause');
                        } else {
                            videoNode.obj3D.visible = true;
                        }
                        break;
                    case 'box-play-video':
                        if (reading.inRange && !videoPlayed) {
                            myself.playPauseVideo(videoNode, 'Play');
                            videoPlayed = true;
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        // replace Embedly to IframeLy
        const iframe = new IframeLy(mpSdk);
        iframe.replace();
        
    }

    playPauseVideo(video: ISceneNode, status: 'Play' | 'Pause'): void {
        const componentIterator = video.componentIterator();
        for (const component of componentIterator) {
            if (component.componentType === 'mp.toggleState') {
                component.outputs.state = status === 'Play' ? true : false;
                component.outputs.negated = status === 'Play' ? false : true;
            }
            if (component.componentType === 'mp.hlsLoader') {
                component.inputs.enabled = status === 'Play' ? true : false;
            }
        }
        
    }

    render() {
        return (
            <div className='frame'>
                <Button id="unmuteButton" variant="dark"><BiVolumeFull /> Enable Video Sound</Button>
                <iframe id='showcase' className='frame' src={this.src}></iframe>
            </div>    
        );
      }

}

export const objectFromQuery = (url?: string): {[key: string]: string} => {
    const regex = /[#&?]([^=]+)=([^#&?]+)/g;
    url = url || window.location.href;
    const object: { [param: string]: string } = {};
    let matches;
    // regex.exec returns new matches on each
    // call when we use /g like above
    while ((matches = regex.exec(url)) !== null) {
      object[matches[1]] = decodeURIComponent(matches[2]);
    }
    return object;
};