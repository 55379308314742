import React, { Component } from 'react';
import { Frame, GetSDK, initComponents, sdkKey } from '@mp/common';
// import { AppState } from '../AppState';
import { SceneLoader } from '../SceneLoader';
import { Accordion, ListGroup, Badge, Button, OverlayTrigger, Tooltip, Modal, Form, CloseButton  } from 'react-bootstrap';
import { CameraReels } from 'react-bootstrap-icons';
// import { RadialGauge } from 'canvas-gauges';

interface Props {
  // appState: AppState;
}

interface State {
  showDasboard: boolean;
  gauges: any;
  gaugesList: any;
  streamURL: string;
  streamId: string;
  showModal: boolean;
  showStream: boolean;
}
var MODEL_ID = 'yGBesRymq9n'; //'JVPy9wN5iU2';
export class Main extends Component<Props, State> {
  private sdk: any = null;
  private scene: SceneLoader = null;
  private src: string;
  private applicationKey: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      showDasboard: false,
      gauges: {}, 
      gaugesList: [
        {
          id: 'gaugeRelativeHumidity',
          title: 'Relative Humidity'
        },
        {
          id: 'gaugeTemperature',
          title: 'Air Temperature'
        },
        {
          id: 'gaugeOutsideTemperature',
          title: 'Outside Temperature'
        },
      ],
      streamURL: '',
      streamId: '',
      showModal: false,
      showStream: false,
    };

    // Forward url params.
    const params = objectFromQuery();
    params.m = MODEL_ID;
    params.play = params.play || '1'; // Play enabled
    params.title = params.title || '1'; // Show title info [Frame.tsx]
    params.help = params.help || '1'; // Present default help [Frame.tsx]
    params.brand = params.brand || '1'; // Display all branding information in the "About" panel (top-left corner of 3D Showcase) [Frame.tsx]
    params.mls = params.mls || '0'; // Show branding information, links in Mattertag� Posts, and VR (default)
    params.mt = params.mt || '1'; // Show Mattertag� Posts (default) 
    params.hr = params.hr || '1'; // Show highlight reel (default) [Frame.tsx]
    params.f = params.f || '1'; // Let the user navigate the 3D model floor by floor (default) [Frame.tsx]
    params.dh = params.dh || '1'; // Show Dollhouse View (default)
    params.qs = params.qs || '1'; // Enable Quickstart, only works if Start Position is Inside View. [Frame.tsx]
    params.sr = params.sr || ' 3.1, -.21'; // Set camera position in photo scan
    params.ss = params.ss || '32'; // -> Set position scan

    // ensure applicationKey is inserted into the bundle query string
    params.applicationKey = params.applicationKey || sdkKey;
    this.applicationKey = params.applicationKey;

    const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    this.src = `./bundle/showcase.html?${queryString}`;

  }

  async componentDidMount() {
    document.title = "R&D Test Lab - US";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const streamId = urlParams.get('sid');
    if (streamId) {
      this.setState({ streamId, showStream: true });
      this.setStreamUrl(streamId);
    }
    

    console.log('#streamId: ' + streamId)
    
    this.sdk = await GetSDK('sdk-iframe', this.applicationKey);
    await initComponents(this.sdk);
    await this.sdk.Scene.configure((renderer: any, three: any) => {
      renderer.physicallyCorrectLights = true;
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.bias = 0.0001;
      renderer.shadowMap.type = three.PCFSoftShadowMap;

    });
    this.scene = new SceneLoader(this.sdk);

    await this.scene.load(MODEL_ID);

    this.setState({showDasboard: true}),

    console.log(this.scene);
    console.log(this.sdk);

    const myself = this;
    // Listen for the event.
    window.addEventListener('gaugeUpdate', function (e) {
      // e.target matches elem
      const gauges = (window as any).gauges;
      myself.setState({gauges: gauges})

      Object.keys(gauges).forEach(gaugeName => {
        console.log('#gauges update', gauges[gaugeName])
        // const gauge = gauges[gaugeName];

        // if (gauge.canvas) {
        //   const element = document.getElementById(gaugeName);
        //   gauge.canvas.renderTo = element;
        //   gauge.canvas.valueBox = true;
        //   gauge.canvas.width = 140;
        //   gauge.canvas.height = 140;
        //   const radialGauge = new RadialGauge(gauge.canvas);
        //   radialGauge.draw();
        // }

      });
    }, false);

    this.sdk.Sweep.current.subscribe((currentSweep: any) => {
      // Change to the current sweep has occurred.
      if (currentSweep.sid === '') {
        console.log('Not currently stationed at a sweep position');
      } else {
        console.log('#sweep - Currently at sweep', currentSweep.sid);
        console.log('#sweep - Current position', currentSweep.position);
      }
    });
  }

  getGaugeValue(gaugeName: string): string {
    if (this.state.gauges[gaugeName]) {
      return this.state.gauges[gaugeName].value + this.state.gauges[gaugeName].unit
    }
    return '';
  }

  getGaugeHighlight(gaugeName: string): string {
    if (this.state.gauges[gaugeName] && this.state.gauges[gaugeName].highlight) {
      console.log('#addingi highlight', this.state.gauges[gaugeName].highlight)
      // return {
      //   backgroundColor: `${this.state.gauges[gaugeName].highlight} !important`
      // }
      return this.state.gauges[gaugeName].highlight
    }

    return ''
  }

  goToView(gaugeName: string) {
    switch (gaugeName) {
      case 'gaugeOutsideTemperature':
        this.moveToSweep('19faf7c2d36845c284a09072c5103ce7', {x: 5, y: -70}, 1.7);
        break;
      case 'gaugeTemperature':
        this.moveToSweep('1c9173977d884a9da7c9840518884184', {x: 0, y: 215}, 2.4);
        break;
      case 'gaugeRelativeHumidity':
        this.moveToSweep('5d83009976a5463b83eb881781c74c71', {x: 0, y: 170}, 1);
        break;
    
      default:
        break;
    }
  }

  async moveToSweep(sweepId: string, rotation?: {x: number, y:number}, zoom?: number) {
    if (!rotation) rotation = { x: 30, y: -45 };
    const transition = this.sdk.Sweep.Transition.FLY;
    const transitionTime = 2000; // in milliseconds

    
    try {
      await this.sdk.Sweep.moveTo(sweepId, {
        rotation: rotation,
        transition: transition,
        transitionTime: transitionTime,
      })
      if (zoom) {
        await this.sdk.Camera.zoomTo(zoom);
      }
    } catch (error) {
      console.log('Failed to go to sweep', error);
    }
  }

  openLiveStream() {
    console.log(this.state.streamURL)
    if (!this.state.streamURL) {
      this.setState({ showModal: true });
    } else {
      this.conectStream();
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  setStreamUrl(streamId: string) {
    const url = `https://web.microsoftstream.com/embed/video/${streamId}?autoplay=true&showinfo=false`;
    this.setState({ streamURL: url, streamId });
  }

  handleInputChange(e: any) {
    const value = e.target.value;
    const regex = /https:\/\/web\.microsoftstream\.com\/video\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gm;
    const match = regex.exec(value);
    const streamId = match ? match[1] : '';
    
    this.setStreamUrl(streamId);
  }
  conectStream() {
    if (!window.location.search) {
      window.history.pushState('','',`/rd-testlab-us-sample?sid=${this.state.streamId}`);
    }
    this.setState({ showStream: true, showModal: false });
  }

  closeStream = () => {
    this.setState({ showStream: false });
  }

  render() {
    return (
      <div className='main'>
        <Frame src={this.src}></Frame>
        {this.state.showDasboard &&
        <div>
          <div className='quickPreview'onClick={ () => this.openLiveStream()}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="button-tooltip-2">Click to open livestream</Tooltip>}
            >
              <CameraReels color='white' size={20}/>
            </OverlayTrigger>
          </div>
          {this.state.showStream && <div className='liveStream'>
            <iframe 
              width="400" //640
              height="200" //360
              src={this.state.streamURL}
              allowFullScreen 
              style={{"border":"none"}}>
            </iframe>
            <div className="closeStreamBtn">
              <CloseButton variant="white" onClick={this.closeStream}/>
            </div>
          </div>}
          
          <Modal show={this.state.showModal} onHide={() => this.handleCloseModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Stream URL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Text className="text-muted">
                  Please add a Microsoft Stream URL to enable your stream.
                </Form.Text>
                <Form.Control type="text" placeholder="Enter URL" defaultValue={this.state.streamURL} onChange={(e) => this.handleInputChange(e)} />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleCloseModal()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => this.conectStream()}>
                Connect
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="dashboard">
            <Accordion defaultActiveKey={['0']} flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header >

                </Accordion.Header>
                <Accordion.Body>

                  {/* <canvas id="gaugeRelativeHumidity" width="100" height="100"></canvas> */}

                  <ListGroup as="ol">

                    {this.state.gaugesList.map((item: any, index: number) => {
                        return (<ListGroup.Item key={index}  as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{item.title}</div>
                              <Button variant="outline-secondary" size="sm" onClick={ () => this.goToView(item.id)}>Go to view</Button>
                            </div>
                            <Badge bg="secondary" pill ref={(node: any) => {
                              if (node) {
                                node.style.setProperty("background", this.getGaugeHighlight(item.id), "important");
                              }
                            }}>
                              {this.getGaugeValue(item.id)}
                            </Badge>
                          </ListGroup.Item>);
                    })}

                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        }
      </div>
    );
  }
}

// from cwf/modules/browser.ts
export const objectFromQuery = (url?: string): {[key: string]: string} => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object: { [param: string]: string } = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
