import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Size } from './PlaneRenderer';
import * as d3 from 'd3';

export type DataPoint = {
    x: Date,
    y: number
};

export type DataPoints = Array<DataPoint>

type Inputs = {
    data: Array<DataPoint> | null,
    datapoint: DataPoint | null,
    datapoints: DataPoints | null,
    size: Size,
    datapointColor: string | null;
    datapointNameSerie: string | null;
    yDomain: number[] | null,
    yUnit: string | null,
    showPoints: boolean,
    xLabel: string | null,
    timeFormat: string,
}

type Outputs = {
    src: string | null;
    srcPosition: { x: number, y: number };
    srcSize: Size;
    destPosition: { x: number, y: number };
    destSize: Size;
} & ComponentOutput;

class D3Handler extends SceneComponent {
    private samples = 100; // how many data points to display

    inputs: Inputs = {
        data: null,
        datapoint: null,
        datapoints: null,
        size: { h: 512, w: 512 },
        datapointColor: "#000000",
        datapointNameSerie: "Variable A",
        yDomain: null,
        yUnit: null,
        showPoints: true,
        xLabel: '',
        timeFormat: '%H:%M'
    }

    outputs = {
        src: null,
        srcPosition: { x: 0, y: 0 },
        srcSize: { w: 64, h: 64 },
        destPosition: { x: 0, y: 0 },
        destSize: { w: 64, h: 64 },
    } as Outputs;


    onInit() {
        this.inputs.data = [];
        this.outputs.srcSize = this.inputs.size;
        this.outputs.destSize = this.inputs.size;
    }

    onInputsUpdated(oldInputs: Inputs) {
        if ((!this.inputs.datapoint && !this.inputs.datapoints ) || !this.inputs.data) return;

        if (this.inputs.data.length >= this.samples)
            this.inputs.data.shift();

        if (this.inputs.datapoints) {
            // console.log('#datapoints updated', this.inputs.datapoints)
            this.inputs.data = this.inputs.datapoints;
        } else {
            // console.log('# single datapoint updated')
            this.inputs.data.push(this.inputs.datapoint);
        }
        
        this.inputs.datapoint = null;

        // rasterize the svg data and set as src output
        const svg = this.getChartSVG(this.inputs.data).getRootNode();
        const serializer = new XMLSerializer();
        const svgData = serializer.serializeToString(svg);
        this.outputs.src = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svgData)));
    }

    onDestroy() { }

    private getChartSVG(data: Array<DataPoint>): SVGElement {

        // based off https://www.d3-graph-gallery.com/graph/scatter_basic.html
        // set the dimensions and margins of the graph
        const margin = { top: 200, right: 60, bottom: 120, left: 90 },
            width = this.inputs.size.w - margin.left - margin.right,
            height = this.inputs.size.h - margin.top - margin.bottom;

        // create d3 svg obj
        const svg = d3.create('svg')
            .attr('xmlns', 'http://www.w3.org/2000/svg')
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis -> time scale
        var xScale = d3.scaleTime()
            .domain(d3.extent(data, function (d) { return d.x; }))
            .rangeRound([0, width]);

        var xAxis = svg.append("g").attr("class", "axis x xAxis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(xScale)
                .ticks(10)
                .tickFormat(d3.timeFormat(this.inputs.timeFormat)));

        xAxis.selectAll("line")
            .style("stroke", "black");

        xAxis.selectAll("path")
            .style("stroke", "black")
            .attr("stroke-width", 2.0);

        xAxis.selectAll("text")
            .style("fill", "black")
            .style("font-size", "20px");

        // Add Y axis -> linear scale
        const yScale = d3.scaleLinear()
            .domain(this.inputs.yDomain ? [...this.inputs.yDomain] : [0, 100])
            .rangeRound([height, 0]);

        var yAxis = svg.append("g").attr("class", "axis y yAxis")
            .call(d3.axisLeft(yScale));

        yAxis.selectAll("line")
            .style("stroke", "black");

        yAxis.selectAll("path")
            .style("stroke", "black")
            .attr("stroke-width", 2.0);

        yAxis.selectAll("text")
            .style("fill", "black")
            .style("font-size", "20px");

        // X-axis Label
        var xLabel = svg.append("g").attr("class", "axis x label");
        xLabel.append("text")
            .attr("x", (width + margin.left + margin.right) * 0.5)
            .attr("y", height + margin.bottom * 0.65)
            .attr("dx", "1em")
            .attr("fill", "#000000")
            .style("text-anchor", "end")
            .style("font-size", "30px")
            .text(this.inputs.xLabel);

        // Y-axis Label
        // var deg = 176; // �	&#176;	&deg;	degree sign
        var yLabel = svg.append("g").attr("class", "axis y label");
        // yLabel.append("text")
        //     .attr("x", (margin.left * 0.85))
        //     .attr("y", 0 - (margin.top * 0.4))
        //     .attr("dy", "1em")
        //     .attr("fill", "#000000")
        //     .style("text-anchor", "end")
        //     .style("font-size", "30px")
        if (this.inputs.yUnit) {
            yLabel.append("text")
                .attr("x", (margin.left * 0.85))
                .attr("y", 0 - (margin.top * 0.4))
                .attr("dy", "1em")
                .attr("fill", "#000000")
                .style("text-anchor", "end")
                .style("font-size", "30px")
                .text("Value (" + this.inputs.yUnit + ")");
        }

        // Add dots (circles)
        if (this.inputs.showPoints) {
            svg.append('g')
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", function (d) { return xScale(d.x); })
            .attr("cy", function (d) { return yScale(d.y); })
            .attr("r", 5)
            .style("fill", this.inputs.datapointColor)
        }

        // Add the line path between points
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", this.inputs.datapointColor)
            .attr("stroke-width", 3)
            .attr("d", d3.line<DataPoint>()
                .x(function (d) { return xScale(d.x) })
                .y(function (d) { return yScale(d.y) })
            )

        // Remove Legend Content 
        svg.selectAll("legend").remove();

        // Add Legend
        var legend = svg.append('g')
            .attr('class', 'legend')
            .attr("transform", "translate(" + (width * 0.25) + "," + (0 - (margin.top * 0.45)) + ")");

        // Legend box
        legend.append("rect")
            .attr("width", 0.5 * width)
            .attr("height", 0.075 * height)
            .attr("fill", "#000000")
            .attr("stroke", "#000000")
            .attr("stroke-width", 1.5)
            .attr("fill-opacity", 0.05)
            .attr("stroke-opacity", 1);

        // Legend item dots (circle)
        legend.append("circle")
            .attr("cx", 0.05 * (width + margin.left + margin.right))
            .attr("cy", 0.135 * margin.top)
            .attr("r", 5)
            .style("fill", this.inputs.datapointColor)

        // Update Legend item text
        legend.selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("x", 0.125 * (width + margin.left + margin.right))
            .attr("y", 0.15 * margin.top)
            .attr("text-anchor", "center")
            .attr("alignment-baseline", "middle")
            .style("font-size", "30px")
            .style("fill", this.inputs.datapointColor)
            .text(this.inputs.datapointNameSerie);

        svg.selectAll("g.yAxis g.tick")
            .append("line")
            .style("stroke", "black")
            .style("shape-rendering", "crispEdges")
            .style("stroke-opacity", .2)
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", width)
            .attr("y2", 0);

        svg.selectAll("g.xAxis g.tick")
            .append("line")
            .style("stroke", "black")
            .style("shape-rendering", "crispEdges")
            .style("stroke-opacity", .2)
            .attr("x1", 0)
            .attr("y1", -height)
            .attr("x2", 0)
            .attr("y2", 0);

        return svg.node();
    }
}

export const d3HandlerType = 'mp.d3Handler';
export function makeD3Handler() {
    return new D3Handler();
}