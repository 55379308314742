import { Gauge } from '@mp/scene-components';

const gaugeCurrent = new Gauge('gaugeCurrent', true);

const isPorto = location.pathname.includes('porto');
gaugeCurrent.setPosition({
    x: 0,
    y: 2,
    z: isPorto ? 4.2 : -4.2
})

gaugeCurrent.setRotation({
    x: 0,
    y: isPorto ? 180 : 0,
    z: 0
});

gaugeCurrent.setScale({
    x: .5,
    y: .5,
    z: .5
})

gaugeCurrent.setUnit('A');

gaugeCurrent.setMqttClient("b5ecfdc04d7d4b50a9187596a75bd5e0.s1.eu.hivemq.cloud", 8884, "siemens_hivemq", "sv$dmb0N5MDEPpL1:8!<", true, 'current');

gaugeCurrent.setTitle('Corrente');
gaugeCurrent.setMinMaxValues(0, 1);
gaugeCurrent.setRange([0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1])
gaugeCurrent.setHighlights([
    {
        "from": 0,
        "to": .75,
        "color": "rgba(0, 192, 0, 1.0)"
    },
    {
        "from": .75,
        "to": .9,
        "color": "rgba(192, 192, 0, 1.0)"
    },
    {
        "from": .9,
        "to": 1,
        "color": "rgba(192, 0, 0, 1.0)"
    }
])


export default gaugeCurrent.Gauge;