import { Gauge } from '@mp/scene-components';

const gaugeSpeed = new Gauge('gaugeSpeed', true);
const isPorto = location.pathname.includes('porto');
gaugeSpeed.setPosition({
    x: .5,
    y: 2,
    z: isPorto ? 4.2 : -4.2
})

gaugeSpeed.setRotation({
    x: 0,
    y: isPorto ? 180 : 0,
    z: 0
});

gaugeSpeed.setScale({
    x: .5,
    y: .5,
    z: .5
})

gaugeSpeed.setUnit('ºC');

gaugeSpeed.setMqttClient("b5ecfdc04d7d4b50a9187596a75bd5e0.s1.eu.hivemq.cloud", 8884, "siemens_hivemq", "sv$dmb0N5MDEPpL1:8!<", true, 'temperature');

gaugeSpeed.setTitle('Temperatura');
gaugeSpeed.setMinMaxValues(0, 60);
gaugeSpeed.setRange([0, 10, 20, 30, 40, 50, 60])
gaugeSpeed.setHighlights([
    {
        "from": 0,
        "to": 40,
        "color": "rgba(0, 192, 0, 1.0)"
    },
    {
        "from": 40,
        "to": 50,
        "color": "rgba(192, 192, 0, 1.0)"
    },
    {
        "from": 50,
        "to": 60,
        "color": "rgba(192, 0, 0, 1.0)"
    }
])


export default gaugeSpeed.Gauge;