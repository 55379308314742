import { SceneComponent, ComponentOutput } from '../SceneComponent';
import { Texture, VideoTexture } from 'three';

type Inputs = {
  src: MediaStream | string | HTMLVideoElement | null;
  flipX?: boolean;
};

type Outputs = {
  texture: Texture | null;
} & ComponentOutput;

class VideoRenderer extends SceneComponent {
  private video: HTMLVideoElement;
  private texture: VideoTexture;

  inputs: Inputs = {
    src: null,
    flipX: false,
  }

  outputs = {
    texture: null,
  } as Outputs;

  onInit() {
  }

  onInputsUpdated() {
    this.releaseTexture();

    const THREE = this.context.three;
    if (!this.inputs.src) {
      this.video.src = '';
      return;
    }

    if (this.inputs.src instanceof HTMLVideoElement) {
      this.video = this.inputs.src;
    } else {
      this.video = this.createVideoElement();

      if (typeof this.inputs.src === 'string') {
        this.video.src = this.inputs.src;
      } else {
        this.video.srcObject = this.inputs.src;
      }

      this.video.load();
    }

    this.texture = new THREE.VideoTexture(this.video);
    this.texture.minFilter = THREE.LinearFilter;
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.format = THREE.RGBFormat;

    if (this.inputs.flipX) {
      this.texture.center = new THREE.Vector2(0.5, 0.5);
      this.texture.rotation = Math.PI;
      this.texture.flipY = false;
    }

    this.outputs.texture = this.texture;

    this.video.play().then(() => {
      if (this.iOSDevice() && this.video.muted === true) {
        this.unMuteVideo(this);
      } 
      else {
        this.video.muted = false;
      }
    }).catch((error: any) => {
      console.log('Failed to play video', error);
    });
  }

  iOSDevice(): boolean {
    const mobile = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    // check if Macbook using Safari
    const mac = navigator.userAgent.includes("Mac") && navigator.vendor.indexOf('Apple') > -1;

    return mobile || mac;
  }

  unMuteVideo(context: this): void {
    const unmuteButton = document.getElementById('unmuteButton');
    unmuteButton.style.display = 'initial';
    
    unmuteButton.addEventListener('click', function() {
      context.video.muted = false;
      const videos = (window as any).videos;
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        video.muted = false;
      }
      unmuteButton.remove();
    })
    
  }

  onDestroy() {
    this.releaseTexture();
  }

  releaseTexture() {
    if (this.texture) {
      this.outputs.texture = null;
      this.texture.dispose();
    }
  }

  private createVideoElement() {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.controls = true;
    video.autoplay = true;
    video.muted = true;
    video.loop = true;

    return video;
  }
}

export interface IVideoRenderer extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const videoRendererType = 'mp.videoRenderer';
export function makeVideoRenderer() {
  return new VideoRenderer();
}
