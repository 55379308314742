import { SceneComponent, ComponentOutput, IPainter3d } from '@mp/common';
import { Object3D, Camera, Scene, WebGLRenderer } from 'three';

type Outputs = {
    painter: IPainter3d | null;
} & ComponentOutput;

class ScenePainterComponent extends SceneComponent {
    private scenePainter: ScenePainter;
    private scene: Scene;
    private camera: Camera;
    private cube: Object3D;
    private orbiter1: Object3D;
    private orbiter2: Object3D;
    private orbiter3: Object3D;

    outputs = {
        painter: null,
    } as Outputs;

    onInit() {
        const THREE = this.context.three;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera();
        this.camera.position.z = 5;

        const cubeEdges = new THREE.LineSegments(
            new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(1.0, 1.0, 1.0)),
            new THREE.MeshBasicMaterial({
                color: 0x000000, // black color
            }));
        const orbitEdges1 = new THREE.LineSegments(
            new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(0.5, 0.5, 0.5)),
            new THREE.MeshBasicMaterial({
                color: 0x000000, // black color
            }));
        const orbitEdges2 = new THREE.LineSegments(
            new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(0.4, 0.4, 0.4)),
            new THREE.MeshBasicMaterial({
                color: 0x000000, // black color
            }));
        const orbitEdges3 = new THREE.LineSegments(
            new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(0.3, 0.3, 0.3)),
            new THREE.MeshBasicMaterial({
                color: 0x000000, // black color
            }));

        const cube = new THREE.Mesh(
            new THREE.BoxBufferGeometry(1.0, 1.0, 1.0),
            new THREE.MeshBasicMaterial({
                color: 0x00ff00, // green 
            }));  

        const orbiter1 = new THREE.Object3D();
        const orbitCube1 = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.5, 0.5, 0.5),
            new THREE.MeshBasicMaterial({
                color: 0xff0000, // red
            }));

        const orbiter2 = new THREE.Object3D();
        const orbitCube2 = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.4, 0.4, 0.4),
            new THREE.MeshBasicMaterial({
                color: 0x0000ff, // blue
            }));

        const orbiter3 = new THREE.Object3D();
        const orbitCube3 = new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.3, 0.3, 0.3),
            new THREE.MeshBasicMaterial({
                color: 0xffff00, // yellow
            }));

        // associate Cube 3D Object with cube Mesh
        this.cube = cube;

        // associate 3D Objects orbiters
        this.orbiter1 = orbiter1;
        this.orbiter2 = orbiter2;
        this.orbiter3 = orbiter3;

        // set Positioning of the orbiter Meshs
        orbitCube1.position.x = 2;
        orbitCube2.position.y = 1.5;
        orbitCube3.position.z = 1;

        // set Positioning of the orbiter Mesh Edges
        orbitEdges1.position.x = 2;
        orbitEdges2.position.y = 1.5;
        orbitEdges3.position.z = 1;

        // set scale size of edges
        orbitEdges1.scale.multiplyScalar(1.0001);
        orbitEdges2.scale.multiplyScalar(1.0001);
        orbitEdges3.scale.multiplyScalar(1.0001);
        cubeEdges.scale.multiplyScalar(1.0001);

        // add Edge Meshs to 3D objects 
        this.orbiter1.add(orbitEdges1);
        this.orbiter2.add(orbitEdges2);
        this.orbiter3.add(orbitEdges3);
        this.cube.add(cubeEdges);

        // associate 3D Object to the orbiter Meshs
        this.orbiter1.add(orbitCube1);
        this.orbiter2.add(orbitCube2);
        this.orbiter3.add(orbitCube3);

        // add orbiters objects to Cube object
        this.cube.add(this.orbiter1);
        this.cube.add(this.orbiter2);
        this.cube.add(this.orbiter3);

        // add cube 3D object to scene
        this.scene.add(this.cube);

        // call ScenePainter
        this.scenePainter = new ScenePainter(this.scene, this.camera);

        // set scenePainter to 'painter' ouput variable
        this.outputs.painter = this.scenePainter;
    }

    onTick(delta: number) {
        this.cube.rotation.x += delta * 0.001;
        this.cube.rotation.y += 0.2 * delta * 0.001;

        this.orbiter1.rotation.y -= 2 * delta * 0.001;
        this.orbiter2.rotation.z -= 1.5 * delta * 0.001;
        this.orbiter3.rotation.x -= 1 * delta * 0.001;

        // force a repaint
        this.outputs.painter = null;
        this.outputs.painter = this.scenePainter;
    }
}

class ScenePainter implements IPainter3d {
    constructor(private scene: Scene, private camera: Camera) { }

    paint(renderer: WebGLRenderer): void {
        renderer.clear();
        renderer.render(this.scene, this.camera);
    }
}

export interface IScenePainter extends SceneComponent {
    outputs: Outputs;
}

export const scenePainterType = 'mp.scenePainter';
export function makeScenePainter() {
    return new ScenePainterComponent();
}
