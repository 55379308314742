import {  Video } from '@mp/scene-components';


const learningCenterStreaming = new Video('learningCenterStreaming', true, true);
learningCenterStreaming.setPosition({
    x: 10.090,
    y: -2.062,
    z: 0.169
})

learningCenterStreaming.setRotation({
    x: 0,
    y: 90,
    z: 0
});

learningCenterStreaming.setScale({
    x: 2.94,
    y: 2.52,
    z: 1
})
const learningCenterStreamingURLs = [
    'https://hwvod.streamingmediahosting.com/10445/0_vk61tmv3_0_zl3w9oxe_1.mp4/index.m3u8',
    'https://hwvod.streamingmediahosting.com/10445/0_jnt4awd2_0_b0z8p15j_2.mp4/index.m3u8',
    'https://hwvod.streamingmediahosting.com/10445/0_vt1jo4vl_0_o9kylegc_2.mp4/index.m3u8',
    'https://hwvod.streamingmediahosting.com/10445/0_bx86z7bh_0_0l0girti_2.mp4/index.m3u8'
];
learningCenterStreaming.setUrls(learningCenterStreamingURLs);
learningCenterStreaming.setBorderRadius(0);
learningCenterStreaming.setBorderColor('white');
learningCenterStreaming.setBorderShadow(false);
learningCenterStreaming.setBackgroundColor('white');

export default learningCenterStreaming.Video;