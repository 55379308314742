import {  Video } from '@mp/scene-components';

const lobbyTV = new Video('lobbyTV', false);
lobbyTV.setPosition({
    x: 1.820,
    y: -2.138, // 2.052, //-2.138,
    z: 8.010
})

lobbyTV.setRotation({
    x: 1,
    y: 180,
    z: 0
});

lobbyTV.setScale({
    x: 1.46,
    y: 1.40,
    z: 1
})
let videoPath = '/assets/videos/ga-400/SITRAIN-DI-Openness-Teaservideo-EN_original.mp4';

const lobbyVideoURL = window.location.origin + videoPath;
lobbyTV.setUrls([lobbyVideoURL])

export default lobbyTV.Video;