import { SceneComponent, ComponentOutput } from '@mp/common';
import { VideoTexture } from 'three';

type Inputs = {
    src: string;
    enabled: boolean;
};

type Outputs = {
    video: HTMLVideoElement | null;
    aspect: number;
} & ComponentOutput;

class VideoSourceLoader extends SceneComponent {
    private video: HTMLVideoElement | null = null;
    private texture: VideoTexture | null = null;
    private newVideo: Boolean = true;

    inputs: Inputs = {
        src: '',
        enabled: true,
    }

    outputs = {
        video: null,
        aspect: 1,
    } as Outputs;

    onInit() {
        this.video = this.createVideoElement();
        if (!window.hasOwnProperty('videos')) {
            (window as any).videos = [this.video];
        } else {
            (window as any).videos.push(this.video);
        }
        this.outputs.aspect = 720 / 480;
        if (this.inputs.enabled) {
            this.setupStream();
        }
    }

    onInputsUpdated() {
        this.setupStream();
    }

    onDestroy() {
        this.releaseResources();
    }

    private createVideoElement() {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        video.setAttribute('height', '480');
        video.setAttribute('width', '720');
        video.volume = 0.5;
        video.autoplay = true;
        video.muted = true;
        return video;
    }

    private setupStream() {
        this.releaseResources();

        if (this.inputs.enabled && this.newVideo) {
            // this.video = this.createVideoElement();
            this.video.load();
            this.video.src = this.inputs.src;
            this.outputs.video = this.video;
        }
    }

    private releaseResources() {
        this.newVideo = true;
        if (this.video) {
            // if (this.video.src === this.inputs.src) {
            //     if (this.video.ended) {
            //         this.video.currentTime = 0;
            //     }
            //     if (this.video.paused) {
            //         this.video.play();
            //     } else {
            //         this.video.pause()
            //     }
            //     this.newVideo = false;
            //     return;
            // }
            this.video.pause()
            this.video.currentTime = 0;
            this.video.src = "";
        }

        if (this.texture) {
            this.texture.dispose();
            this.texture = null;
            this.outputs.video = null;
        }
    }
}

export const videoSourceLoaderType = 'mp.videoSourceLoader';
export function makeVideoSourceLoader() {
    return new VideoSourceLoader();
}
