import { Image } from '@mp/scene-components';

let imagePath = '/assets/videos/dwc/DWC_Agenda.png';

const DWCAgenda = new Image('dwcAgenda', imagePath)

// DWCAgenda.setPosition({
//     x: -26.51,
//     y: 1.6,
//     z: -6.2
// })

// DWCAgenda.setRotation({
//     x: 4,
//     y: -90,
//     z: 0
// });

// DWCAgenda.setScale({
//     x: 2.0,
//     y: 1.2,
//     z: 1
// })

DWCAgenda.setPosition({
    x: -30.85,
    y: 1.3, // 2.052, //-2.138,
    z: -4.6
})

DWCAgenda.setRotation({
    x: 0,
    y: 212,
    z: 0
});

DWCAgenda.setScale({
    x: 1.5,
    y: 1,
    z: 1
})

DWCAgenda.setTextureSize({
    w: 1024,
    h: 768
})
export default DWCAgenda.Image;