type CoordinatesType = {
    x: number, y: number, z: number
}

export class Graphic {
    private graphicComponent = {
        "name": "scatterplot",
        "position": {
          "x": 11.5,
          "y": 1.65,
          "z": 4.555
        },
        "rotation": {
          "x": 0,
          "y": 180,
          "z": 0
        },
        "scale": {
          "x": 1.0,
          "y": 1.0,
          "z": 1.0
        },
        "components": [
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "colorBackground": "#ffffff",
              "transparent": false,
              "localScale": {
                "x": 1,
                "y": 1,
                "z": 1
              },
              "localPosition": {
                "x": 0,
                "y": 0,
                "z": 0
              }
            }
          },
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "transparent": true,
              "enabledBackground": false,
              "colorBackground": "#ffffff",
              "localScale": {
                "x": 1.0,
                "y": 1.0,
                "z": 1.0
              },
              "localPosition": {
                "x": 0,
                "y": 0,
                "z": 0
              }
            }
          },
          {
            "type": "mp.canvasRenderer",
            "inputs": {
              "textureRes": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.canvasImage",
            "inputs": {
              "srcSize": {
                "w": 1024,
                "h": 1024
              },
              "destSize": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.d3Handler",
            "inputs": {
              "size": {
                "w": 1024,
                "h": 1024
              },
              "datapointColor": "#CF5300",
              "datapointNameSerie": "Temperature",
              "showPoints": true,
            }
          },
          {
            "type": "mp.dispersionDataGenerator",
            "inputs": {
              "dataStreamFrequency": 0.5
            }
          },
          // {
          //   "type": "mp.gaugeData",
          //   "inputs": {
          //       "updateInterval": 5000,
          //       "deviceKey": '',
          //   }
          // },
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "transparent": true,
              "opacity": 0.8,
              "localScale": {
                "x": 1.0,
                "y": 1.0,
                "z": 1.0
              }
            }
          },
          {
            "type": "mp.canvasRenderer",
            "inputs": {
              "textureRes": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.canvasBorder",
            "inputs": {
              "position": {
                "x": 0,
                "y": 0
              },
              "size": {
                "h": 1024,
                "w": 1024
              },
              "radius": 10
            }
          },
          {
            "type": "mp.planeRenderer",
            "inputs": {
              "transparent": true,
              "opacity": 0.8,
              "polygonOffset": true,
              "polygonOffsetFactor": -2
            }
          },
          {
            "type": "mp.canvasRenderer",
            "inputs": {
              "textureRes": {
                "w": 1024,
                "h": 1024
              }
            }
          },
          {
            "type": "mp.canvasText",
            "inputs": {
              "size": {
                "h": 1024,
                "w": 1024
              },
              "text": "Temperature Dispersion Chart",
              "font": "normal bold 50px sans-serif",
              "color": "black",
              "colorBorder": "black",
              "position": {
                "x": 512,
                "y": 50,
                "z": 0
              },
              "textWidth": 1024
            }
          }
        ],
        "bindings": [
          [ 1, "texture", 2, "texture" ],
          [ 2, "painter", 3, "painter" ],
          [ 3, "src", 4, "src" ],
          [ 4, "datapoints", 5, "datapoints" ],
          [ 4, "datapoint", 5, "datapoint" ],
          [ 4, "yDomain", 5, "yDomain" ],

          // [ 5, "receivedValue", 6, "receivedMessage" ],

          [ 7, "painter", 8, "painter" ],
          [ 6, "texture", 7, "texture" ],

          [ 10, "painter", 11, "painter" ],
          [ 9, "texture", 10, "texture" ]

        ],
        "events": [
          [ 2, "repaint", 3, "paint.ready" ]
        ]
      }
    private graphic: any;

    constructor(name: string, title: string, sprite?: boolean) {
        this.graphic = { ...this.graphicComponent };
        // if (sprite) {
        //   this.graphic = { ...this.spriteGraphicComponent }
        // }
        this.graphic.name = name;

        this.changeComponentProperties('mp.canvasText', 'text', title);
    }

    get Graphic(): any {
        return this.graphic;
    }

    public setPosition(position: CoordinatesType) {
        this.graphic.position = position;
    }

    public setRotation(rotation: CoordinatesType) {
        this.graphic.rotation = rotation;
    }

    public setScale(scale: CoordinatesType) {
        this.graphic.scale = scale;
    }

    public setSprite() {
      // this.changeComponentProperties('mp.planeRenderer', 'sprite', true);
      const components = [...this.graphic.components];
        components.forEach((component: any) => {
            if (component.type === 'mp.planeRenderer') {
                component.type = 'mp.spriteRenderer';
            }
        });
        this.graphic.components = [...components];
    }

    public setDataPointColor(color: string) {
      this.changeComponentProperties('mp.d3Handler', 'datapointColor', color);
    }

    public setShowPoints(show: boolean) {
      this.changeComponentProperties('mp.d3Handler', 'showPoints', show);
    }

    public setXLabel(label: string) {
      this.changeComponentProperties('mp.d3Handler', 'xLabel', label);
    }

    public setYUnit(unit: string) {
      this.changeComponentProperties('mp.d3Handler', 'yUnit', unit);
    }

    public setDataPointNameSerie(name: string) {
      this.changeComponentProperties('mp.d3Handler', 'datapointNameSerie', name);
    }

    public setDataPointMargin(margin: number) {
      this.changeComponentProperties('mp.dispersionDataGenerator', 'dataPointMargin', margin);
    }

    public setSiteControlAPI(clientId: string, siteId: string, deviceType: string, deviceKey: string) {
      this.changeComponentProperties('mp.dispersionDataGenerator', 'clientId', clientId);
      this.changeComponentProperties('mp.dispersionDataGenerator', 'siteId', siteId);
      this.changeComponentProperties('mp.dispersionDataGenerator', 'deviceKey', deviceKey);
      this.changeComponentProperties('mp.dispersionDataGenerator', 'deviceType', deviceType);
  }

  public setEnlightedAPI(spaceId: string, apiKey: string) {
    this.changeComponentProperties('mp.dispersionDataGenerator', 'enlightedApi', {spaceId, apiKey});
  }

  public setTimeFormat(format: string) {
    this.changeComponentProperties('mp.d3Handler', 'timeFormat', format);
  }

    private changeComponentProperties(componentType: string, propertyName: string, value: any) {
        const components = [...this.graphic.components];
        components.forEach((component: any) => {
            if (component.type === componentType) {
                component.inputs[propertyName] = value;
            }
        });
        this.graphic.components = [...components];
    }

}